import React from 'react';
import { customDateNumbersOnly } from '../../../../utils/helpers';
import { AdminContext } from '../../../../utils/store/context/adminContext';
import LoadingSection from '../../../Helpers/LoadingSection';
import SelectENURMYear from '../../../Helpers/SelectENURMYear';
import { useSortableData } from '../../../Helpers/useSortableData';

// STYLES
const thead = {
	fontWeight: '700',
	backgroundColor: 'var(--brand-1)',
	color: 'var(--brand-white)',
};

const center = {
	textAlign: 'center',
};

const Reviews = () => {
	const { getReviews, getUsersWhere } = React.useContext(AdminContext);
	const [loading, setLoading] = React.useState(false);
	const [searchSubmitted, setSearchSubmitted] = React.useState(false);
	const [reportData, setReportData] = React.useState([]);
	const [reportYear, setReportYear] = React.useState('');
	const [year, setYear] = React.useState();

	const { items, requestSort } = useSortableData(reportData, {
		key: 'userLastName',
		direction: 'descending',
	});

	React.useEffect(() => {
		if (reportYear) {
			let date = new Date(reportYear);
			let yr = date.getFullYear() + 1;
			setYear(yr);
		}

		return () => {
			setSearchSubmitted(false);
		};
		// eslint-disable-next-line
	}, [reportYear]);

	// React.useEffect(() => {
	// 	console.info('reportData changed...');
	// 	reportData.length > 0 && setLoading(false);

	// 	// eslint-disable-next-line
	// }, [reportData]);

	const handleSubmit = async () => {
		setLoading(true);
		let completeData = [];
		let data = await getReviews(`year`, '==', year);

		const join = async (i) => {
			i.filter((review) => {
				return (
					review.feedback !== '' &&
					review.feedback !== 'Chose not to leave review.'
				);
			}).forEach(async (review) => {
				let email = await getEmail(review.user);
				completeData.push({ ...review, userEmail: email });
				// setTimeout(() => {
				// 	setReportData((reportData) => [
				// 		...reportData,
				// 		{ ...review, userEmail: email },
				// 	]);
				// }, 1000);
			});
			return setTimeout(() => {
				setReportData(new Set([...completeData]));
			}, 1000);
		};

		join(data);
		// setReportData(completeData);
		setSearchSubmitted(true);
		setLoading(false);
	};

	const getEmail = async (userID) => {
		let user = await getUsersWhere('uid', '==', userID);
		if (user.length > 0) {
			return user[0].email;
		} else {
			console.info({ user });
		}
	};

	// LOADING
	// if (loading) {
	// 	return <LoadingSection padding='5vh' message='Loading data....' />;
	// }

	return (
		<>
			<div className='mt-2 row'>
				<h5 className='txt-brand-2'>Reviews Report</h5>
				<SelectENURMYear onChange={(yr) => setReportYear(yr)} />
				{!searchSubmitted && (
					<div className='input-field col s12'>
						<button
							type='submit'
							className='btn bg-brand'
							disabled={reportYear === ''}
							onClick={handleSubmit}>
							Submit
						</button>
					</div>
				)}
			</div>

			{searchSubmitted && (
				<div style={{ overflowX: 'scroll' }}>
					<table>
						<thead style={thead}>
							<tr>
								<td
									className='c-pointer'
									onClick={() => requestSort('userFirstName')}>
									Nombre
								</td>
								<td
									className='c-pointer'
									onClick={() => requestSort('userLastName')}>
									Apellido
								</td>
								<td style={center}>
									<span
										className='c-pointer'
										onClick={() => requestSort('rating')}>
										rating
									</span>
								</td>
								<td style={center}>Feedback</td>
								<td style={center}>Email</td>
							</tr>
						</thead>
						<tbody>
							{reportData &&
								items.map((review) => {
									return (
										<tr key={review.id}>
											<td>{review.userFirstName}</td>
											<td>{review.userLastName}</td>
											<td style={center}>{review.rating}</td>
											<td style={center}>{review.feedback}</td>
											<td style={center}>{review.userEmail}</td>
										</tr>
									);
								})}
						</tbody>
					</table>
				</div>
			)}
		</>
	);
};

export default Reviews;
