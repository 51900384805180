import React from 'react';

const TextInput = ({
	defaultValue,
	icon = '',
	id,
	label,
	onChange,
	showIcon,
}) => {
	const handleChange = (e) => {
		onChange(id, e.target.value);
	};
	return (
		<div className='input-field'>
			{showIcon && <i className='material-icons prefix txt-brand2'>{icon}</i>}
			<label htmlFor={id} className={defaultValue ? 'active' : ''}>
				{label}
			</label>
			<input
				type='text'
				id={id}
				onChange={handleChange}
				autoComplete='null'
				value={defaultValue}
			/>
		</div>
	);
};

export default TextInput;
