import React from 'react';
import { AuthContext } from '../../../utils/store/context/authContext';
const FreeTrial = () => {
	const { user, freeTrialStart } = React.useContext(AuthContext);

	if (user.freeTrialExpired) {
		return (
			<p>
				<strong>Nota:</strong> Tu período de prueba ha terminado. Por favor
				completa tu pago para continuar usando nuestra plataforma.
			</p>
		);
	}

	if (user.freeTrialActive) {
		return (
			<p>
				<strong>Nota:</strong> La modalidad de tu cuenta es de prueba y está
				limitada a hacer simulaciones de un solo ENURM (2020). Debes completar
				tu pago para usar tener acceso al contenido premium y continuar usando
				nuestra plataforma sin interrumpciones.
			</p>
		);
	}

	return (
		<div className='center mb-4'>
			<hr />
			<h3 className='txt-brand2'>¿No estás list@ para inscribirte?</h3>
			<p>
				Puedes probar nuestra plataforma <strong>completamente GRATIS</strong>{' '}
				pero trandrás la limitacion de{' '}
				<strong> solo poder hacer simulaciones del ENURM 2020</strong>. Además,
				puedes perder la oportunidad de perder la oferta actual. ¡Aprovechala!
			</p>
			<button onClick={freeTrialStart} className='btn btn-large bg-brand'>
				Prueba de Acceso Gratis
			</button>
		</div>
	);
};

export default FreeTrial;
