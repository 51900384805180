import React from 'react';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';

const AddQuestion = (props) => {
	const { quillRef, editor } = useQuill({
		modules: {
			toolbar: [
				['bold', 'italic', 'underline', 'strike'],
				[{ align: [] }],
				[{ list: 'ordered' }, { list: 'bullet' }],
				[{ size: ['small', false, 'large', 'huge'] }],
				[{ color: [] }, { background: [] }],

				['clean'],
			],
		},
	});

	return (
		<div style={{ width: '100%', height: 'auto' }}>
			<div
				ref={quillRef}
				// onInput={() => props.action(editor.root.innerHTML)}
				onBlur={() => props.action(editor.root.innerHTML)}
				id='question'
				tabIndex='4'
			/>
		</div>
	);
};

export default AddQuestion;
