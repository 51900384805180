import React from 'react';
// import { deleteAllCookies } from '../../../utils/helpers';
import { AuthContext } from '../../../utils/store/context/authContext';

const NewFeature = () => {
	const { updateUserWebAppVersion } = React.useContext(AuthContext);
	const handleClick = async () => {
		await updateUserWebAppVersion(true);
		// deleteAllCookies();
	};

	return (
		<div className=''>
			<div className='alert center'>
				Hemos lanzado una nueva versión del app. Por favor, sigue estos pasos
				para asegurarte de tener la última versión:
				{window.innerWidth < 768 && (
					<ul>
						<li>Dale al botón debajo para actualizar la aplicación.</li>
						<li>Cierra la página completamente y vuelvela a abrir.</li>
					</ul>
				)}
				{window.innerWidth > 768 && (
					<ul>
						<li>
							Si estás en un dispositivo Windows, en tu teclado dale a{' '}
							<strong>Ctrl</strong> + <strong>F5</strong>.
						</li>
						<li>
							Si estás en un dispositivo iOS, en tu teclado dale a{' '}
							<strong>CMD</strong> + <strong>R</strong>.
						</li>
						<li>Dale al botón debajo para actualizar la aplicación.</li>
					</ul>
				)}
				<div className='center pt-1'>
					<button className='btn bg-brand' onClick={handleClick}>
						Actualizar
					</button>
				</div>
				<strong> Nota:</strong> Será requerido que inicies sesión nuevamente.
			</div>
		</div>
	);
};

export default NewFeature;
