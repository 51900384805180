import React from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../utils/store/context/authContext';
import { FirestoreContext } from '../../../utils/store/context/firestoreContext';

import { removeHTMLTags } from '../../../utils/helpers';
import { FLASHCARD_DETAILS } from '../../../utils/ROUTES';
// import EditFlashcard from './EditFlashcard';

const ListOfAllFlashcards = ({ flashcards, categories, isAdmin }) => {
	const { deleteFlashcard, flashcardCreatedByPrepMed } =
		React.useContext(FirestoreContext);
	const { user } = React.useContext(AuthContext);

	const getCategories = (card) => {
		let x = categories.filter((c) => card.categories.includes(c.id));
		return x;
	};

	if (!flashcards) return <div>No flashcards</div>;

	if (!flashcards.length > 0) {
		return (
			<>
				<hr />
				<div className='alert bg-alternate2-offwhite border-0'>
					<h5 className='txt-alternate2 mt-1'>
						<i className='material-icons fsize-larger mr-1'>info</i>No tienes
						flashcards disponibles
					</h5>
					<p className='txt-black my-1'>
						Crear tus propios flashcards es fácil, rápido y altamente
						recomendado. ¡Crea tu primer flashcard hoy!
					</p>
				</div>
			</>
		);
	}

	return (
		<div>
			{flashcards
				.sort((a, b) => {
					return b.createTS.seconds * 1000 - a.createTS.seconds * 1000;
				})
				.map((card) => {
					return (
						// <div key={card.id} className='my-1 py-1'>
						<div
							key={card.id}
							className='card-panel'
							style={{ padding: '1vmin', borderRadius: '5px' }}>
							<div
								className='d-flex dark-text'
								style={{ justifyContent: 'space-between' }}>
								{/* CARD CONTENT */}
								<span style={{ width: '75%' }}>
									{card.image && <i className='material-icons small'>image</i>}
									{removeHTMLTags(card.front)}
									{isAdmin && (
										<>
											<br />
											<span className='gray-text text-lighten-2'>
												{removeHTMLTags(card.back)}
											</span>
										</>
									)}
								</span>
								{/* ACTIONS */}
								<span
									className='d-flex w-25'
									style={{
										justifyContent: 'space-between',
										alignItems: 'center',
									}}>
									{isAdmin && card?.createdBy !== 'PrepMedRD' && (
										<button
											className='orange-text text-accent-2'
											onClick={() => flashcardCreatedByPrepMed(card)}>
											<i className='material-icons'>file_copy</i>Own
										</button>
									)}
									<Link
										to={{
											pathname: FLASHCARD_DETAILS + card.id,
											state: {
												flashcard_id: card.id,
											},
										}}>
										<i className='material-icons'>edit</i> Editar
									</Link>
									<button
										className='text-danger'
										onClick={() => deleteFlashcard(card.id)}>
										<i className='material-icons'>delete</i> Borrar
									</button>
								</span>
							</div>
							<div className='my-1'>
								{getCategories(card).map((i) => {
									return (
										<span className='pill' key={i.id}>
											{i.name}
										</span>
									);
								})}
							</div>
						</div>
					);
				})}
		</div>
	);
};

export default ListOfAllFlashcards;
