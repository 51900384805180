import React, {
	useContext,
	useEffect,
	useState,
	// useRef
} from 'react';
import { AdminContext } from '../../../utils/store/context/adminContext';
import MaterialDatePicker from '../../forms/MaterialDatePicker';

const HabilitarCuenta = () => {
	const { findUserByEmail, markUserAsPaid, nextEnurm } = useContext(
		AdminContext
	);
	// const dateRef = useRef('');
	const [email, setEmail] = useState('');
	const [hasSearched, setHasSearched] = useState(false);
	const [thisUser, setThisUser] = useState(null);
	const [isPaid, setIsPaid] = useState(false);
	const [paymentDate, setPaymentDate] = useState(null);
	const [paymentSource, setPaymentSource] = useState(null);

	const findUser = async (e) => {
		e.preventDefault();
		let result = await findUserByEmail(email);
		setThisUser(result);
		setHasSearched(true);
		return result;
	};

	useEffect(() => {
		if (thisUser && thisUser[`isClient_${nextEnurm}`]) {
			setIsPaid(true);
		} else {
			setIsPaid(false);
		}

		//   return () => {
		//     second
		//   }
	}, [nextEnurm, thisUser]);

	const handleSubmit = async () => {
		let result = await markUserAsPaid(thisUser.uid, paymentSource, paymentDate);
		if (result) {
			setHasSearched(false);
			setThisUser(null);
			setIsPaid(false);
			setPaymentDate(null);
			setPaymentSource(null);
		}
	};

	return (
		<div className='container'>
			<h4>Busca Usuario por Correo</h4>
			<form onSubmit={findUser}>
				<input
					type='email'
					onFocus={() => setHasSearched(false)}
					onChange={(e) => setEmail(e.target.value.toLowerCase())}
					placeholder='Search for a user by email'
				/>
				<button type='submit' className='btn bg-brand z-depth-0 '>
					Search
				</button>
			</form>

			{hasSearched && (
				<>
					{thisUser?.hasOwnProperty('uid') ? (
						<div className='mt-4'>
							<h5>Datos del Usuario</h5>
							<ul>
								<li>
									Nombre: {thisUser.firstName} {thisUser.lastName}
								</li>
								<li>Membership: {isPaid ? 'Pago' : 'No pago'}</li>
							</ul>

							{(!isPaid || thisUser.freeTrialActive) && (
								<div className='mt-4'>
									<h6>Select Payment Source & Date</h6>
									<div className='row'>
										{/* PAYMENT DATE */}
										<div className='col s6'>
											<label htmlFor='paymentSource'>Fecha de Pago </label>
											<input
												type='date'
												id='paymentDate'
												className='datepicker'
												onChange={(e) => setPaymentDate(e.target.value)}
											/>
										</div>
										{/* <MaterialDatePicker
											thisRef={dateRef}
											onDateSelected={(d) => setPaymentDate(d)}
										/> */}

										{/* PAYMENT SOURCE */}
										<div className='col s6'>
											<label htmlFor='paymentSource'>Forma de Pago </label>
											<select
												id='paymentSource'
												className='browser-default'
												onChange={(e) => setPaymentSource(e.target.value)}>
												<option value='default'>- Select One -</option>
												<option value='Transferencia'>Transferencia</option>
												<option value='Depósito'>Depósito</option>
												<option value='Giveaway'>Giveaway</option>
												<option value='Stripe'>Stripe</option>
												<option value='PayPal'>PayPal</option>
											</select>
										</div>
									</div>
									{paymentSource && paymentDate && (
										<button
											onClick={handleSubmit}
											className='btn bg-brand z-depth-0 '>
											Habilitar Cuenta
										</button>
									)}
								</div>
							)}
						</div>
					) : (
						<div className='alert'>No existe usuario con ese correo. </div>
					)}
				</>
			)}
		</div>
	);
};

export default HabilitarCuenta;
