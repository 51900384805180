import React, { useState, useEffect } from 'react';
import LoadingSection from '../../../Helpers/LoadingSection';

import CounterOfSubCategories from './CounterOfSubCategories';
import M from 'materialize-css/dist/js/materialize';

const Counter = ({ questions }) => {
	const [getCount, setGetCount] = useState(false);
	const [loading, setLoading] = useState(true);
	const [issuesCount, setIssuesCount] = useState(0);
	const [imageCount, setImageCount] = useState(0);
	const [cienciasBasicas, setCienciasBasicas] = useState(0);
	const [cirugia, setCirugia] = useState(0);
	const [ginecologia, setGinecologia] = useState(0);
	const [medicinaInterna, setMedicinaInterna] = useState(0);
	const [obstetricia, setObstetricia] = useState(0);
	const [psiquiatría, setPsiquiatría] = useState(0);
	const [pediatría, setPediatría] = useState(0);
	const [radiología, setRadiología] = useState(0);
	const [cuidadoDeEmergencias, setCuidadoDeEmergencias] = useState(0);

	const [prepmed, setPrepmed] = useState(0);
	const [enurm24, setEnurm24] = useState(0);
	const [enurm23, setEnurm23] = useState(0);
	const [enurm22D, setEnurm22D] = useState(0);
	const [enurm22C, setEnurm22C] = useState(0);
	const [enurm22B, setEnurm22B] = useState(0);
	const [enurm22A, setEnurm22A] = useState(0);
	const [enurm21C, setEnurm21C] = useState(0);
	const [enurm21B, setEnurm21B] = useState(0);
	const [enurm21A, setEnurm21A] = useState(0);
	const [enurm20, setEnurm20] = useState(0);
	const [enurm19, setEnurm19] = useState(0);
	const [enurm18, setEnurm18] = useState(0);
	const [enurm17, setEnurm17] = useState(0);
	const [enurm16, setEnurm16] = useState(0);
	const [enurm15, setEnurm15] = useState(0);
	const [enurm14, setEnurm14] = useState(0);
	const [enurm13, setEnurm13] = useState(0);
	const [enurm12, setEnurm12] = useState(0);
	const [enurm11, setEnurm11] = useState(0);
	const [enurm10, setEnurm10] = useState(0);
	const [enurm09, setEnurm09] = useState(0);
	const [enurm08, setEnurm08] = useState(0);
	const [noEnurm, setNoEnurm] = useState(0);

	// TODO: show/hide subCat counters
	const [showCB, setShowCB] = useState(false);
	const [showC, setShowC] = useState(false);
	const [showG, setShowG] = useState(false);
	const [showMI, setShowMI] = useState(false);
	const [showO, setShowO] = useState(false);
	const [showPe, setShowPe] = useState(false);
	const [showPi, setShowPi] = useState(false);
	const [showRad, setShowRad] = useState(false);
	const [showCE, setShowCE] = useState(false);

	/* eslint-disable */
	useEffect(() => {
		// questions && setGetCount(true);
		document.addEventListener('DOMContentLoaded', function () {
			var collapsibles = document.querySelectorAll('.collapsible');
			var instances = M.Collapsible.init(collapsibles, {
				accordion: false,
			});
		});

		// return () => {
		// 	setGetCount(false);
		// };
	}, []);

	useEffect(() => {
		setGetCount(true);
	}, [questions]);

	useEffect(() => {
		if (getCount) {
			questions.forEach((question) => {
				countCategories(question);
			});

			questions.forEach((question) => {
				countEnurm(question);
			});

			questions.forEach((question) => {
				if (question.hasOwnProperty('imagePath')) {
					setImageCount((imageCount) => imageCount + 1);
				}
			});

			// ~ QUESTIONS WITH ISSUES
			questions.forEach((question) => {
				if (question.hasOwnProperty('issues')) {
					setIssuesCount((issuesCount) => issuesCount + 1);
				}
				if (!question.hasOwnProperty('fuente')) {
					// console.info('Question with FUENTE Issue:', question.id, question);
					setIssuesCount((issuesCount) => issuesCount + 1);
				}
				if (!question.hasOwnProperty('answer')) {
					// console.info('Question with ANSWER Issue:', question.id, question);
					setIssuesCount((issuesCount) => issuesCount + 1);
				}
				if (!question.hasOwnProperty('option1')) {
					// console.info('Question with OPTION1 Issue:', question.id, question);
					setIssuesCount((issuesCount) => issuesCount + 1);
				}
				if (!question.hasOwnProperty('option2')) {
					// console.info('Question with OPTION2 Issue:', question.id, question);
					setIssuesCount((issuesCount) => issuesCount + 1);
				}
				if (!question.hasOwnProperty('option3')) {
					// console.info('Question with OPTION3 Issue:', question.id, question);
					setIssuesCount((issuesCount) => issuesCount + 1);
				}
				if (!question.hasOwnProperty('explanation')) {
					// console.info(
					// 	'Question with EXPLANATION Issue:',
					// 	question.id,
					// 	question
					// );
					setIssuesCount((issuesCount) => issuesCount + 1);
				}
			});
			setGetCount(false);
		}
		setLoading(false);
		// return () => {
		// 	setIssuesCount();
		// };
	}, [getCount]);
	/* eslint-enable */

	const countCategories = (question) => {
		switch (question.category) {
			case 'Ciencias Básicas':
				setCienciasBasicas((cienciasBasicas) => cienciasBasicas + 1);
				break;
			case 'Cirugía':
				setCirugia((cirugia) => cirugia + 1);
				break;
			case 'Ginecología':
				setGinecologia((ginecologia) => ginecologia + 1);
				break;
			case 'Medicina Interna':
				setMedicinaInterna((medicinaInterna) => medicinaInterna + 1);
				break;
			case 'Obstetricia':
				setObstetricia((obstetricia) => obstetricia + 1);
				break;
			case 'Psiquiatría':
				setPsiquiatría((psiquiatría) => psiquiatría + 1);
				break;
			case 'Pediatría':
				setPediatría((pediatría) => pediatría + 1);
				break;
			case 'Radiología':
				setRadiología((radiología) => radiología + 1);
				break;
			case 'Cuidado de Emergencias':
				setCuidadoDeEmergencias(
					(cuidadoDeEmergencias) => cuidadoDeEmergencias + 1
				);
				break;
			default:
				setNoEnurm((noEnurm) => noEnurm + 1);
				// console.log('no category', question.category, question);
				break;
		}
	};

	const countEnurm = (question) => {
		switch (question.enurm) {
			case 'prepmed':
				setPrepmed((prepmed) => prepmed + 1);
				break;
			case 'version-prepmed':
				setPrepmed((prepmed) => prepmed + 1);
				break;
			case 'enurm-2024':
				setEnurm24((enurm24) => enurm24 + 1);
				break;
			case 'enurm-2023-A':
				setEnurm23((enurm23) => enurm23 + 1);
				break;
			case 'enurm-2022-d':
				setEnurm22D((enurm22D) => enurm22D + 1);
				break;
			case 'enurm-2022-C':
				setEnurm22C((enurm22C) => enurm22C + 1);
				break;
			case 'enurm-2022-B':
				setEnurm22B((enurm22B) => enurm22B + 1);
				break;
			case 'enurm-2022-A':
				setEnurm22A((enurm22A) => enurm22A + 1);
				break;
			case 'enurm-2021-C':
				setEnurm21C((enurm21C) => enurm21C + 1);
				break;
			case 'enurm-2021-B':
				setEnurm21B((enurm21B) => enurm21B + 1);
				break;
			case 'enurm-2021-A':
				setEnurm21A((enurm21A) => enurm21A + 1);
				break;
			case 'enurm-2020':
				setEnurm20((enurm20) => enurm20 + 1);
				break;
			case 'enurm-2019':
				setEnurm19((enurm19) => enurm19 + 1);
				break;
			case 'enurm-2018':
				setEnurm18((enurm18) => enurm18 + 1);
				break;
			case 'enurm-2017':
				setEnurm17((enurm17) => enurm17 + 1);
				break;
			case 'enurm-2016':
				setEnurm16((enurm16) => enurm16 + 1);
				break;
			case 'enurm-2015':
				setEnurm15((enurm15) => enurm15 + 1);
				break;
			case 'enurm-2014':
				setEnurm14((enurm14) => enurm14 + 1);
				break;
			case 'enurm-2013':
				setEnurm13((enurm13) => enurm13 + 1);
				break;
			case 'enurm-2012':
				setEnurm12((enurm12) => enurm12 + 1);
				break;
			case 'enurm-2011':
				setEnurm11((enurm11) => enurm11 + 1);
				break;
			case 'enurm-2010':
				setEnurm10((enurm10) => enurm10 + 1);
				break;
			case 'enurm-2009':
				setEnurm09((enurm09) => enurm09 + 1);
				break;
			case 'enurm-2008':
				setEnurm08((enurm08) => enurm08 + 1);
				break;
			default:
				// console.log('No Enurm', question.enurm, question);
				break;
		}
	};

	return (
		<div>
			{loading ? (
				<LoadingSection />
			) : (
				<React.Fragment>
					{/* <div className='card z-depth-0 border-0'>
						<div className='card-content center txt-g2'>
							<span style={{ fontSize: 'xx-large' }} className=''>
								{questions && questions.length}
							</span>
							<div className=''>Preguntas en Total</div>
						</div>
					</div>
					<div className='card z-depth-0 border-0 '>
						<div className='card-content center bg-lighter-shade'>
							<span style={{ fontSize: 'xx-large' }} className='txt-g1'>
								{imageCount}
							</span>
							<div className='txt-g1'>Preguntas con Imagen:</div>
						</div>
					</div> */}
					<div className='card z-depth-0 border-0 overflow-hidden'>
						<div className='card-content red white-text'>
							<div>
								Questions with Issues:
								<span
									className='badge white-text'
									style={{ fontSize: 'xx-large' }}>
									{issuesCount}
								</span>
							</div>
						</div>
					</div>
					<div className='card z-depth-0 border-0 overflow-hidden'>
						<div className='card-content center bg-lighter-shade'>
							<div className='row'>
								<div className='col s6 txt-g2'>
									<span style={{ fontSize: 'xx-large' }} className=''>
										{questions && questions.length}
									</span>
									<div className=''>Preguntas</div>
								</div>
								<div className='col s6 txt-g1'>
									<span style={{ fontSize: 'xx-large' }}>{imageCount}</span>
									<div>w/ Images</div>
								</div>
							</div>
						</div>
					</div>

					<div className='card z-depth-0 border-0'>
						<div className='card-content'>
							{/* <h6>Areas Básicas</h6> */}
							<ul className='collapsible'>
								{/* Ciencias Basicas */}
								<li onClick={(e) => setShowCB(!showCB)}>
									<div className='collapsible-header'>
										{/* <i className='material-icons'>filter_drama</i> */}
										<span>Ciencias Básicas: </span>
										{questions &&
											questions.some((question) => {
												return (
													question.category === 'Ciencias Básicas' &&
													question.hasOwnProperty('sub_category')
													// 	||
													// question.hasOwnProperty('sub_category')
												);
											}) && (
												<span className='badge fsize-1 txt-danger'>
													has "sub_category"
												</span>
											)}

										<span className='badge white-text brand-bg'>
											{cienciasBasicas}
										</span>
									</div>
									<div
										className='collapsible-body'
										style={{
											display: `${showCB ? 'block' : 'none'}`,
											padding: '0',
											fontSize: 'smaller',
										}}>
										<ul className='collection'>
											<CounterOfSubCategories
												questions={questions.filter(
													(item) => item.category === 'Ciencias Básicas'
												)}
											/>
										</ul>
									</div>
								</li>
								{/* Cirugia */}
								<li onClick={(e) => setShowC(!showC)}>
									<div className='collapsible-header'>
										{/* <i className='material-icons'>filter_drama</i>First */}
										<span>Cirugía: </span>
										<span className='badge white-text brand-bg'>{cirugia}</span>
									</div>
									<div
										className='collapsible-body'
										style={{
											display: `${showC ? 'block' : 'none'}`,
											padding: '0',
											fontSize: 'smaller',
										}}>
										<ul className='collection'>
											<CounterOfSubCategories
												questions={questions.filter(
													(item) => item.category === 'Cirugía'
												)}
											/>
										</ul>
									</div>
								</li>
								{/* Ginecología */}
								<li onClick={(e) => setShowG(!showG)}>
									<div className='collapsible-header'>
										{/* <i className='material-icons'>filter_drama</i>First */}
										<span>Ginecologia: </span>
										<span className='badge white-text brand-bg'>
											{ginecologia}
										</span>
									</div>
									<div
										className='collapsible-body'
										style={{
											display: `${showG ? 'block' : 'none'}`,
											padding: '0',
											fontSize: 'smaller',
										}}>
										<ul className='collection'>
											<CounterOfSubCategories
												questions={questions.filter(
													(item) => item.category === 'Ginecología'
												)}
											/>
										</ul>
									</div>
								</li>
								{/* Medicina Interna  */}
								<li onClick={(e) => setShowMI(!showMI)}>
									<div className='collapsible-header'>
										{/* <i className='material-icons'>filter_drama</i>First */}
										<span>Medicina Interna: </span>
										<span className='badge white-text brand-bg'>
											{medicinaInterna}
										</span>
									</div>
									<div
										className='collapsible-body'
										style={{
											display: `${showMI ? 'block' : 'none'}`,
											padding: '0',
											fontSize: 'smaller',
										}}>
										<ul className='collection'>
											<CounterOfSubCategories
												questions={questions.filter(
													(item) => item.category === 'Medicina Interna'
												)}
											/>
										</ul>
									</div>
								</li>
								{/* Obstetricia  */}
								<li onClick={(e) => setShowO(!showO)}>
									<div className='collapsible-header'>
										{/* <i className='material-icons'>filter_drama</i>First */}
										<span>Obstetricia: </span>
										<span className='badge white-text brand-bg'>
											{obstetricia}
										</span>
									</div>
									<div
										className='collapsible-body'
										style={{
											display: `${showO ? 'block' : 'none'}`,
											padding: '0',
											fontSize: 'smaller',
										}}>
										<ul className='collection'>
											<CounterOfSubCategories
												questions={questions.filter(
													(item) => item.category === 'Obstetricia'
												)}
											/>
										</ul>
									</div>
								</li>
								{/* Pediatria  */}
								<li onClick={(e) => setShowPe(!showPe)}>
									<div className='collapsible-header'>
										{/* <i className='material-icons'>filter_drama</i>First */}
										<span>Pediatría: </span>
										{questions.some((question) => {
											return (
												question.category === 'Pediatría' &&
												question.hasOwnProperty('subCategory')
											);
										}) && <span className='badge'>test</span>}
										<span className='badge white-text brand-bg'>
											{pediatría}
										</span>
									</div>
									<div
										className='collapsible-body'
										style={{
											display: `${showPe ? 'block' : 'none'}`,
											padding: '0',
											fontSize: 'smaller',
										}}>
										<ul className='collection'>
											<CounterOfSubCategories
												questions={questions.filter(
													(item) => item.category === 'Pedriatría'
												)}
											/>
										</ul>
									</div>
								</li>
								{/* Psiquiatria  */}
								<li onClick={(e) => setShowPi(!showPi)}>
									<div className='collapsible-header'>
										{/* <i className='material-icons'>filter_drama</i>First */}
										<span>Psiquiatría: </span>
										<span className='badge white-text brand-bg'>
											{psiquiatría}
										</span>
									</div>
									<div
										className='collapsible-body'
										style={{
											display: `${showPi ? 'block' : 'none'}`,
											padding: '0',
											fontSize: 'smaller',
										}}>
										<ul className='collection'>
											<CounterOfSubCategories
												questions={questions.filter(
													(item) => item.category === 'Psiquiatría'
												)}
											/>
										</ul>
									</div>
								</li>
								{/* Radiología  */}
								<li onClick={(e) => setShowRad(!showRad)}>
									<div className='collapsible-header'>
										{/* <i className='material-icons'>filter_drama</i>First */}
										<span>Radiología: </span>
										<span className='badge white-text brand-bg'>
											{radiología}
										</span>
									</div>
									<div
										className='collapsible-body'
										style={{
											display: `${showRad ? 'block' : 'none'}`,
											padding: '0',
											fontSize: 'smaller',
										}}>
										<ul className='collection'>
											<CounterOfSubCategories
												questions={questions.filter(
													(item) => item.category === 'Radiología'
												)}
											/>
										</ul>
									</div>
								</li>
								{/* Cuidade de Emergencias  */}
								<li onClick={(e) => setShowCE(!showCE)}>
									<div className='collapsible-header'>
										{/* <i className='material-icons'>filter_drama</i>First */}
										<span>Cuidado de Emergencias: </span>
										<span className='badge white-text brand-bg'>
											{cuidadoDeEmergencias}
										</span>
									</div>
									<div
										className='collapsible-body'
										style={{
											display: `${showCE ? 'block' : 'none'}`,
											padding: '0',
											fontSize: 'smaller',
										}}>
										<ul className='collection'>
											<CounterOfSubCategories
												questions={questions.filter(
													(item) => item.category === 'Cuidado de Emergencias'
												)}
											/>
										</ul>
									</div>
								</li>
								{/* New 
							<li onClick={(e) => setShowG(!showG)}>
								<div className='collapsible-header'>
									{/* <i className='material-icons'>filter_drama</i>First */}
								{/* <span>Cirugía: </span>
									<span className='badge white-text brand-bg'>{cirugia}</span>
								</div>
								<div
									className='collapsible-body'
									style={{ display: `${showG ? 'block' : 'none'}`, padding: '0', fontSize: 'smaller' }}>
									<ul className='collection'>
									<CounterOfSubCategories
										questions={questions.filter(
											(item) => item.category === 'Cirugía'
										)}
									/>
									</ul>
								</div>
							</li> */}
							</ul>
						</div>
					</div>

					<div className='container center'>
						<div className='card-panel'>
							<h6>ENURM</h6>
							<ul
								className='left-align'
								style={{
									borderBottom: 'solid 1px #e1e1e1',
								}}>
								<li>Missing ENURM-Label: {noEnurm}</li>
								<li>
									PrepMed: {prepmed}
									<span
										className='teal-text text-darken-1'
										style={{ paddingLeft: '2rem' }}>
										({Math.round((prepmed / questions.length) * 100)}%)
									</span>
								</li>
								<li>ENURM2024: {enurm24}</li>
								<li>ENURM2023: {enurm23}</li>
								<li>ENURM2022-A: {enurm22A}</li>
								<li>ENURM2022-B: {enurm22B}</li>
								<li>ENURM2022-C: {enurm22C}</li>
								<li>ENURM2022-D: {enurm22D}</li>
								<li>ENURM2021-A: {enurm21A}</li>
								<li>ENURM2021-B: {enurm21B}</li>
								<li>ENURM2021-C: {enurm21C}</li>
								<li>ENURM2020: {enurm20}</li>
								<li>ENURM2019: {enurm19}</li>
								<li>ENURM2018: {enurm18}</li>
								<li>ENURM2017: {enurm17}</li>
								<li>ENURM2016: {enurm16}</li>
								<li>ENURM2015: {enurm15}</li>
								<li>ENURM2014: {enurm14}</li>
								<li>ENURM2013: {enurm13}</li>
								<li>ENURM2012: {enurm12}</li>
								<li>ENURM2011: {enurm11}</li>
								<li>ENURM2010: {enurm10}</li>
								<li>ENURM2009: {enurm09}</li>
								<li>ENURM2008: {enurm08}</li>
							</ul>
						</div>
					</div>
				</React.Fragment>
			)}
		</div>
	);
};

export default Counter;
