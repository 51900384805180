// PUBLIC
export const HOME_PAGE = '/';
export const LOGIN_PAGE = '/acceder';
export const SIGNUP_PAGE = '/registrate_OLD';
export const SIGNUP_PAGE_NEW = '/registrate';
export const EMAIL_VERIFICATION = '/verifica-tu-correo';
export const EMAIL_VERIFIED = '/correo-verificado/';
export const ABOUT_US = '/sobre-nosotros';
export const POLICY_COOKIES = '/cookies';
export const PRIVACY_POLICY = '/politica-de-privacidad';
export const TOS_PAGE = '/terminos-de-servicio';
export const NEW_PASSWORD = '/nuevo-password';
export const CONTACT_US = '/contactanos';
export const SOCIAL_LINKS = '/links';
export const COLABORADORES = '/colaboradores';
//'/contacto';
export const STRIPE_CHECKOUT = '/checkout';
export const STRIPE_PAYMENTS = '/payments';
export const STRIPE_CHECKOUT_SUCCESS = '/success/session_id/:id';
export const STRIPE_CHECKOUT_FAIL = '/failed';

// paypal
export const PAYPAL_CHECKOUT = '/paypal';

// PRIVATE
export const ADD_FLASHCARD = '/add-flashcard';
export const FLASHCARDS_LIST = '/flashcard-dashboard';
export const TAKE_FLASHCARDS = '/flashcards';
export const FLASHCARDS_MODE = '/flashcards-mode';
export const GRADE_CONSULTATION = '/consulta-de-notas';

export const ADD_QUESTIONS = '/add-questions';
export const FLASHCARD_DETAILS = '/flashcard-details/:id';
export const QUESTION_LIST = '/question-dashboard';
export const CHOOSE_QUIZ = '/elige';
export const DASHBOARD = '/dashboard';
export const STATS = '/estadisticas';
export const EDIT_MODE = '/edit-mode';
export const PREVIOUS_TEST_REVIEW = '/resultados-anteriores';
export const QUESTION_DETAILS = '/question-details/:id';
export const QUESTION_DETAILS_DARK = '/question-details-dark';
export const QUIZ = '/examen';
export const TEST_RESULTS = '/resultados';
export const REPORTS = '/admin-reports';
export const USER_PROFILE = '/tu-perfil';

export const ADMIN_LINKS = {
	HABILITAR_CUENTA: '/habilitar-cuenta',
};
export const PUBLIC_LINKS = {
	PREGUNTAS_FRECUENTES: '/preguntas-frecuentes',
	PREGUNTAS_FRECUENTES_WITH_ID: '/preguntas-frecuentes/:id',
};
export const USER_LINKS = {
	PREGUNTAS_VISTAS: '/preguntas-vistas',
};
