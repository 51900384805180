import React from 'react';
import { AuthContext } from '../../../../utils/store/context/authContext';
import InputCedula from '../../../Helpers/InputCedula';

export const VerificaTuCedula = ({ handleChange }) => {
	const { user, UpdateCedulaVerification } = React.useContext(AuthContext);
	const [cedula, setCedula] = React.useState('');
	const [verificationFailed, setVerificationFailed] = React.useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();

		if (user.cedula.replaceAll('-', '') === cedula.replaceAll('-', '')) {
			UpdateCedulaVerification(user.uid, true);
		} else {
			setVerificationFailed(true);
		}
	};

	return (
		<div className='card-panel bg-alternate1-white py-1'>
			<h5 className='txt-alternate1'>
				<i className='material-icons fsize-larger mr-2'>announcement</i>
				Verifica Tu Cédula
			</h5>

			<p>
				Para mantener la seguridad de tu cuenta, debes verificar tu numero de
				cédula cada cierto tiempo.
			</p>
			<p>
				Por favor, escribe y confirma tu número de cédula para continuar. Si la
				cédula que tenemos y la que intentas confirmar no son iguales, tu cuenta
				estará bloqueada temporalmente hasta que puedas confirmarla.
			</p>
			<form onSubmit={handleSubmit} className='mt-2'>
				<div className='container'>
					<div className='w-65 m-auto'>
						<InputCedula handleChange={setCedula} />
					</div>
					<div className='w-75 m-auto'>
						{verificationFailed && (
							<strong className='center fsize-1 txt-danger m-0'>
								La cédula en nuestra base de datos y la que introdujo no son
								iguales. Por favor inténtelo de nuevo o contactenos si necesita
								asistencia.
							</strong>
						)}
					</div>
				</div>
			</form>

			<div className='right-align'>
				<button className='btn btn-large bg-brand' onClick={handleSubmit}>
					<i className='material-icons right'>send</i>Confirmar Cédula
				</button>
			</div>
		</div>
	);
};
