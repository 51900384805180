import React from 'react';
import parse from 'html-react-parser';
import { removeEmptyParagraphsbyQuill } from '../../../../utils/helpers';
import { FirestoreContext } from '../../../../utils/store/context/firestoreContext';

const Explanation = ({ currentQuestion, imageURL }) => {
	const explanation = removeEmptyParagraphsbyQuill(currentQuestion.explanation);

	const { getAudioURL, audioURL, clearAudioURL } = React.useContext(
		FirestoreContext
	);

	React.useEffect(() => {
		let audioFileName = currentQuestion.audioFileName;
		if (audioFileName !== '') getAudioURL(audioFileName);
		return () => {
			clearAudioURL();
		};
		// eslint-disable-next-line
	}, []);

	return (
		<React.Fragment>
			<>
				{currentQuestion.audioFileName && audioURL && (
					<div className=''>
						<audio controls>
							<source src={audioURL} type='audio/mpeg' />
							Your browser does not support the audio element.
						</audio>
					</div>
				)}
				<div className='explanation'>{parse(explanation)}</div>
			</>
		</React.Fragment>
	);
};

export default Explanation;
