import React from 'react';
import { FirestoreContext } from '../../../utils/store/context/firestoreContext';

const MinMaxByYear = ({ especialidad }) => {
	const years = [2023, 2022, 2021, 2020, 2019, 2018, 2017];
	return (
		<div className='my-4'>
			<h6 className='txt-brand2 center'>
				Nota mínima y máxima de <b>{especialidad.name}</b>
			</h6>
			<table className='historic-grade-data'>
				<thead>
					<tr>
						<td>AÑO</td>
						<td>MIN</td>
						<td>MAX</td>
					</tr>
				</thead>
				<tbody>
					{especialidad &&
						years.map((year) => {
							return (
								<tr key={year}>
									<td>{year}</td>
									<td>{especialidad[year]?.min}</td>
									<td>{especialidad[year]?.max}</td>
								</tr>
							);
						})}
				</tbody>
			</table>
		</div>
	);
};

const PostTestGradeConsultationResults = ({ interests }) => {
	const { getHistoricGrades, especialidades } =
		React.useContext(FirestoreContext);

	const [esp_1, setEsp_1] = React.useState({});
	const [esp_2, setEsp_2] = React.useState({});

	React.useEffect(() => {
		getHistoricGrades(interests.especialidad_target_1);
		getHistoricGrades(interests.especialidad_target_2);

		// return () => {
		//     cleanup
		// }
	}, []);

	React.useEffect(() => {
		setEsp_1(especialidades?.[0]);
		setEsp_2(especialidades?.[1]);
		// return () => {
		//     cleanup
		// }
	}, [especialidades]);

	return (
		<div>
			<div className='mb-3'>
				Tu nota en el ENURM fue de {interests.nota_final}. Historicamente para
				las especialidades que te interesan estos han sido los resultados:
			</div>
			{esp_1 && esp_2 && (
				<>
					<div style={{ overflowX: 'auto' }}>
						<table className='historic-grade-summary'>
							<thead
								style={{
									fontWeight: '700',
									background: 'var(--brand-1)',
									color: 'var(--brand-white)',
								}}>
								<tr>
									<td>Especialidad</td>
									<td className='center'>Mínimo</td>
									<td className='center'>Promedio</td>
									<td className='center'>Máximo</td>
									<td className='center'>Aplicantes</td>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>{esp_1.name}</td>
									<td className='center'>{esp_1.min}</td>
									<td className='center'>{esp_1.avg}</td>
									<td className='center'>{esp_1.max}</td>
									<td className='center'>{esp_1.count}</td>
								</tr>
								<tr>
									<td>{esp_2.name}</td>
									<td className='center'>{esp_2.min}</td>
									<td className='center'>{esp_2.avg}</td>
									<td className='center'>{esp_2.max}</td>
									<td className='center'>{esp_2.count}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div>
						<MinMaxByYear especialidad={esp_1} />
					</div>
					<div>
						<MinMaxByYear especialidad={esp_2} />
					</div>
					<div className='center'>
						<sub>
							<b>Nota:</b> Si vez algún 0 en las notas historicas significa que
							no se reportó ningun aplicante o no se hicieron públicas sus
							calificaciónes.
						</sub>
					</div>
				</>
			)}
		</div>
	);
};

export default PostTestGradeConsultationResults;
