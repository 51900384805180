import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AuthContext } from '../../../utils/store/context/authContext';
import Pagination from '../../Helpers/Pagination';
import TestRecordTableRow from './TestRecordTableRow';

let PageSize = 10;

function TestRecordTable({ records, onLoadDone }) {
	const { userTestRecords, getUserTestRecordsFromDB } = useContext(AuthContext);
	// const [testRecords, setTestRecords] = useState(records);

	const getLatestRecords = () => {
		// setTestRecords([]);
		getUserTestRecordsFromDB();
	};

	useEffect(() => {
		onLoadDone(userTestRecords);
		// return () => {
		// 	cleanup
		// }
	}, []);

	useEffect(() => {
		setTotal(userTestRecords);
		// return () => {
		// 	cleanup
		// }
	}, [userTestRecords]);

	const [currentPage, setCurrentPage] = useState(1);
	const [total, setTotal] = useState();

	const currentTableData = React.useMemo(() => {
		let data = userTestRecords;
		setTotal(userTestRecords);
		const firstPageIndex = (currentPage - 1) * PageSize;
		const lastPageIndex = firstPageIndex + PageSize;
		return data.slice(firstPageIndex, lastPageIndex);
	}, [currentPage, userTestRecords]);

	return (
		<section className='section-1 col s12'>
			<div className='container'>
				<div
					className='d-flex'
					style={{ justifyContent: 'space-between', alignItems: 'baseline' }}>
					<h3>Récord de Exámenes</h3>
					<button className='btn bg-brand' onClick={getLatestRecords}>
						<i className='material-icons left'>cached</i>Actualizar
					</button>
				</div>

				<div className='d-flex' style={{ justifyContent: 'flex-end' }}>
					{userTestRecords.length > 0 && (
						<Pagination
							className='pagination-bar'
							currentPage={currentPage}
							totalCount={total?.length}
							pageSize={PageSize}
							onPageChange={(page) => setCurrentPage(page)}
						/>
					)}
				</div>
				{total && (
					<table id='test-records'>
						<thead>
							<tr>
								<td style={{ width: '20%' }}>Date</td>
								<td style={{ width: '20%', textAlign: 'center' }}>Hora</td>
								<td style={{ width: '20%', textAlign: 'center' }}>Modo</td>
								<td style={{ width: '15%', textAlign: 'center' }}>
									{window.innerwidth ? 'Preguntas' : '# de Preguntas'}
								</td>
								<td style={{ width: '10%', textAlign: 'center' }}>
									Puntuación
								</td>
								<td style={{ width: '15%', textAlign: 'center' }}>Detalles</td>
							</tr>
						</thead>
						<tbody>
							{currentTableData
								.sort((a, b) => {
									return b.date - a.date;
								})
								.map((record) => {
									return <TestRecordTableRow record={record} key={uuidv4()} />;
								})}
							{/* {testRecords &&
							testRecords
								.sort((a, b) => {
									return b.date - a.date;
								})
								.map((record) => {
									return <TestRecordTableRow record={record} key={uuidv4()} />;
								})} */}
						</tbody>
					</table>
				)}
			</div>
		</section>
	);
}

export default TestRecordTable;
