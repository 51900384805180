import React from 'react';
import { AdminContext } from '../../../../utils/store/context/adminContext';
import SelectENURMYear from '../../../Helpers/SelectENURMYear';

const SignupsByUniversity = (props) => {
	const { getUsersWhere } = React.useContext(AdminContext);

	const [searchSubmitted, setSearchSubmitted] = React.useState(false);
	const [reportData, setReportData] = React.useState([]);
	const [reportYear, setReportYear] = React.useState([]);
	const [year, setYear] = React.useState();

	React.useEffect(() => {
		if (reportYear.length > 0) {
			let date = new Date(reportYear);
			let yr = date.getFullYear() + 1;
			setYear(yr);
		}

		return () => {
			setSearchSubmitted(false);
		};
		// eslint-disable-next-line
	}, [reportYear]);

	const handleSubmit = async () => {
		// let data = await getUsersWhere(`isClient_${year}`, '==', true);
		let data = await getUsersWhere(`isClient_${year}`, 'in', [true, false]);
		console.info('Report Data:', data);

		// getActiveUsers(data);
		setReportData(data);
		setSearchSubmitted(true);
	};

	let universities = [
		'INTEC',
		`O&M`,
		'PUCMM',
		'UASD',
		'UCATECI',
		'UCE',
		'UCNE',
		'UNIBE',
		'UNPHU',
		'UTESA',
	];

	return (
		<div>
			<SelectENURMYear onChange={(yr) => setReportYear(yr)} />
			{!searchSubmitted && (
				<div className='input-field col s12'>
					<button
						type='submit'
						className='btn bg-brand'
						disabled={year === undefined}
						onClick={handleSubmit}>
						Submit
					</button>
				</div>
			)}

			{searchSubmitted && (
				<div>
					{universities.map((uni) => {
						return (
							<div key={uni}>
								{uni}: {/* CALCULATE TOTAL PAID USERS BY UNIVERSITY */}
								{reportData.reduce((total, user) => {
									return (
										total +
										(user?.universidad === uni && user[`isClient_${year}`]
											? 1
											: 0)
									);
								}, 0)}{' '}
								/ {/* GET TOTAL USERS BY UNIVERSITY */}
								{reportData.reduce((total, user) => {
									return total + (user?.universidad === uni ? 1 : 0);
								}, 0)}
							</div>
						);
					})}
					{reportData.length > 0 ? (
						<div>
							{universities.map((uni) => {
								return (
									<div key={uni}>
										{uni}:{' '}
										{reportData.reduce((total, user) => {
											return (
												total +
												(user?.universidad === uni && user[`isClient_${year}`]
													? 1
													: 0)
											);
										}, 0)}{' '}
										/{' '}
										{reportData.reduce((total, user) => {
											return total + (user?.universidad === uni ? 1 : 0);
										}, 0)}
									</div>
								);
							})}
						</div>
					) : (
						<div>
							<p>No users found...</p>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default SignupsByUniversity;
