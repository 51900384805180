import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../utils/store/context/authContext';
import {
	customDateFormatInSpanish,
	redirectToVerifyEmail,
} from '../../../utils/helpers';
import TextInput from '../../forms/TextInput';
const Profile = () => {
	const {
		changeEmailAddress,
		resetPassword,
		user,
		updateUserName,
	} = useContext(AuthContext);
	// console.info(user.signUpDate);

	const [showEmailInput, setShowEmailInput] = React.useState(false);
	const [email, setEmail] = React.useState(false);
	const [userProfile, setUserProfile] = React.useState(user);

	const updateUserprofile = (key, value) => {
		switch (key) {
			case 'firstName':
				setUserProfile({ ...user, firstName: value });
				break;
			case 'middleName':
				setUserProfile({ ...user, middleName: value });
				break;
			case 'lastName':
				setUserProfile({ ...user, lastName: value });
				break;
			case 'secondLastName':
				setUserProfile({ ...user, secondLastName: value });
				break;
			default:
				break;
		}
		console.info(key, value, user);
	};

	const updateUser = () => {
		updateUserName(userProfile);
		return;
	};

	const updatePasssword = () => {
		resetPassword(userProfile.email);
		alert(
			'Por favor revisa tu correo. Hemos enviado un enlace para cambiar tu contraseña.'
		);
	};

	const handleEmailChange = (params) => {
		changeEmailAddress(email);
		setShowEmailInput(false);
	};

	return (
		<div className='container'>
			<div className='card my-4'>
				<div className='card-content bg-gray-1'>
					<h5 className='d-flex' style={{ alignItems: 'center' }}>
						<i className='material-icons fsize-4 mr-1'>person</i>Tu Perfil
					</h5>
				</div>
				<div className='card-content'>
					{/* CORREY Y CONTRASEÑA */}
					{/* <hr className='my-4 mx-4' /> */}
					<div className='mt-2 mb-4'>
						<h5>Correo y Contraseña</h5>
						{!user?.emailVerified && (
							<div className='card-panel bg-alternate1-white py-1'>
								<h5 className='txt-alternate1'>
									<i className='material-icons fsize-larger mr-2'>
										announcement
									</i>
									Aun no hemos podido verificar tu correo.
								</h5>
								<p className='txt-black my-1'>
									Es opcional pero recomendado verificar tu correo para
									confirmar que eres tu.
								</p>
								<div className='right-align'>
									<button
										onClick={redirectToVerifyEmail}
										className='btn bg-alternate1-1'>
										<i className='material-icons right'>send</i>
										VERIFICAR CORREO
									</button>
								</div>
							</div>
						)}
						<div className='input-field'>
							<label htmlFor='email' className={user.email ? 'active' : ''}>
								Correo:
							</label>
							<input
								type='text'
								id='email'
								autoComplete='null'
								value={user.email}
								hidden={showEmailInput}
								disabled
							/>
							{showEmailInput && (
								<div>
									<input
										type='text'
										onChange={(e) => setEmail(e.target.value)}
									/>
									<button onClick={handleEmailChange}> Submit</button>
									<button onClick={() => setShowEmailInput(false)}>
										Cancel
									</button>
								</div>
							)}
						</div>
						{!showEmailInput && (
							<button
								onClick={() => setShowEmailInput(true)}
								className='btn bg-brand z-depth-0 mr-2'>
								Cambia tu correo
							</button>
						)}
						<button
							onClick={updatePasssword}
							className='btn bg-brand z-depth-0 '>
							Cambia tu Contraseña
						</button>
					</div>

					{/* TU NOMBRE */}
					{/* <hr className='my-4 mx-4' /> */}
					<div className='my-4'>
						<h5>Datos de Perfil</h5>
						<TextInput
							defaultValue={userProfile.firstName}
							id='firstName'
							label='Primer Nombre:'
							onChange={updateUserprofile}
							showIcon={false}
						/>
						<TextInput
							defaultValue={userProfile.middleName}
							id='middleName'
							label='Segundo Nombre:'
							onChange={updateUserprofile}
							showIcon={false}
						/>
						<TextInput
							defaultValue={userProfile.lastName}
							id='lastName'
							label='Apellido:'
							onChange={updateUserprofile}
							showIcon={false}
						/>
						<TextInput
							defaultValue={userProfile.secondLastName}
							id='secondLastName'
							label='Segundo Apellido:'
							onChange={updateUserprofile}
							showIcon={false}
						/>
						{/* <li>Telefono: {user.telefonoDeContacto} </li> */}
						<button onClick={updateUser} className='btn bg-brand z-depth-0 '>
							Guardar Cambios
						</button>
					</div>

					{/* OTROS DATOS */}
					<div className='my-4'>
						<h5>Otros Datos</h5>
						<ul className='container ml-4'>
							<li>
								Fecha de Nacimiento: {user.dateOfBirth}
								{/* {customDateFormatInSpanish(new Date(user.dateOfBirth))} */}
							</li>
							<li>Universidad: {user.universidad} </li>
							<li>Año de Graduación: {user.gradYear} </li>
							<li>
								Cuenta Creada:{' '}
								{customDateFormatInSpanish(new Date(user.signUpDate.seconds))}
							</li>
							{/* <li>Request a refund</li> */}
						</ul>
					</div>
					<div className='txt-g-1 d-flex' style={{ justifyContent: 'end' }}>
						<span>{user.uid}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Profile;
