import React from 'react';
import MainCheckout from './MainCheckout';

const CheckoutFail = () => {
	return (
		<React.Fragment>
			<div className='container mt-4'>
				<div className='card'>
					<div className='card-content'>
						<h2 className='txt-danger '>
							<i className='material-icons fsize-larger mr-2'>cancel</i>
							¡Atención!
						</h2>
						<p className='fsize-medium'>
							El pago fue cancelado, declinado o hubo un problema con la
							transacción. Por favor intente mas tarde o intenta completar tu
							pago con Paypal.
						</p>
					</div>
				</div>
			</div>

			<MainCheckout />
		</React.Fragment>
	);
};

export default CheckoutFail;
