import React from 'react';

const DisplayImage = (imageURL) => {
	console.info(imageURL);
	return (
		<div style={{ marginTop: '1rem', textAlign: 'center' }}>
			<img
				className='responsive-img'
				src={imageURL.imageURL}
				alt={imageURL.imageURL}
			/>
		</div>
	);
};

export default DisplayImage;
