import React from 'react';
import { FirestoreContext } from '../../../../utils/store/context/firestoreContext';

const AddAudioFile = ({ questionID, isEdit, hasAudio, fileName }) => {
	const {
		submitAudioFile,
		uploaderProgress,
		setUploaderProgress,
		updateQuestionbyKey,
		deleteQuestionField,
		clearAudioURL,
		getAudioURL,
		audioURL,
	} = React.useContext(FirestoreContext);

	const handleUpload = (e) => {
		submitAudioFile(e.target.files[0]);

		updateQuestionbyKey(questionID, 'audioFileName', e.target.files[0].name);
		// updateQuestionbyKey(questionID, 'needs_audio', false);
		// deleteQuestionField(questionID, 'needs_audio');
	};

	React.useEffect(() => {
		if (fileName !== '') getAudioURL(fileName);
		return () => {
			setUploaderProgress(false);
			clearAudioURL();
		};
		// eslint-disable-next-line
	}, []);

	return (
		<div
			className='card-panel br-2 white-text'
			style={{ background: 'var(--alternate1)' }}>
			<h5>
				{fileName ? (
					'Explicación en Audio:'
				) : (
					<>
						{!isEdit && (
							<>
								<i className='material-icons small'>warning</i> No encontramos
								explicacion en audio para esta pregunta
							</>
						)}
					</>
				)}
			</h5>

			{isEdit && !audioURL && (
				<div className='center w-75 m-auto'>
					<h6>
						<i className='material-icons small'>audio_file</i> No audio added,
						want to add one?
					</h6>
					<div
						className='input-field flex-center'
						style={{ flexDirection: 'column' }}>
						<progress
							value={uploaderProgress}
							max='100'
							id='uploader'
							className='mb-2'>
							0%
						</progress>

						{uploaderProgress === 100 && <div>Upload successful!</div>}

						{uploaderProgress !== 100 && (
							<input
								type='file'
								name='fileUpload'
								id='fileButton'
								accept='.mp3,audio/*'
								onChange={handleUpload}
							/>
						)}
					</div>
				</div>
			)}

			{audioURL && (
				<div className='center'>
					<audio controls>
						<source src={audioURL} type='audio/mpeg' />
						Your browser does not support the audio element.
					</audio>
				</div>
			)}
			{audioURL && isEdit && (
				<div>
					<span>Delete audio?</span>
					<button
						className='txt-danger'
						onClick={() => {
							deleteQuestionField(questionID, 'audioFileName');
							clearAudioURL();
						}}>
						<i className='material-icons'>delete</i>
					</button>
				</div>
			)}
		</div>
	);
};

export default AddAudioFile;
