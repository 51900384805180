import React from 'react';
import LogoDisplay from './LogoDisplay';

const Colaboradores = () => {
	return (
		<div className='container'>
			<LogoDisplay />
			<h4>Colaboradores</h4>
			<p>
				Listado de usuarios notables con reconocimiento por colaboraciones
				estratégicas,retroaliemntación valiosa, u otras colaboraciones.
			</p>
			<ul>
				<li>Names here...</li>
				<li>Names here...</li>
			</ul>

			<h4>Videos</h4>
			<p>Videos de entrevistas</p>
		</div>
	);
};

export default Colaboradores;
