//! This should change to a production URL later
const API =
	process.env.NODE_ENV === 'production'
		? 'https://us-central1-prep-med.cloudfunctions.net/api'
		: 'http://localhost:3333';
// const API = 'https://us-central1-prep-med.cloudfunctions.net/api';

/**
 * HELPER: fetch data from API.
 */
export async function fetchFromAPI(endpointURL, options) {
	// Takes enpoint URL and options method and body with "POST" and "null" as default values
	const { method, body } = { method: 'POST', body: null, ...options };

	// Request response from API
	const res = await fetch(`${API}/${endpointURL}`, {
		method,
		...(body && { body: JSON.stringify(body) }),
		headers: {
			'Content-Type': 'application/json',
		},
	});

	return res.json();
}
