import React from 'react';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';

const Back = (props) => {
	const { quill, quillRef, editor } = useQuill({
		modules: {
			toolbar: [
				['bold', 'italic', 'underline', 'strike'],
				[{ align: [] }],
				[{ list: 'ordered' }, { list: 'bullet' }],
				[{ size: ['small', false, 'large', 'huge'] }],
				[{ color: [] }, { background: [] }],

				['clean'],
			],
		},
	});

	React.useEffect(() => {
		if (quill && props.value) {
			quill.clipboard.dangerouslyPasteHTML(props.value);
		}
		// eslint-disable-next-line
	}, [quill]);

	React.useEffect(() => {
		if (quill && props.submitted) {
			quill.clipboard.dangerouslyPasteHTML('');
		}
		// eslint-disable-next-line
	}, [props.submitted]);

	return (
		<div style={{ width: '100%', height: 'auto' }}>
			<div
				ref={quillRef}
				onInput={() => props.action(editor.root.innerHTML)}
				onBlur={() => props.action(editor.root.innerHTML)}
				id='back'
				tabIndex={props.tabIndex}
			/>
		</div>
	);
};

export default Back;
