import React, { useEffect, useContext, useState, useReducer } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import M from 'materialize-css';
import { FirestoreContext } from '../../../utils/store/context/firestoreContext';
import { AuthContext } from '../../../utils/store/context/authContext';
import {
	redirectToDashboard,
	redirectToHome,
	redirectToVerifyEmail,
	shuffle,
} from '../../../utils/helpers';
import quizReducer from '../../../utils/store/context/reducers/quizReducer';
import {
	FILTER_QUESTIONS,
	FILTER_QUESTIONS_BY_SUBCATEGORIES,
	USE_TIMER,
	USE_TUTOR_MODE,
	TEST_TYPE,
	TEST_MODE,
	TEST_TIMER_DURATION,
	TEST_TOPICS,
	TEST_TOPIC_SUBCATEGORIES,
	TEST_LABEL,
	GET_QUESTIONS,
	// SET_TEST_QUESTIONS,
} from '../../../utils/store/context/types';
import TestSelectionsModal from './modals/InfoModal';
import { AdminContext } from '../../../utils/store/context/adminContext';
import { deleteDB } from '../../../utils/indexedDBHelpers';

const ChooseQuiz = (props) => {
	// SUBJECT
	// TODO: When I fix the protected routes issue, I need to make sure that if I reload the page I will be able to select "Categorias Especificas" - if unable to, then send to a blank page with a message saying "something" + a "Go Back" link hack
	// TODO: By SubCategory
	// TODO: Should be able to exclude topics

	// BY LABEL
	// TODO: PrepMed or ENURM (by Year) or all

	const {
		user,
		getUserTestRecords,
		userTestRecords,
		updateCurrentTestSelections,
	} = useContext(AuthContext);

	const { questions, getCategories, categories, getQuestions } =
		useContext(FirestoreContext);

	const { enurmData, nextEnurm } = useContext(AdminContext);

	let correctQuestions = [];
	let incorrectQuestions = [];

	const getRightWrongAnswers = (params) => {
		userTestRecords.forEach((record) => {
			record.questions?.forEach((question) => {
				if (
					question.correctAnswer === question.selectedAnswer &&
					!correctQuestions.includes(question.questionID)
				) {
					return correctQuestions.push(question.questionID);
				}
				if (
					question.correctAnswer !== question.selectedAnswer &&
					!incorrectQuestions.includes(question.questionID)
				) {
					// console.info(question.questionID);
					return incorrectQuestions.push(question.questionID);
				}
			});
		});
		// user.testRecords.forEach((record) => {
		// 	record.questions.forEach((question) => {
		// 		if (
		// 			question.correctAnswer === question.selectedAnswer &&
		// 			!correctQuestions.includes(question.questionID)
		// 		) {
		// 			return correctQuestions.push(question.questionID);
		// 		}
		// 		if (
		// 			question.correctAnswer !== question.selectedAnswer &&
		// 			!incorrectQuestions.includes(question.questionID)
		// 		) {
		// 			// console.info(question.questionID);
		// 			return incorrectQuestions.push(question.questionID);
		// 		}
		// 	});
		// });
	};

	const initialState = {
		allQuestions: [...questions],
		label: 'none',
		questions: [...questions],
		questionType: 'todas',
		testDuration: '',
		testLabel: 'all',
		testLength:
			props.location.state && props.location.state.hasOwnProperty('testLength')
				? props.location.state.testLength
				: 50,
		testMode: 'aleatorio',
		testType:
			props.location.state && props.location.state.hasOwnProperty('testType')
				? props.location.state.testType
				: 'pruebín',
		topics: [],
		topicSubcategories: [],
		showCategorySection: true,
		showTopicSelectionBoxes: false,
		useTimer: false,
		useTutorMode:
			props.location.state && props.location.state.useTutorMode
				? props.location.state.useTutorMode
				: false,
		userCorrectQuestions: correctQuestions,
		userIncorrectQuestions: incorrectQuestions,
		userTestRecords,
	};
	// questionTypes: 'todas', 'nuevas','repetidas'
	const [state, dispatch] = useReducer(quizReducer, initialState);
	const [testReady, setTestReady] = useState(false);
	const [loading, setLoading] = useState(false);
	const [subcategories, setSubcategories] = useState([]);
	const [showTestLengthInput, setShowTestLengthInput] = useState(
		state.testLength === 'otro' ? true : false
	);

	const handleSubmit = async (e) => {
		// TODO: Tengo k habilitar las preguntas nuevas/repetidas
		e.preventDefault();
		if (state.questions.length >= state.testLength) {
			// setShowMinimum(false);
			setTestReady(true);
			shuffle(state.questions);
		} else {
			if (state.testType === 'otro') {
				return window.alert(
					`No elegiste suficientes preguntas para tu examen. Debes elegir al menos 1 pregunta para un examen personalizado.`
				);
			}
			window.alert(
				`No hay suficientes preguntas (${state.questions.length}) para tu ${state.testType} que requiere un mínimo de ${state.testLength}. Agrega más categorías y vuelve a intentar.`
			);
		}
	};

	const updSelections = () => {
		updateCurrentTestSelections(state);
	};

	const getSubcategoryList = () => {
		let subcategories = [];

		state.topics.forEach((t) => {
			let result = categories.filter((c) => {
				return c.name === t;
			})[0];

			if (result.hasOwnProperty('sub_categories')) {
				subcategories.push(...result.sub_categories);
			}
		});

		setSubcategories(subcategories);
	};

	const getTotalQuestionsByCategory = (category) => {
		let count = questions.filter((q) => {
			return q.category === category;
		}).length;
		return count;
	};

	const handleDirectPull = (e) => {
		e.preventDefault();
		localStorage.removeItem('prepmed-last-questions-pull-date');
		deleteDB('prepmed-questions');

		if (
			window.confirm(
				'Esto puede tomar unos minutos dependiendo de tu connección, ¿estás segur@ que quieres recargar las preguntas?'
			)
		) {
			redirectToDashboard();
		} else {
		}
	};

	/* eslint-disable */
	useEffect(() => {
		M.AutoInit();
		// TODO: uncomment below for production
		getQuestions();
		getUserTestRecords();

		// SECURITIES
		if (
			user.isAdmin ||
			user?.freeTrialActive ||
			user[`membership_${nextEnurm}`]?.isPaid
		) {
			return;
		} else {
			redirectToDashboard();
		}
		enurmData?.testResultsOut && redirectToDashboard();
	}, []);

	useEffect(() => {
		if (questions.length > 0) {
			dispatch({
				type: GET_QUESTIONS,
				payload: questions,
			});
		}
	}, [questions]);

	useEffect(() => {
		const categorySelect = document.querySelector('#category-select');
		state.showCategorySection ? M.FormSelect.init(categorySelect) : null;
	}, [state.showCategorySection]);

	useEffect(() => {
		// if (state.showTopicSelectionBoxes) {
		// 	setLoading(true);
		getCategories();
		// 	setLoading(false);
		// }
	}, [state.showTopicSelectionBoxes]);

	useEffect(() => {
		// console.info(userTestRecords);
		userTestRecords.length > 0 && getRightWrongAnswers();
		// return () => {
		// 	cleanup
		// }
	}, [userTestRecords]);

	useEffect(() => {
		getSubcategoryList();
		// return () => {
		// 	cleanup
		// }
	}, [state.topics]);

	/* eslint-enable */

	return (
		<div className='container'>
			{!testReady && (
				<div className='row'>
					<h2 className='center-on-small-only txt-brand2'>
						Elige tu Modo de Examen
					</h2>
					{/* ===== DESCRIPTION ===== */}
					<div className='col s12 m6 '>
						<p>
							Para examinarte debes elegir la especificación de tus preguntas,
							estas son tus opciones:
						</p>
						<ul className='normal-bullets'>
							<li>Tipo "Pruebín", "Simulación" u otra cantidad de preguntas</li>
							<li>
								Sólo preguntas del ENURM, proprietarias de PrepMed o con todas
								las preguntas
							</li>
							<li> Modo "Aleatorio" o "Categorías Específicas"</li>
							<li>Con o sin límite de tiempo</li>
							<li>
								Con o sin modo tutor (El modo tutor te permite ver explicaciones
								de cada pregunta una vez eliges tu respuesta.)
							</li>
						</ul>
						<p>
							Un examen tipo "Pruebín" te presentará 50 preguntas, una
							"Simulación" te presentará 100 preguntas y además tendrás la
							opción de elegir otra cantidad de preguntas.
						</p>
						<p>
							El modo "Aleatorio", eligirá preguntas al azar de todas las
							categorias, todos los examenes y proprietarias de PrepMed.
						</p>
						<p>
							El modo "Categorías Espacíficas", te permitirá elegir las
							categorías de tus de preguntas.
						</p>

						<p>
							<strong>Nota:</strong> Si eliges tu examen con límite de tiempo,
							te recomendamos:
						</p>
						<ul className='normal-bullets'>
							<li>1 hora para pruebines</li>
							<li>2 horas para simulaciones</li>
							<li>2 minutos por pregunta si es personalizado</li>
						</ul>
					</div>

					{/* ===== FORM ===== */}
					<div className='col s12 m6'>
						<h4 className='hide-on-med-and-up center'>Crea tu Examen</h4>
						<form onSubmit={handleSubmit} className='flex-center'>
							{user.freeTrialActive && (
								<p className='bg-danger-lighter p-1 center'>
									<strong>Nota:</strong> Las cuentas de prueba solo tienen
									acceso al enurm 2020.
								</p>
							)}
							{/* Elige un tipo de examen */}
							<div
								className='input-field col s12 d-flex m-0'
								style={{ justifyContent: 'space-between' }}>
								<div className='d-flex-center'>
									<i
										className='material-icons center txt-brand2'
										style={{ fontSize: '2rem', marginLeft: '0.5rem' }}>
										ballot
									</i>
								</div>
								<div className='input-field col s11'>
									<select
										name='test-type'
										className='browser-default'
										defaultValue={state.testLength}
										onChange={(e) => {
											if (state.useTimer) {
												dispatch({
													type: USE_TIMER,
													payload: state.useTimer,
												});
											}
											if (e.target.value === 'otro') {
												setShowTestLengthInput(true);
												dispatch({
													type: TEST_TYPE,
													payload: null,
												});
											} else {
												setShowTestLengthInput(false);
												dispatch({
													type: TEST_TYPE,
													payload: parseInt(e.target.value),
												});
											}
										}}>
										<option value='50'> Pruebín (50 preguntas)</option>
										<option value='100'> Simulación (100 preguntas)</option>
										<option value='otro'>
											Elige otra cantidad de preguntas
										</option>
									</select>
									<label htmlFor='test-type' className='active'>
										Elige un tipo de examen
									</label>
								</div>
							</div>
							{showTestLengthInput && (
								<div className='input-field col s12'>
									<i className='material-icons prefix center txt-brand2'>
										dialpad
									</i>
									<label htmlFor='testLengthInput'>
										¿Qué cantidad de preguntas quieres?
									</label>
									<input
										type='number'
										id='testLengthInput'
										onChange={(e) =>
											dispatch({
												type: TEST_TYPE,
												payload: parseInt(e.target.value),
											})
										}
									/>
								</div>
							)}

							{/* Banco de Preguntas / LABEL */}
							<div
								className='input-field col s12 d-flex m-0'
								style={{ justifyContent: 'space-between' }}>
								<div className='d-flex-center'>
									<i
										className='material-icons center txt-brand2'
										style={{ fontSize: '2rem', marginLeft: '0.5rem' }}>
										label
									</i>
								</div>
								<div className='input-field col s11'>
									{user.freeTrialActive ? (
										<select
											name='label'
											className='browser-default'
											defaultValue={state.testLabel}
											onChange={(e) => {
												dispatch({
													type: TEST_LABEL,
													payload: e.target.value,
												});
											}}>
											<option value='all'>Todas las preguntas</option>
											{user.hasOwnProperty('lastTestScore') && (
												<option value='correctas'>Solo Correctas</option>
											)}
											{user.hasOwnProperty('lastTestScore') && (
												<option value='incorrectas'>Solo Incorrectas</option>
											)}
											<option value='enurm-2020'>ENURM 2020</option>
										</select>
									) : (
										<select
											name='label'
											className='browser-default'
											defaultValue={state.testLabel}
											onChange={(e) => {
												dispatch({
													type: TEST_LABEL,
													payload: e.target.value,
												});
											}}>
											<option value='all'>Todas las preguntas</option>
											<option value='prepmed'>Propietarias de PrepMed</option>
											{user.hasOwnProperty('lastTestScore') && (
												<option value='nuevas'>
													Preguntas no antes vistas
												</option>
											)}
											{user.hasOwnProperty('lastTestScore') && (
												<option value='correctas'>Solo Correctas</option>
											)}
											{user.hasOwnProperty('lastTestScore') && (
												<option value='incorrectas'>Solo Incorrectas</option>
											)}
											<option value='enurm-2024'>ENURM 2024</option>
											<option value='enurm-2023-A'>ENURM 2023</option>
											{/* 
											<option value='enurm-2023-B'>ENURM 2023 B</option>
											<option value='enurm-2023-C'>ENURM 2023 C</option>
											<option value='enurm-2023-D'>ENURM 2023 D</option>
											<option value='enurm-2023-E'>ENURM 2023 E</option> */}
											<option value='enurm-2022-A'>ENURM 2022 A</option>
											<option value='enurm-2022-B'>ENURM 2022 B</option>
											<option value='enurm-2022-C'>ENURM 2022 C</option>
											<option value='enurm-2022-D'>ENURM 2022 D</option>
											<option value='enurm-2021-A'>ENURM 2021 A</option>
											<option value='enurm-2021-B'>ENURM 2021 B</option>
											<option value='enurm-2021-C'>ENURM 2021 C</option>
											<option value='enurm-2020'>ENURM 2020</option>
											<option value='enurm-2019'>ENURM 2019</option>
											<option value='enurm-2018'>ENURM 2018</option>
											<option value='enurm-2017'>ENURM 2017</option>
											<option value='enurm-2016'>ENURM 2016</option>
											<option value='enurm-2015'>ENURM 2015</option>
											<option value='enurm-2014'>ENURM 2014</option>
											<option value='enurm-2013'>ENURM 2013</option>
											<option value='enurm-2012'>ENURM 2012</option>
											<option value='enurm-2011'>ENURM 2011</option>
											{/* <option value='enurm-2010'>ENURM 2010</option> */}
											<option value='enurm-2009'>ENURM 2009</option>
											<option value='enurm-2008'>ENURM 2008</option>
										</select>
									)}
									<label htmlFor='label' className='active'>
										Elige un banco de preguntas
									</label>
								</div>
							</div>

							{/* Elige un modo */}
							{state.showCategorySection && (
								<React.Fragment>
									<div
										className='input-field col s12 d-flex m-0'
										style={{ justifyContent: 'space-between' }}>
										<div className='d-flex-center'>
											<i
												className='material-icons center txt-brand2'
												style={{ fontSize: '2rem', marginLeft: '0.5rem' }}>
												tune
											</i>
										</div>
										<div className='input-field col s11'>
											<select
												id='category-select'
												className='browser-default'
												name='topic'
												defaultValue={state.mode}
												onChange={(e) => {
													dispatch({
														type: TEST_MODE,
														payload: e.target.value,
													});
												}}>
												<option value='aleatorio'>Aleatorio</option>
												<option value='categorías'>
													Categorías Específicas
												</option>
											</select>
											<label htmlFor='topic' className='active'>
												Elige un Modo
											</label>
										</div>
									</div>

									{/* Elige las categorias de interes */}
									{state.showTopicSelectionBoxes && (
										<div
											className='input-field col s12 mt-0'
											onChange={(e) =>
												dispatch({
													type: FILTER_QUESTIONS,
													payload: [...state.topics, e.target.name],
												})
											}>
											<div className='row m-1'>
												<div className='col offset-s1 s11'>
													<label htmlFor='categorias'>
														Elige las categorias de interés
													</label>
												</div>
											</div>
											<div className='col s12'>
												{categories
													.filter(
														(category) => category.id !== 'M8rTWnWd2Wh9WLZtsMQn'
													)
													.filter(
														(category) => category.id !== 'DpVZVQbCpngLsSuCXX8J'
													)
													.sort((a, b) => {
														if (a.name < b.name) {
															return -1;
														}
														if (a.name > b.name) {
															return 1;
														}
														return 0;
													})
													.map((category) => {
														return (
															<div key={uuidv4()} className='col s6 px-0 pb-1'>
																<label>
																	<input
																		type='checkbox'
																		className='filled-in'
																		name={category.name}
																		checked={
																			state.topics.includes(category.name)
																				? 'checked'
																				: null
																		}
																		onChange={(e) =>
																			dispatch({
																				type: TEST_TOPICS,
																				payload: e.target.name,
																			})
																		}
																	/>
																	<span>{category.name}</span>{' '}
																	<span className='txt-brand'>
																		(
																		{getTotalQuestionsByCategory(category.name)}
																		)
																	</span>
																</label>
															</div>
														);
													})}
											</div>
											{(state.testLabel === 'correctas' ||
												state.testLabel === 'incorrectas') && (
												<p className='center mb-0'>
													Total de Preguntas Disponibles:{' '}
													{state.questions.length}
												</p>
											)}
										</div>
									)}

									{/* Elige las sub-categorias de interes */}
									{state.topics.length > 0 && (
										<div
											className='input-field col s12 mt-0'
											onChange={(e) =>
												dispatch({
													type: FILTER_QUESTIONS_BY_SUBCATEGORIES,
													payload: [...state.topicSubcategories, e.target.name],
												})
											}>
											<div className='row m-1'>
												<div className='col offset-s1 s11'>
													<label htmlFor='categorias'>
														Elige las sub-categorias de interés
													</label>
												</div>
											</div>
											<div className='col s12'>
												{subcategories.map((subcategory) => {
													return (
														<div key={uuidv4()} className='col s6 px-0 pb-1'>
															<label>
																<input
																	type='checkbox'
																	className='filled-in'
																	name={subcategory.name}
																	checked={
																		state.topicSubcategories.includes(
																			subcategory.name
																		)
																			? 'checked'
																			: null
																	}
																	onChange={(e) =>
																		dispatch({
																			type: TEST_TOPIC_SUBCATEGORIES,
																			payload: e.target.name,
																		})
																	}
																/>
																<span>{subcategory.name}</span>
															</label>
														</div>
													);
												})}
											</div>
											{(state.testLabel === 'correctas' ||
												state.testLabel === 'incorrectas') && (
												<p className='center mb-0'>
													Total de Preguntas Disponibles:{' '}
													{state.questions.length}
												</p>
											)}
										</div>
									)}
								</React.Fragment>
							)}
							{/* questionType */}
							{user.totalQuestionsTaken > 500 && (
								<div
									className='input-field col s12 d-flex m-0'
									style={{ justifyContent: 'space-between' }}>
									<div className='d-flex-center'>
										<i
											className='material-icons center txt-brand2'
											style={{ fontSize: '2rem', marginLeft: '0.5rem' }}>
											question_answer
										</i>
									</div>
									<div className='input-field col s11'>
										<select
											name=''
											id=''
											defaultValue={state.questionType}
											className='browser-default'>
											<option value='todas'>Todas las preguntas</option>
											<option value='nuevas'>Sólo preguntas nuevas</option>
											<option value='viejas'>Sólo preguntas repetidas</option>
										</select>
										<label className='active'>
											¿Quieres solo preguntas nuevas o todas las preguntas?
										</label>
									</div>
								</div>
							)}

							{/* Timer */}
							{!state.useTutorMode && (
								<React.Fragment>
									<div className='input-field col s12'>
										<div className='col s12'>
											<label>
												<input
													type='checkbox'
													className='filled-in'
													checked={state.useTimer && 'checked'}
													onChange={() => {
														dispatch({
															type: USE_TIMER,
															payload: state.useTimer,
														});
													}}
												/>
												<span>
													¿Quieres tu{' '}
													{state.testType === 'otro'
														? 'examen'
														: state.testType}{' '}
													con límite de tiempo?
												</span>
											</label>
										</div>
									</div>
									{/* TIMER DURATION */}
									{state.useTimer && (
										<div
											className='input-field col s12 d-flex m-0'
											style={{ justifyContent: 'space-between' }}>
											<div className='d-flex-center'>
												<i
													className='material-icons center txt-brand2'
													style={{ fontSize: '2rem' }}>
													timer
												</i>
											</div>
											<div className='input-field col s11'>
												<select
													id='timer-select'
													className='browser-default'
													defaultValue={state.testDuration}
													onChange={(e) => {
														dispatch({
															type: TEST_TIMER_DURATION,
															payload: e.target.value,
														});
													}}>
													<option value='' disabled>
														- Elige un tiempo límite -
													</option>
													{process.env.NODE_ENV !== 'production' && (
														<option value={60}>1 Minuto</option>
													)}
													<option value={1800}>30 Minutos</option>
													<option value={3599.9}>1 Hora</option>
													<option value={5400}>1 Hora y Media</option>
													<option value={7200}>2 Horas</option>
													<option value={9000}>2 Horas y Media</option>
													<option value={10800}>3 Horas</option>
												</select>
												<label htmlFor='timer-select' className='active'>
													Elige tu tiempo límite
												</label>
											</div>
										</div>
									)}
								</React.Fragment>
							)}

							{/* Tutor Mode */}
							{!state.useTimer && (
								<div className='input-field col s12'>
									<div>
										<div className='col s12'>
											<label>
												<input
													type='checkbox'
													className='filled-in'
													checked={state.useTutorMode && 'checked'}
													onChange={() => {
														dispatch({
															type: USE_TUTOR_MODE,
															payload: state.useTutorMode,
														});
													}}
												/>
												<span>¿Quieres examinarte en modo tutor?</span>
											</label>
										</div>
									</div>
								</div>
							)}

							{/* Buttons */}
							<div className='input-field col s12'>
								{state.useTimer && state.testDuration === '' && (
									<p className='alert center'>
										Debes elegir un tiempo límite para continuar.
									</p>
								)}

								{state.questions.length < state.testLength && (
									<div>
										<p className='alert center'>
											No hay suficientes preguntas para examinarte con tu
											selección actual.
										</p>

										<p>
											Esto puede pasar si tienes una mala conección de internet
											y no se descargan todas las preguntas o si la categoría
											seleccionada no tiene suficientes preguntas para la
											cantidad que elegiste.
										</p>

										<p>
											Si crees que esto es un error, por favor, recarga tus
											preguntas disponibles dandole click al botón debajo:
										</p>

										<button
											className='btn bg-secondary'
											onClick={handleDirectPull}>
											Recargar Preguntas
										</button>
									</div>
								)}
								<button
									className='btn brand-bg right'
									type='submit'
									disabled={
										(state.useTimer && state.testDuration === '') ||
										state.testLength <= 0 ||
										isNaN(state.testLength) ||
										state.questions.length < state.testLength
									}>
									Empezar <i className='material-icons'>send</i>
								</button>
							</div>
						</form>
					</div>
				</div>
			)}
			{/* Link de empiezar el examen */}
			<TestSelectionsModal
				testReady={testReady}
				content={
					<div className='flex-center'>
						<div className=''>
							{state.testType === 'otro' ? (
								<p className='fsize-medium'>¿Estas listo para tu examen?</p>
							) : (
								<p className='fsize-medium'>
									¿Estas listo para tu <strong>{state.testType}</strong> (
									{state.testLength} preguntas){' '}
									{state.useTimer && <span>con tiempo límite</span>}
									{state.useTutorMode && <span>en modo tutor</span>}?
								</p>
							)}
						</div>
					</div>
				}
				submit={
					<Link
						className='modal-close btn-flat'
						to={{
							pathname: '/examen',
							// state: { ...state },
						}}
						onClick={updSelections}>
						<span>
							Sí <i className='material-icons right'>send</i>
						</span>
					</Link>
				}
			/>
		</div>
	);
};

export default ChooseQuiz;
