import React from 'react';
// import { v4 as uuidv4 } from 'uuid';
import { FirestoreContext } from '../../../utils/store/context/firestoreContext';

const EditLabelTags = ({ id, question }) => {
	const {
		categories,
		getCategories,
		updateQuestionTagsAdd,
		updateQuestionTagsDelete,
		updateTagsLS,
	} = React.useContext(FirestoreContext);
	const [tags, setTags] = React.useState(
		question.hasOwnProperty('tags') ? question.tags : []
	);
	// const [tags, setTags] = React.useState(
	// 	question.hasOwnProperty('tags') ? [...question.tags] : []
	// );

	const handleChange = (e) => {
		let tag = e.target.id.toLowerCase();

		// REMOVE IF EXISTING IN QUESTION
		if (tags.includes(tag)) {
			// let i = tags.indexOf(tag);
			// console.info(i);
			setTags(tags.filter((t) => t !== tag));
			updateQuestionTagsDelete(id, tag, tags);
			return;
		}

		// ADD IF NOT EXISTENT IN QUESTION
		setTags([tag, ...tags]);
		updateQuestionTagsAdd(id, tag, tags);
	};

	React.useEffect(() => {
		getCategories();
		return () => {
			setTags([]);
		};
		// eslint-disable-next-line
	}, []);
	React.useEffect(() => {
		updateTagsLS(id, tags);
		// eslint-disable-next-line
	}, [tags]);

	return (
		<div>
			Labels:
			<ul className='no-list-style m-0'>
				{categories &&
					categories.map((category) => {
						return (
							<li key={category.id}>
								<label htmlFor={category.name}>
									<input
										className='filled-in'
										type='checkbox'
										id={category.name}
										checked={tags.includes(category.name.toLowerCase())}
										onChange={handleChange}
									/>
									<span>{category.name}</span>
								</label>
							</li>
						);
					})}
			</ul>
		</div>
	);
};

export default EditLabelTags;
