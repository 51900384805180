import React, { useContext, useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { isMobileScreen } from '../../../utils/helpers';
import { AuthContext } from '../../../utils/store/context/authContext';

const SearchQuestions = () => {
	const { userTestRecords } = useContext(AuthContext);
	const [isSearching, setIsSearching] = useState(false);
	const [userQuestions, setUserQuestions] = useState([]);
	const [tempData, setTempData] = useState([]);

	useEffect(() => {
		let questions = [];
		userTestRecords?.forEach((test) => {
			questions.push(...test.questions);
		});
		setUserQuestions(questions);
		//   return () => {
		// 	second
		//   }
	}, []);

	const getQuestionsFromTestRecords = () => {
		return;
	};

	const clearSearch = (e) => {
		e.target.value = '';
		setIsSearching(false);
	};

	const handleSearch = (e) => {
		setIsSearching(true);
		let search = e?.target?.value.toLowerCase();

		if (userQuestions.length > 0) {
			let data = userQuestions.filter((q) => {
				return (
					q.question.toLowerCase().includes(search) ||
					q.explanation.toLowerCase().includes(search) ||
					q.correctAnswer.toLowerCase().includes(search)
				);
			});
			setTempData([...data]);
		}

		if (search === '') setIsSearching(false);
	};

	return (
		<div style={{ minHeight: '100vh' }}>
			<div className='container'>
				<div className='mt-4 center'>
					<h3 className='mt-2'>Buscar Preguntas Vistas</h3>
					<p className={`mx-auto ${isMobileScreen ? 'w-90' : 'w-75'}`}>
						En este módulo podrás buscar entre las preguntas que a has visto
						antes en simulaciones o pruebines. Solo escribe palabras encontradas
						en la pregunta o la explicación para facilitar tu busqueda.
					</p>
					{/* SEARCH */}
					<div
						className={`mx-auto mt-2 mb-3 ${
							isMobileScreen ? 'w-100' : 'w-50'
						}`}>
						<div className='input-field'>
							<input
								type='text'
								name='search'
								id='search'
								// placeholder='¿Qué estás buscando?'
								onChange={handleSearch}
								onFocus={clearSearch}
								// onBlur={() => setIsSearching(false)}
								autoComplete='off'
							/>
							<label htmlFor='search'>
								<i className='material-icons'>search</i> ¿Qué estás buscando?
							</label>
						</div>
					</div>

					{isSearching &&
						tempData?.map((record) => {
							return (
								<div className='card' key={record.questionID}>
									<div className='card-content card-content-reduced-padding dark-text text-darken-3 left-align'>
										<div className='card-title'>{parse(record.question)}</div>
										{/* // !EXPLICACION */}
										<details>
											<summary className='txt-brand-accent'>
												{/* <span className='material-icons'>visibility</span> */}
												Ver Respuesta y Explicación
											</summary>
											<div className='card-panel white grey-text text-darken-3 left-align z-depth-0 p-0'>
												<h5 className='mt-0'>Respuesta Correcta:</h5>
												<div>{record.correctAnswer}</div>
												<h5>Explicación:</h5>
												<div className='explanation'>
													{parse(record.explanation)}
												</div>
											</div>
										</details>
									</div>
								</div>
							);
						})}
				</div>
			</div>
		</div>
	);
};

export default SearchQuestions;
