import React from 'react';

const ListaDeEspera = () => {
	React.useEffect(() => {
		window.location.replace('https://mailchi.mp/prepmed/enurm25');

		// return () => {
		//     cleanup
		// }
	}, []);
	return <div>Espera un momento por favor...</div>;
};

export default ListaDeEspera;
