import React from 'react';
import { FirestoreContext } from '../../../utils/store/context/firestoreContext';
// import { removeHTMLTags } from '../../../utils/helpers';
import Front from './Front';
import Back from './Back';
import ImageUpload from './ImageUpload';
import LoadingSection from '../../Helpers/LoadingSection';

const EditFlashcard = (props) => {
	const {
		flashcards,
		flashcard_addCategory,
		flashcard_removeCategory,
		getCategories,
		categories,
		updateFlashcard,
		getImageURL,
		imageURL,
		deleteFlashcardImage,
	} = React.useContext(FirestoreContext);
	const [flashcard, setFlashcard] = React.useState({});
	const [front, setFront] = React.useState('');
	const [back, setBack] = React.useState('');
	const [image, setImage] = React.useState('');
	const [updateMSG, setUpdateMSG] = React.useState('');
	const [loadingCategories, setLoadingCategories] = React.useState(true);
	const [categoriesArr, setCategoriesArr] = React.useState([]);

	let flashcard_id = props.location.state.flashcard_id;

	React.useEffect(() => {
		categories.length <= 0 && getCategories();
		let thisFlashcard = flashcards.filter((f) => f.id === flashcard_id);

		setFlashcard(thisFlashcard[0]);
		setCategoriesArr([thisFlashcard[0]?.categories]);
		setLoadingCategories(false);
		setFront(thisFlashcard[0]?.front);
		setBack(thisFlashcard[0]?.back);

		if (thisFlashcard[0]?.image) {
			getImageURL(thisFlashcard[0]?.image, 'flashcard-images');
			setImage(thisFlashcard[0]?.image);
		}

		// console.info(thisFlashcard[0], thisFlashcard.length < 1);
		if (!(thisFlashcard.length > 0)) {
			window.history.back();
		}
		// eslint-disable-next-line
	}, []);

	React.useEffect(() => {
		// THIS UPDATES THE FLASHCARD STATE IF THERE'S ANY CHANGE
		if (flashcard?.hasOwnProperty('createTS')) {
			setFlashcard({ ...flashcard, front, back, image });
		}
		// eslint-disable-next-line
	}, [front, back, image]);

	// const listCategories = (card) => {
	// 	let x = categories.filter((c) => card.categories.includes(c.id));
	// 	return x;
	// };

	const handleImgDelete = () => {
		deleteFlashcardImage(flashcard_id);
		setImage(null);
	};

	const handleCategory = (e) => {
		// console.info(e.target.id);
		setLoadingCategories(true);
		let categoryArray =
			flashcard.categories.length > 0 ? [...flashcard.categories] : [];
		let target = e.target.id;

		if (flashcard.categories.includes(target)) {
			flashcard_removeCategory(flashcard, target);
			let index = categoryArray.indexOf(target);
			categoryArray.splice(index, 1);
			setFlashcard({ ...flashcard, categories: [...categoryArray] });
			setCategoriesArr([...categoryArray]);
		} else {
			flashcard_addCategory(flashcard, target);
			categoryArray.push(target);
			setFlashcard({ ...flashcard, categories: [...categoryArray] });
			setCategoriesArr([...categoryArray]);
		}
		setLoadingCategories(false);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		// console.info('updated', flashcard);
		updateFlashcard(flashcard);
		// window.location.replace('/dashboard');
		setUpdateMSG('Flashcard change saved.');
	};

	if (flashcard) {
		return (
			<div className='container'>
				<div className='card mx-2'>
					<div className='card-content'>
						<div className='right-align'>
							<button onClick={() => window.history.back()}>
								Devuelta al Dashboard
							</button>
						</div>
						<h2 className='txt-brand2'>Editar Flashcard</h2>
						<div>
							<h5>Parte visible</h5>
							<Front
								value={front}
								action={(content) =>
									setFront(content.replace(/(<p><br><\/p>)/g, ''))
								}
							/>
						</div>
						<div>
							<h5>Parte escondida</h5>
							<Back
								value={flashcard.back}
								action={(content) =>
									setBack(content.replace(/(<p><br><\/p>)/g, ''))
								}
							/>
						</div>
						{flashcard?.image?.length > 0 ? (
							<div className='my-4'>
								<h5 className='mb-2'>Cambia la imagen</h5>
								<div className='row flex-center'>
									<div className='col s12 m5'>
										<img
											src={imageURL}
											alt={image}
											className='responsive-img'
										/>
									</div>
									<div className='col s12 m7'>
										<div
											className='d-flex'
											style={{ justifyContent: 'center' }}>
											<button className='text-danger' onClick={handleImgDelete}>
												<i className='material-icons'>delete</i> Borrar Imagen
											</button>
										</div>
										<ImageUpload
											onUpload={(path) => setImage(path)}
											imgFolder='flashcard_images'
										/>
									</div>
								</div>
							</div>
						) : (
							<div className='my-3'>
								<h5 className='mb-2'>Incluye una imagen</h5>
								<div>
									<ImageUpload
										onUpload={(path) => setImage(path)}
										imgFolder='flashcard_images'
									/>
								</div>
							</div>
						)}
						<div className='right-align mt-2'>
							{updateMSG && <div className='txt-success'>{updateMSG}</div>}
							<button
								className='btn bg-red mr-2'
								onClick={() => window.history.back()}>
								Cancelar
							</button>
							{categoriesArr.length > 0 && flashcard.front && flashcard.back && (
								<button className='btn bg-brand' onClick={handleSubmit}>
									Guardar Cambios
								</button>
							)}
						</div>
						<div>
							<h5>Category</h5>
							<p className='p-1 bg-danger white-text'>
								Cambios a las categorías se guardan automáticamente.
							</p>
							{loadingCategories ? (
								<LoadingSection />
							) : (
								<div>
									{categories.map((c) => {
										return (
											<div key={c.id}>
												<label>
													<input
														className={
															flashcard?.categories?.includes(c.id)
																? 'filled-in'
																: ''
														}
														id={c.id}
														type='checkbox'
														checked={
															flashcard?.categories?.includes(c.id)
																? true
																: false
														}
														onChange={handleCategory}
													/>
													<span>{c.name}</span>
												</label>
											</div>
										);
									})}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
	return <div>No encontramos ningun flashcard. Por favor intenta denuevo.</div>;
};

export default EditFlashcard;
