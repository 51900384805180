import React from 'react';
import { AuthContext } from '../../../utils/store/context/authContext';
import InputCedula from '../../Helpers/InputCedula';
import InputNota from '../../Helpers/InputNota';
import SelectFromEspecialidades from '../../Helpers/SelectFromEspecialidades';

const PostTestConsultationSurvey = (props) => {
	const { user, storePostTestConsultation } = React.useContext(AuthContext);

	const [cedula, setCedula] = React.useState('');
	// const [nota, setNota] = React.useState(0);
	const [especialidad1, setEspecialidad1] = React.useState();
	const [especialidad2, setEspecialidad2] = React.useState();
	const [showSubmit, setShowSubmit] = React.useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();
		let data = {
			cedula,
			// nota_final: nota,
			especialidad_target_1: especialidad1,
			especialidad_target_2: especialidad2,
		};
		storePostTestConsultation(data);
	};

	return (
		<div className='mb-4'>
			<>
				<h3 className='txt-brand2'>
					Consulta las Estadísticas de tus Plazas Deseadas
				</h3>
				{/* <p>
					Los resultados del ENURM 2021 ya salieron, pero sabemos que tu proceso
					aún no termina.
				</p> */}
				<p>
					Queremos acompañarte en los pasos que siguen y por eso estamos
					ofreciéndote una consulta estadística gratis. Al consultar tendrás
					acceso a la nota promedio, mínima y máxima de dos de tus
					especialidades de interés.
				</p>

				<p>
					¿Por que necesitamos tu información? Utilizaremos esta información
					para calcular la nota promedio de los usuarios y continuar mejorando
					la plataforma.{' '}
				</p>

				<p>
					Utilizamos la información demográfica para mejorar la plataforma en
					base a las necesidades especificas de diferentes grupos demográficos
					(Universidad, Años post graduación, etc). Utilizaremos esta
					información para crear nuestra lista de ganadores ENURM 2023
				</p>
				<p>Para consultar, completa los siguientes datos:</p>
			</>
			{/* //~FORM STARTS HERE */}
			<form onSubmit={handleSubmit}>
				<h5 className='mt-2 center txt-brand2'>
					¿Cuáles son tus 2 especialidades de interés?
				</h5>
				{/* CEDULA */}
				<InputCedula handleChange={setCedula} />
				{/* NOTA */}
				{/* <InputNota handleChange={setNota} /> */}
				{/* ESPECIALIDAD 1 */}
				<div className='input-field col s12 m6'>
					<div>
						<i className='material-icons txt-brand2'>assignment_ind</i>
						<span style={{ marginLeft: '2rem', color: '#9e9e9e' }}>
							1ra Especialidad de Interés
						</span>
					</div>
					<SelectFromEspecialidades
						id='especialidad_1'
						handleChange={setEspecialidad1}
					/>

					{/* <label htmlFor={id}>Especialidad de Interés</label> */}
					<sup className='ml-4'>(requerido)</sup>
				</div>

				{/* ESPECIALIDAD 2 */}
				<div className='input-field col s12 m6'>
					<div>
						<i className='material-icons txt-brand2'>assignment_ind</i>
						<span style={{ marginLeft: '2rem', color: '#9e9e9e' }}>
							2da Especialidad de Interés
						</span>
					</div>
					<SelectFromEspecialidades
						id='especialidad_2'
						handleChange={setEspecialidad2}
					/>
					{/* <label htmlFor={id}>Especialidad de Interés</label> */}
					<sup className='ml-4'>(requerido)</sup>
				</div>

				{/* BUTTONS */}
				{cedula && especialidad1 && especialidad2 && (
					<div className='right-align'>
						{/* READY BUTTON */}
						{!showSubmit && (
							<button
								className='btn bg-red'
								onClick={() => setShowSubmit(true)}>
								¿Listo para Consultar?
							</button>
						)}
						{/* SUBMIT BUTTON */}
						{showSubmit && (
							<button className='btn btn-large bg-brand' onClick={handleSubmit}>
								<i className='material-icons right'>send</i>Consultar
								Estadisticas
							</button>
						)}
					</div>
				)}
				<p>
					<b>IMPORTANTE:</b> Solo podrás consultar una sola vez y tienes un
					máximo de dos especialidades.
				</p>
			</form>
			{/* DISCLAIMER */}
			<blockquote className='mt-4'>
				Los resultados de tu consulta son datos históricos del ENURM que fueron
				hechos públicos y recolectados por PrepMedRD.
			</blockquote>
			{/* SHOW RESULTS */}
		</div>
	);
};

export default PostTestConsultationSurvey;
