import React from 'react';
import { AuthContext } from '../../../utils/store/context/authContext.js';
import LoadingSection from '../../Helpers/LoadingSection.js';
import PostTestConsultationSurvey from '../surveys/PostTestConsultationSurvey.js';
import ReviewRequest from '../surveys/ReviewRequest';
import PostTestGradeConsultationResults from './PostTestGradeConsultationResults.js';

const PostTestDashboard = () => {
	const { user } = React.useContext(AuthContext);
	const [loading, setLoading] = React.useState(true);

	const [consultationYear, setConsultationYear] = React.useState('');
	const [reviewYear, setReviewYear] = React.useState('');

	React.useEffect(() => {
		let year = user.enurmTargetYear;
		let field = `post_test_consultation_${year}`;
		let review = `post_test_review_${user.enurmTargetYear}`;
		setConsultationYear(field);
		setReviewYear(review);
		setLoading(false);
	}, []);

	if (loading) {
		return <LoadingSection />;
	}

	// USER HASN'T SELECTED ESPECIALIDADES A CONSULTAR
	if (!user[consultationYear]) {
		return (
			<main className='dashboard'>
				<div className='container'>
					<PostTestConsultationSurvey onFormSubmit={setLoading} />;
				</div>
			</main>
		);
	}
	// USER SELECTED ESPECIALIDADES BUT HASN'T MADE DESICION ON REVIEW
	if (user[consultationYear] && !user[reviewYear]) {
		return (
			<main className='dashboard'>
				<div className='container'>
					{' '}
					<ReviewRequest />
				</div>
			</main>
		);
	}

	// USER SELECTED ESPECIALIDADES & DECIDED ON REVIEW
	if (user[consultationYear] && user[reviewYear]) {
		return (
			<main className='dashboard'>
				<div className='container'>
					<PostTestGradeConsultationResults
						interests={user[consultationYear]}
					/>
				</div>
			</main>
		);
	}
	return <div>Por favor comunícate con nosotros.</div>;
};

export default PostTestDashboard;
