import React from 'react';

const Progress = (props) => {
	return (
		<span>
			<span style={{ fontSize: 'larger', fontWeight: '600' }}>
				{props.currentQuestion + 1}{' '}
			</span>{' '}
			/ <span style={{ fontSize: 'smaller' }}>{props.total}</span>
		</span>
	);
};

export default Progress;
