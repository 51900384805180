import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import quizReducer from '../../../../utils/store/context/reducers/quizReducer';
import { REPORT_ERROR } from '../../../../utils/store/context/types';

const ReportAnIssueModal = (props) => {
	const initialState = {
		isMissingExplanation: false,
		isMissingQuestion: false,
		isMissingAnswers: false,
		isMissingTheExplanationSource: false,
		hasGramaticalError: false,
		hasFormattingIssue: false,
		issues: { list: [], message: '' },
	};
	const [state, dispatch] = useReducer(quizReducer, initialState);

	const handleMessage = (e) => {
		dispatch({
			type: REPORT_ERROR,
			payload: {
				name: e.target.name,
				value: e.target.value,
			},
		});
	};

	const handleSubmit = () => {
		props.update(props.questionID, 'issues', state.issues);
	};

	return (
		<span style={{ position: 'relative' }}>
			{/* Modal link trigger */}
			<a
				className='report-issues modal-trigger'
				href={`#report-an-issue-${props.questionID}`}
				title='Reporta un error o problema'>
				<i className='material-icons'>feedback</i>
			</a>

			{/* Modal hidden body */}
			<div
				className='modal modal-fixed-footer modal-report'
				id={`report-an-issue-${props.questionID}`}
				// style={modalStyle}
			>
				{/* modal content */}
				<div className='modal-content' style={{ paddingBottom: '0' }}>
					<div className='row'>
						<h4 className='center-align fsize-3'>
							¿Qué quieres reportar sobre esta pregunta?
						</h4>
					</div>
					<div className='input-field col s12 mt-0 left-align'>
						<div className='row'>
							<div className='col offset-s1 s11 m5 offset-m1'>
								<label htmlFor='isMissingAnswers'>
									<input
										name='isMissingAnswers'
										id='isMissingAnswers'
										type='checkbox'
										checked={state.isMissingAnswers ? 'checked' : ''}
										className='filled-in'
										onChange={(e) =>
											dispatch({
												type: REPORT_ERROR,
												payload: {
													name: e.target.name,
													value: state[e.target.name],
												},
											})
										}
									/>
									<span>Faltan respuestas</span>
								</label>
							</div>
							<div className='col offset-s1 s11 m5 offset-m1'>
								<label htmlFor='hasGramaticalError'>
									<input
										name='hasGramaticalError'
										type='checkbox'
										id='hasGramaticalError'
										checked={state.hasGramaticalError ? 'checked' : ''}
										className='filled-in'
										onChange={(e) =>
											dispatch({
												type: REPORT_ERROR,
												payload: {
													name: e.target.name,
													value: state[e.target.name],
												},
											})
										}
									/>
									<span>Hay faltas ortográficas</span>
								</label>
							</div>
							<div className='col offset-s1 s11 m5 offset-m1'>
								<label htmlFor='isMissingQuestion'>
									<input
										type='checkbox'
										name='isMissingQuestion'
										id='isMissingQuestion'
										checked={state.isMissingQuestion ? 'checked' : ''}
										className='filled-in'
										onChange={(e) =>
											dispatch({
												type: REPORT_ERROR,
												payload: {
													name: e.target.name,
													value: state[e.target.name],
												},
											})
										}
									/>
									<span>No hay pregunta</span>
								</label>
							</div>
							<div className='col offset-s1 s11 m5 offset-m1'>
								<label htmlFor='isMissingExplanation'>
									<input
										type='checkbox'
										name='isMissingExplanation'
										id='isMissingExplanation'
										checked={state.isMissingExplanation ? 'checked' : ''}
										className='filled-in'
										onChange={(e) =>
											dispatch({
												type: REPORT_ERROR,
												payload: {
													name: e.target.name,
													value: state[e.target.name],
												},
											})
										}
									/>
									<span>No hay explicación</span>
								</label>
							</div>
							<div className='col offset-s1 s11 m5 offset-m1'>
								<label htmlFor='hasFormattingIssue'>
									<input
										type='checkbox'
										name='hasFormattingIssue'
										id='hasFormattingIssue'
										checked={state.hasFormattingIssue ? 'checked' : ''}
										className='filled-in'
										onChange={(e) =>
											dispatch({
												type: REPORT_ERROR,
												payload: {
													name: e.target.name,
													value: state[e.target.name],
												},
											})
										}
									/>
									<span>Un problema con el formato</span>
								</label>
							</div>
							<div className='col offset-s1 s11 m5 offset-m1'>
								<label htmlFor='isMissingTheExplanationSource'>
									<input
										type='checkbox'
										name='isMissingTheExplanationSource'
										id='isMissingTheExplanationSource'
										checked={
											state.isMissingTheExplanationSource ? 'checked' : ''
										}
										className='filled-in'
										onChange={(e) =>
											dispatch({
												type: REPORT_ERROR,
												payload: {
													name: e.target.name,
													value: state[e.target.name],
												},
											})
										}
									/>
									<span>No hay fuente de explicación</span>
								</label>
							</div>
						</div>
					</div>
					{/* Notas */}
					<div className='modal-textarea col s12'>
						<div className='input-field m-0'>
							<i className='material-icons prefix'>insert_comment</i>
							<textarea
								name='issueMessage'
								id='issueMessage'
								type='text'
								className='materialize-textarea'
								onChange={handleMessage}></textarea>
							<label htmlFor='issueMessage'>
								<span> (Opcional) Más información:</span>
							</label>
						</div>
					</div>
				</div>
				{/* modal footer */}
				<div
					className='modal-footer d-flex'
					style={{ justifyContent: 'start', paddingTop: '0' }}>
					<a className='modal-close btn-flat' href='#!'>
						Cerrar
					</a>
					<a className='modal-close btn-flat' href='#!' onClick={handleSubmit}>
						<i className='material-icons right'>submit</i>
						Enviar
					</a>
				</div>
			</div>
		</span>
	);
};

// const modalStyle = {
// 	// width: '60%',
// 	height: '50%',
// };

ReportAnIssueModal.propTypes = {
	update: PropTypes.func.isRequired,
	questionID: PropTypes.string.isRequired,
	// content: PropTypes.any,
	// fieldKey: PropTypes.string.isRequired,
	// materialSmall: PropTypes.bool,
	// triggerCSS: PropTypes.string.isRequired,
};

export default ReportAnIssueModal;

// componentDidMount() {
// 	const options = {
// 		onOpenStart: () => {
// 			console.log('Open Start');
// 		},
// 		onOpenEnd: () => {
// 			console.log('Open End');
// 		},
// 		onCloseStart: () => {
// 			console.log('Close Start');
// 		},
// 		onCloseEnd: () => {
// 			// this.setState({ ...this.state, type: '' });
// 			console.log('Close End');
// 		},
// 		inDuration: 250,
// 		outDuration: 250,
// 		opacity: 0.5,
// 		dismissible: false,
// 		startingTop: '4%',
// 		endingTop: '10%',
// 	};
// 	M.Modal.init(this.Modal, options);

// 	let instance = M.Modal.getInstance(this.Modal);
// 	instance.open();
// 	// instance.close();
// 	// instance.destroy();
// }
