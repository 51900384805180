import React from 'react';
import { AuthContext } from '../../../../utils/store/context/authContext';
import InputCedula from '../../../Helpers/InputCedula';

const AgregaTuCedula = () => {
	const { user, updateCedula } = React.useContext(AuthContext);
	const [cedula, setCedula] = React.useState('');
	const [cedulaInvalida, setCedulaInvalida] = React.useState(true);

	const handleSubmit = (e) => {
		e.preventDefault();

		updateCedula(user.uid, cedula);
	};

	React.useEffect(() => {
		const isValid = new RegExp('[0-9]{3}[-][0-9]{7}[-][0-9]|[0-9]{11}').test(
			cedula
		);
		if (isValid) {
			setCedulaInvalida(false);
		}
	}, [cedula]);

	return (
		<div className='card-panel bg-alternate1-white py-1'>
			<h5 className='txt-alternate1'>
				<i className='material-icons fsize-larger mr-2'>announcement</i>
				Necesitamos Tu Cédula
			</h5>

			<p>
				Para mantener la seguridad de tu cuenta, PrepMedRD ahora{' '}
				<strong>requiere</strong> de todos los usuarios una verificación
				aleatoria de su número de cédula y para esto necesitamos tu número de
				cédula.
			</p>
			<p>
				Por favor, introduce y agrega tu número de cédula para continuar tu
				cuenta estará bloqueada temporalmente hasta que puedas proveernos una
				cédula válida.
			</p>
			<form onSubmit={handleSubmit} className='mt-2'>
				<div className='container'>
					<div className='w-65 m-auto'>
						<InputCedula handleChange={setCedula} />
					</div>
				</div>
			</form>

			<div className='right-align'>
				<button
					className='btn btn-large bg-brand'
					disabled={cedulaInvalida}
					onClick={handleSubmit}>
					<i className='material-icons right'>send</i>Actualizar Cédula
				</button>
			</div>
		</div>
	);
};

export default AgregaTuCedula;
