import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { secondsToHoursMinutesSeconds } from '../../../../utils/helpers';
// import { AuthContext } from '../../../../utils/store/context/authContext';
import Fuente from './Fuente';
import Explanation from './Explanation';

const FinalResults = ({ state }) => {
	// const { user, storeUserTestResults } = React.useContext(AuthContext);
	let count = 1;
	const score = Math.round((state.score / state.testLength) * 100);
	const [showQuestionsSummary, setShowQuestionsSummary] = useState(false);

	useEffect(() => {
		// const thisTestData = {
		// 	questions: [...state.selectedQuestionsAndAnswers],
		// 	score: state.score,
		// 	testDuration: state.timeElapsedInSeconds,
		// 	testLabel: state.userTestSelections.testLabel,
		// 	testLength: state.testLength,
		// 	testMode: state.userTestSelections.testMode,
		// 	testTopics: [...state.userTestSelections.topics],
		// 	testType: state.userTestSelections.testType,
		// 	useTimer: state.userTestSelections.useTimer,
		// 	useTutorMode: state.userTestSelections.useTutorMode,
		// };

		// storeUserTestResults(user.uid, thisTestData);

		// CLEANUP
		return () => {
			setShowQuestionsSummary(false);
			console.log('Component dismounted');
		};
		/*eslint-disable-next-line */
	}, []);

	const scrollToTop = () => {
		const top = document.querySelector('div.prepmed');
		top.scrollIntoView({
			alignToTop: true,
			behavior: 'smooth',
		});
	};

	return (
		<React.Fragment>
			<div className='container'>
				{showQuestionsSummary && (
					<div style={{ position: 'sticky', top: '90vh', zIndex: '1' }}>
						<button
							onClick={scrollToTop}
							title='Edit Question'
							className='btn btn-floating right bg-brand'
							style={
								window.innerWidth < 790
									? {
											margin: '0.10vmin',
											position: 'absolute',
											right: '-5vw',
									  }
									: {
											margin: '0.10vmin',
											position: 'absolute',
											right: '-3vw',
									  }
							}>
							<i className='material-icons'>north</i>
						</button>
					</div>
				)}
				<section className='row'>
					<div className='col s12'>
						<main className='test-results card'>
							<h4 className='d-inline'>Resumen de tu Examen</h4>
							<p>
								{parse(
									`Tuviste <strong>${
										state.score
									}</strong> respuestas correctas de tu ${
										state.userTestSelections.testType === 'otro'
											? 'examen personalizado'
											: state.userTestSelections.testType
									} de <strong>${
										state.testLength
									}</strong> preguntas, para una nota final de:`
								)}
							</p>
							<div className='row'>
								<div className='col s6 center'>
									<strong style={{ fontSize: 'xx-large' }}>
										{state.score}
									</strong>
									/{state.testLength}
								</div>
								<div className='col s6 center'>
									<strong style={{ fontSize: 'xx-large' }}>
										{`${Math.round(((score + Number.EPSILON) / 100) * 100)}%`}
									</strong>
								</div>
							</div>
							{state.userTestSelections.useTimer && (
								<p>
									Terminaste tu{' '}
									{state.userTestSelections.testType === 'otro'
										? 'examen personalizado'
										: state.userTestSelections.testType}{' '}
									en un tiempo total de:{' '}
									{secondsToHoursMinutesSeconds(state.timeElapsedInSeconds)}
								</p>
							)}

							<div className='card-action center'>
								{!showQuestionsSummary && (
									<button
										className='btn bg-brand'
										onClick={(e) => setShowQuestionsSummary(true)}>
										Ver Preguntas
									</button>
								)}
								{showQuestionsSummary && (
									<Link to='/elige' className='btn btn-flat'>
										<span className='material-icons'>refresh</span>{' '}
										<span>Examínate De Nuevo</span>
									</Link>
								)}
								<button
									className='btn bg-secondary ml-0-m ml-2'
									onClick={() => {
										window.location.reload();
									}}>
									<span className='material-icons'>dashboard</span>{' '}
									<span>Panel Principal</span>
								</button>
								{/* <Link to='/dashboard' className='btn btn-flat'>
									<span className='material-icons'>dashboard</span>{' '}
									<span>Panel Principal</span>
								</Link> */}
							</div>
						</main>
					</div>
				</section>
				{showQuestionsSummary && (
					<section className='row'>
						<div className='col s12'>
							{state.selectedQuestionsAndAnswers.map(
								({
									questionID,
									question,
									correctAnswer,
									explanation,
									selectedAnswer,
									fuente,
								}) => {
									return (
										<div className='card'>
											<div className='card-content'>
												<div
													key={questionID}
													className={
														selectedAnswer === correctAnswer
															? 'test-result-right-answer'
															: 'test-result-wrong-answer'
													}>
													<article>
														<span>
															{`${count++})`} {parse(question)}
														</span>
													</article>
													<div>
														<p>
															<i
																className='material-icons'
																style={{ marginTop: '-4px' }}>
																{selectedAnswer === correctAnswer
																	? 'check_box'
																	: 'error'}
															</i>
															<strong>Tu selección:</strong> {selectedAnswer}
														</p>
														{selectedAnswer !== correctAnswer && (
															<p>
																<i
																	className='material-icons'
																	style={{ marginTop: '-4px' }}>
																	forward
																</i>
																<strong>Respuesta Correcta:</strong>{' '}
																{correctAnswer}
															</p>
														)}
													</div>
												</div>
												<details>
													<summary className='txt-brand-accent'>
														Explicación
													</summary>
													<div className='row'>
														<div className='col s12'>
															<div className='card-panel white grey-text text-darken-3 left-align z-depth-0'>
																<h5>Explicación:</h5>
																<div className='explanation'>
																	{parse(explanation)}
																</div>
																{/* <Explanation currentQuestion={}/> */}
																<Fuente id={questionID} />
															</div>
														</div>
													</div>
												</details>
											</div>
										</div>
									);
								}
							)}
						</div>
					</section>
				)}
			</div>
		</React.Fragment>
	);
};

export default FinalResults;
