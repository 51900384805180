import React from 'react';
import Counter from './counters/Counter';
const Stats = ({ questions }) => {
	return (
		<div>
			<Counter questions={questions} />
			<h2>Stats</h2>
			<p>Stats</p>
			<h2>Progress</h2>
			<p>Stats</p>

			<h4>Year 1 Goals:</h4>
			<ul>
				<li>Functionality (Pe)</li>
				<li>Content (Feli)</li>
				<li>Reputation</li>
			</ul>
		</div>
	);
};

export default Stats;
