import React, { useState, useRef, useContext } from 'react';
import { Link, Redirect } from 'react-router-dom';
import M from 'materialize-css';
import { isMobileScreen, scrollToTop } from '../../../../utils/helpers';
import {
	// checkValidation,
	showInvalidMessage,
} from '../../../../utils/validation_helpers';
import { AuthContext } from '../../../../utils/store/context/authContext';
import { logError, logEvent } from '../../../../utils/store/config/fbConfig';
import LoadingSection from '../../../Helpers/LoadingSection';
import { LOGIN_PAGE } from '../../../../utils/ROUTES';

const Step1 = ({ nextStep, newUserSignup, thisUser, updateThisUser }) => {
	const { deleteUserOnFailedSignup } = useContext(AuthContext);

	//~ STATE & REFS
	//#region
	//~ REFS
	const emailRef = useRef();
	const passwordRef = useRef();
	const passwordConfirmRef = useRef();

	//~ STATE
	const [loading, setLoading] = useState(false);
	const [readyForNextStep, setReadyForNextStep] = useState(false);

	//~ INPUT FIELDS
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [passwordConf, setPasswordConf] = useState('');
	const [passwordsMatch, setPasswordsMatch] = useState(false);
	const [showPasswords, setShowPasswords] = useState(false);

	//~ VALIDATIONS
	const [allFieldsValid, setAllFieldsValid] = useState(false);
	const [failMessage, setFailMessge] = useState('');
	const [showErrorMessage, setShowErrorMessage] = useState('');
	const [signupErrorMessage, setSignupErrorMessage] = useState('');
	//#endregion

	//~ LOGIC
	// #region
	React.useEffect(() => {
		scrollToTop();
		M.AutoInit();
	}, []);

	React.useEffect(() => {
		email && checkPasswordsMatch();
		// eslint-disable-next-line
	}, [password, passwordConf]);

	const checkPasswordsMatch = (e) => {
		// if (passwordRef.current?.value === passwordConfirmRef.current?.value) {
		if (password !== passwordConf) {
			setPasswordsMatch(false);
		} else {
			setPasswordsMatch(true);
			checkIfAllFieldsValid();
		}
	};

	const checkIfAllFieldsValid = () => {
		setShowErrorMessage('');
		if (
			emailRef.current?.validity.valid &&
			passwordRef.current?.validity.valid &&
			// passwordRef.current.value === passwordConfirmRef.current.value
			password === passwordConf
		) {
			setSignupErrorMessage(false);
			setAllFieldsValid(true);
			setShowErrorMessage('');
		} else {
			setAllFieldsValid(false);
			if (!emailRef.current?.validity.valid) {
				setShowErrorMessage(
					'Por favor verifíca el campo "Correo Electrónico".'
				);
				return;
			}
			if (!passwordRef.current?.validity.valid) {
				setShowErrorMessage(
					'La contraseña debe tener al menos 1 letra mayúscula, 1 minúscula, 1 numero y un mínimo de 6 caracteres.'
				);
				return;
			}
			// if (passwordRef.current?.value !== passwordConfirmRef.current?.value) {
			if (password !== passwordConf) {
				setShowErrorMessage('Las contraseñas no son iguales.');
				return;
			}
		}
	};

	const handleChange = (e) => {
		e.target.setCustomValidity('');
		setShowErrorMessage('');
		setSignupErrorMessage('');
		setFailMessge('');

		switch (e.target.id) {
			case 'email':
				setEmail(e.target.value.toLowerCase());
				break;
			case 'password':
				setPassword(e.target.value);
				break;
			case 'password-confirmation':
				setPasswordConf(e.target.value);

				break;
			default:
				break;
		}
	};

	const handleSignupErrors = (err) => {
		//~ HANDLE DIFFERENT ERROR CODES HERE
		// https://firebase.google.com/docs/auth/admin/errors
		if (err.code === 'auth/email-already-exists') {
			setSignupErrorMessage(
				`Ya existe un usuario con este correo. Por favor cambia el correo electrónico o dirígete a la nuestra página de 'Iniciar Sesión' si olvidaste tu contraseña.`
			);
			logEvent('SIGNUP_ERROR: email-already-exists');
			logError(err, email);
		}
		if (err.code === 'auth/email-already-in-use') {
			setSignupErrorMessage(
				`Ya existe un usuario con este correo. Si habías creado una cuenta previamente, por favor dirigete a la nuestra página de 'Iniciar Sesión' o usa otro correo electrónico.`
			);
			logEvent('SIGNUP_ERROR: email-already-in-use');
			logError(err, email);
		}
		if (err.code === 'auth/invalid-email') {
			setSignupErrorMessage('El formato del correo electrónico es inválido.');
			logEvent('SIGNUP_ERROR: invalid-email');
			logError(err, email);
		}
		if (err.code === 'auth/invalid-password') {
			setSignupErrorMessage(
				'La contraseña debe tener un míninmo de 6 digitos.'
			);
			logEvent('SIGNUP_ERROR: invalid-password');
			logError(err, password);
		}
		if (err.code === 'auth/invalid-phone-number') {
			setSignupErrorMessage('El formato del telefono es incorrecto.');
			logEvent('SIGNUP_ERROR: invalid-phone-number');
		}
		if (err.code === 'auth/phone-number-already-exists') {
			setSignupErrorMessage('El número de telefono ya existe.');
			logEvent('SIGNUP_ERROR: phone-number-exists');
		}
		if (err.message === 'invalid date') {
			setSignupErrorMessage('La fecha de nacimiento es invalida.');
			logEvent('SIGNUP_ERROR: invalid date');
		}

		resetForm();
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		allFieldsValid && submitSignupRequest();
	};

	const submitSignupRequest = async () => {
		scrollToTop();
		setLoading(true);
		let signupResult = await newUserSignup(email, password);
		// console.info('SIGNUP REQUEST RESULT:', signupResult);

		// If signup failed
		if (signupResult.hasOwnProperty('signupErroredOut')) {
			if (
				signupResult.code === 'auth/email-already-in-use' ||
				signupResult.code === 'auth/email-already-in-use'
			) {
				let deleteResult = await deleteUserOnFailedSignup(email);
				// If nothing is returned for "deleteResult", means nothing was deleted and there were no errors
				if (deleteResult) {
					// If unable to delete, log the error so it's reflected on admin Dashboard
					logError(deleteResult, email);
					if (deleteResult?.code === 'auth/requires-recent-login') {
					}
				}
				if (deleteResult === 'Auth account deleted') {
					setSignupErrorMessage(
						'Hubo un error creando tu cuenta, por favor intenta de nuevo.'
					);
					logEvent(
						'Signup error and successfully deleted account so user could try again.'
					);
				}
			}

			resetForm();
			// Report signup error to the user
			handleSignupErrors(signupResult);
			return;
		}

		// If signup was successful
		if (signupResult.hasOwnProperty('uid')) {
			updateThisUser({ email, password, uid: signupResult.uid, ...thisUser });
			setReadyForNextStep(true);
			setLoading(false);
		}
	};

	const resetForm = () => {
		setPassword('');
		setPasswordsMatch(false);
		setPasswordConf('');
		setEmail('');
		setFailMessge('Debes re-ingresar tu correo y contraseña para continuar.');
		setAllFieldsValid(false);
		setTimeout(() => {
			setLoading(false);
		}, 1000);
	};
	// #endregion

	if (loading) {
		return <LoadingSection message='Creando tu cuenta...' />;
	}

	if (readyForNextStep) {
		return (
			<form onSubmit={() => nextStep()}>
				<div className='mx-auto mb-3' style={{ width: '85%' }}>
					<p className='txt-brand2'>
						¡Excelente! Tu cuenta fue creada satisfactoriamente. Los próximos
						pasos son para llenar tus datos personales.
					</p>
					<p className='txt-brand2'>Presiona el botón para continuar.</p>
				</div>
				{/* //~NEXT STEP MESSAGE */}
				<div className='right-align mb-4'>
					<button
						className='btn btn-large bg-brand'
						// onClick={(e) => setDoB(dobRef.current.value)}
						// onClick={() => nextStep()}
						id='ready-for-next-step'
						type='submit'>
						Continuar
						<i className='material-icons right'>send</i>
					</button>
				</div>
			</form>
		);
	}

	return (
		<form
			onSubmit={handleSubmit}
			onChange={() => {
				checkIfAllFieldsValid();
			}}
			autoComplete='off'>
			<h6 className='mb-3'>
				Si ya tienes cuenta del año pasado, no tienes que volver a crearla. Solo
				ve a <Link to={LOGIN_PAGE}>Iniciar Sesión</Link> para continuar.
			</h6>

			{/*//~ EMAIL */}
			<div className='input-field col s12'>
				<i className='material-icons prefix txt-brand'>email</i>
				<label htmlFor='email'>Correo Electrónico</label>
				<input
					type='email'
					id='email'
					onChange={handleChange}
					ref={emailRef}
					pattern='[\S]+@[\S]+\.[\S]+'
					value={email}
					required
				/>
				<span className={emailRef.current?.validity && 'invalid'}></span>
				<sup className='ml-4'>(requerido)</sup>
			</div>

			{/*//~ PASSWORD */}
			<div className='input-field col s12'>
				<i className='material-icons prefix txt-brand'>lock</i>
				<label htmlFor='password'>Contraseña</label>
				<input
					type={showPasswords ? 'text' : 'password'}
					id='password'
					ref={passwordRef}
					onChange={handleChange}
					minLength={6}
					pattern='^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$'
					autoComplete='off'
					value={password}
					required
				/>
				<i
					className='material-icons'
					hidden={passwordRef.current?.validity?.valid}
					onClick={() => setShowPasswords(!showPasswords)}>
					visibility
				</i>
				<span></span>
				<sup className='ml-4' hidden={!passwordRef.current?.validity?.valid}>
					(requerido)
				</sup>
				<p
					className='invalid-text'
					hidden={password.length <= 0 || passwordRef.current?.validity?.valid}>
					{/* (requerido)  */}
					Debe tener al menos 1 letra mayúscula, 1 minúscula, 1 numero y un
					mínimo de 6 caracteres.
				</p>
			</div>

			{/*//~ CONFIRMACION DE PASSWORD */}
			<div className='input-field col s12'>
				<i className='material-icons prefix txt-brand'>lock_open</i>
				<label htmlFor='password-confirmation'>Confirma tu contraseña</label>
				<input
					type={showPasswords ? 'text' : 'password'}
					id='password-confirmation'
					onChange={handleChange}
					ref={passwordConfirmRef}
					// minLength={6}
					// pattern='^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$'
					onInvalid={(event) =>
						showInvalidMessage(event, 'Las contraseñas no son iguales.')
					}
					autoComplete='off'
					required
				/>
				<i
					className='material-icons'
					hidden={passwordsMatch}
					onClick={() => setShowPasswords(!showPasswords)}>
					visibility
				</i>
				<span hidden={!passwordsMatch}></span>
				<sup className='ml-4' hidden={!passwordsMatch}>
					(requerido)
				</sup>
				<p
					className='invalid-text'
					hidden={
						passwordConfirmRef.current?.value === passwordRef.current?.value
					}>
					{/* (requerido)  */}
					Las contraseñas no son iguales.
				</p>
			</div>

			{/*//~ VALIDATION ERRORS */}
			{(signupErrorMessage || !allFieldsValid || showErrorMessage) && (
				<div className='row'>
					<div
						className='txt-danger center-align col s12'
						style={{ display: 'block' }}>
						{signupErrorMessage && (
							<p className='bg-danger p-1 white-text center'>
								{signupErrorMessage}{' '}
							</p>
						)}
						{failMessage && (
							<p className='bg-danger p-1 white-text center'>{failMessage}</p>
						)}
						{!allFieldsValid && showErrorMessage && !signupErrorMessage && (
							<p className='bg-danger p-1 white-text center'>
								{showErrorMessage}
							</p>
						)}
					</div>
				</div>
			)}

			{/*//~ SIGNUP READY */}
			{allFieldsValid && (
				<div className='mx-auto' style={{ width: '85%' }}>
					{/* <p className='txt-brand2'>
							Presiona el botón de 'Continuar' para llenar tus datos personales.
						</p> */}
					<p className='txt-brand2 left-align' style={{ fontSize: 'small' }}>
						Al presionar "Crea mi Cuenta" estás aceptando nuestros{' '}
						<Link
							to='/terminos-de-servicio'
							target='_blank'
							className='normal-links'>
							Términos de Servicio
						</Link>
						, y nuestras{' '}
						<Link
							to='/terminos-de-servicio'
							target='_blank'
							className='normal-links'>
							Políticas de Privacidad
						</Link>
						.
					</p>
					<p className='txt-brand2 left-align' style={{ fontSize: 'small' }}>
						Tu información no será compartida, ni vendida. ¡Gracias por
						prepararte con nosotros!
					</p>
				</div>
			)}
			{allFieldsValid && (
				<div className='right-align mb-4'>
					<button
						onClick={submitSignupRequest}
						className='btn btn-large bg-brand'>
						{' '}
						Crea tu Cuenta
						<i className='material-icons right'>send</i>
					</button>
				</div>
			)}
		</form>
	);
};

export default Step1;
