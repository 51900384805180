import React, { useState, useEffect } from 'react';
import { secondsToHoursMinutesSeconds } from '../../../../utils/helpers';

const Timer = (props) => {
	const [timer, setTimer] = useState(props.testDuration);

	useEffect(() => {
		if (props.startTimer) {
			const timerID = window.setInterval(() => {
				setTimer((timer) => {
					console.info(timer, timer === 0);
					if (timer === 0) {
						return props.stopTest();
					}
					return timer - 1;
				});
			}, 1000);
			return () => {
				window.clearInterval(timerID);
			};
		}
		return undefined;
		/*eslint-disable-next-line */
	}, [props.startTimer]);

	useEffect(() => {
		props.updateTimerStartTime(Date.now());
		return () => {
			if (props.timeElapsed !== 0) {
				props.updateTimeElapsed(Date.now());
			}
		};
		/*eslint-disable-next-line */
	}, []);

	return (
		<React.Fragment>
			<span className='timer-separator'> | </span>
			<span>
				<i className='material-icons material-small-font'>timer</i>{' '}
				{secondsToHoursMinutesSeconds(timer)}
			</span>
		</React.Fragment>
	);
};

export default Timer;
