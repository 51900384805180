import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

const CounterofSubCategories = (props) => {
	const [setOfSubCategories, setS] = useState([]);

	let collectionOfSubcategories = [];
	useEffect(() => {
		props.questions.forEach((item) => {
			if (item.hasOwnProperty('subCategory')) {
				return collectionOfSubcategories.push(item.subCategory);
			}
			return collectionOfSubcategories.push(item.sub_category);
		});
		setS([...new Set(collectionOfSubcategories.sort())]);
		/*eslint-disable-next-line */
	}, []);

	return (
		<div>
			{setOfSubCategories.map((each) => {
				return (
					<li key={uuidv4()} className='collection-item'>
						<span>{each === undefined ? 'No Categories' : each}:</span>
						<b className='bold'>
							{props.questions.reduce((total, item) => {
								if (item.hasOwnProperty('subCategory')) {
									return total + (item.subCategory === each ? 1 : 0);
								}
								return total + (item.sub_category === each ? 1 : 0);
							}, 0)}
						</b>
					</li>
				);
			})}
		</div>
	);
};

export default CounterofSubCategories;
