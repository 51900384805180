import React, { useContext, useEffect } from 'react';
import { FirestoreContext } from '../../../../../utils/store/context/firestoreContext';

const SelectCategories = (props) => {
	const { changeFn } = props;

	/* ===== FIREBASE CONTEXT =====*/
	const { getCategories, categories } = useContext(FirestoreContext);
	/* ===== OTHER VARIABLES =====*/

	/* eslint-disable */
	useEffect(() => {
		getCategories();
	}, []);
	/* eslint-enable */

	const handleSelectChange = (e) => {
		changeFn(e);
	};

	return (
		<div className='input-field'>
			{/* ===== SELECT A CATEGORY ===== */}
			<select
				className='browser-default'
				id='category'
				defaultValue={props.content}
				onChange={handleSelectChange}
				tabIndex='1'>
				<option value=''></option>
				{categories &&
					categories.map((option) => {
						return (
							<option
								value={`${option.name}`}
								key={`${option.id}`}>{`${option.name}`}</option>
						);
					})}
			</select>
			<label htmlFor='category'></label>
		</div>
	);
};

export default SelectCategories;
