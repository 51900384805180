import React, { useEffect, useContext } from 'react';
import { AdminContext } from '../../../../utils/store/context/adminContext';

import LoadingSection from '../../../Helpers/LoadingSection';
import DataSimulaciones from './DataSimulaciones';
import EnurmBreakdown from './EnurmBreakdown';
import PostTestConsultAndReview from './PostTestConsultAndReview';
import Sales from './SalesSummary';
import TrueActiveUsers from './TrueActiveUsers';
import SignupsByUniversity from './SignupsByUniversity';
import ListaDeUsuarios from './ListaDeUsuarios';
import ListaDeUsuariosPagos from './ListaDeUsuariosPagos';
import ListaDeUsuariosNoPagos from './ListaDeUsuariosNoPagos';
import Reviews from './Reviews';
import DataSimulacionesV2 from './DataSimulasionesV2';

// TODO: Convert dates to numbers
function ReportsDashboard() {
	const { getUserList, users } = useContext(AdminContext);

	const [loading, setLoading] = React.useState(false);
	const [showSalesSummary, setShowSalesSummary] = React.useState(false);
	const [showEnurmBreakdown, setShowEnurmBreakdown] = React.useState(false);
	const [showSignupsbyUni, setShowSignupsbyUni] = React.useState(false);
	const [showPostTest, setShowPostTest] = React.useState(false);
	const [showListaDeUsuarios, setShowListaDeUsuarios] = React.useState(false);
	const [showListaDeUsuariosPagos, setShowListaDeUsuariosPagos] =
		React.useState(false);
	const [showListaDeUsuariosNoPagos, setShowListaDeUsuariosNoPagos] =
		React.useState(false);
	const [showTrueActiveUsers, setShowTrueActiveUsers] = React.useState(false);
	const [showDataSimulaciones, setShowDataSimulaciones] = React.useState(false);
	const [showDataSimulacionesV2, setShowDataSimulacionesV2] =
		React.useState(false);
	const [showReviews, setShowReviews] = React.useState(false);

	useEffect(() => {
		if (showSalesSummary && users.length <= 0) {
			getUserList();
		}

		return () => {};
		/*eslint-disable-next-line */
	}, [showSalesSummary]);

	useEffect(() => {
		if (users.length > 0) {
			setLoading(false);
		}
		return () => {};
	}, [users]);

	const handleReportSelect = (e) => {
		setLoading(true);

		// ~hide all
		setShowTrueActiveUsers(false);
		setShowEnurmBreakdown(false);
		setShowSalesSummary(false);
		setShowSignupsbyUni(false);
		setShowPostTest(false);
		setShowListaDeUsuarios(false);
		setShowListaDeUsuariosPagos(false);
		setShowListaDeUsuariosNoPagos(false);
		setShowDataSimulaciones(false);
		setShowDataSimulacionesV2(false);
		setShowReviews(false);

		if (e.target.value === 'sales-summary') {
			setShowSalesSummary(true);
		}
		if (e.target.value === 'true-active-users') {
			setShowTrueActiveUsers(true);
		}
		if (e.target.value === 'enurm-breakdown') {
			setShowEnurmBreakdown(true);
		}
		if (e.target.value === 'signups-by-uni') {
			setShowSignupsbyUni(true);
		}
		if (e.target.value === 'lista-usuarios') {
			setShowListaDeUsuarios(true);
		}
		if (e.target.value === 'lista-usuarios-pagos') {
			setShowListaDeUsuariosPagos(true);
		}
		if (e.target.value === 'lista-usuarios-no-pagos') {
			setShowListaDeUsuariosNoPagos(true);
		}
		if (e.target.value === 'post-test-consult-review') {
			setShowPostTest(true);
		}
		if (e.target.value === 'data-simulaciones') {
			setShowDataSimulaciones(true);
		}
		if (e.target.value === 'data-simulacionesv2') {
			setShowDataSimulacionesV2(true);
		}
		if (e.target.value === 'reviews') {
			setShowReviews(true);
		}
		setLoading(false);
	};

	// if (loading) {
	// 	return (
	// 		<div className='mx-2 pt-3 mb-4'>
	// 			{loading && <div>Esta pagina puede durar mucho en cargar...</div>}
	// 		</div>
	// 	);
	// }

	return (
		<div className='mx-2 pt-3 mb-4'>
			{loading && <div>Esta pagina puede durar mucho en cargar...</div>}

			{loading ? (
				<LoadingSection />
			) : (
				<div>
					{/* //~REPORT SELECT */}
					<div>
						<label htmlFor='reports'>Elige el reporte que quieres ver</label>
						<select
							name='reports'
							id='reports'
							className='browser-default'
							defaultChecked='default'
							onChange={handleReportSelect}>
							<option value='default'>- Elige uno -</option>
							<option value='sales-summary'>Sales Summary</option>
							<option value='data-simulacionesv2'>Data Simulaciones v2</option>
							<option value='data-simulaciones'>Data Simulaciones</option>
							<option value='enurm-breakdown'>Enurm Breakdown</option>
							<option value='post-test-consult-review'>
								Post Test Consult & Review
							</option>
							<option value='signups-by-uni'>Signups by Uni</option>
							<option value='true-active-users'>True Active Users</option>
							<option value='lista-usuarios'>Lista de Usuarios</option>
							<option value='lista-usuarios-pagos'>
								Lista de Usuarios Pagos
							</option>
							<option value='reviews'>Reviews</option>
							<option value='lista-usuarios-no-pagos'>
								Lista de Usuarios No Pagos
							</option>
						</select>
					</div>
				</div>
			)}

			{/* // TODO: need free trial info active/expired*/}

			{/* //~SALES SUMMARY */}
			{users && showSalesSummary && (
				<>{loading ? <LoadingSection /> : <Sales users={users} />}</>
			)}

			{/* //~TRUE ACTIVE USERS REPORT */}
			{showTrueActiveUsers && (
				<>{loading ? <LoadingSection /> : <TrueActiveUsers users={users} />}</>
			)}

			{/* //~POST TEST SURVEY CONSULT */}
			{showPostTest && (
				<>
					{loading ? (
						<LoadingSection />
					) : (
						<PostTestConsultAndReview users={users} />
					)}
				</>
			)}

			{/* //~ENURM BREAKDOWN REPORT */}
			{showEnurmBreakdown && (
				<>
					<h4>Enurm Breakdown</h4>
					{loading ? <LoadingSection /> : <EnurmBreakdown />}
				</>
			)}

			{/* //~UNIVERSITY COUNTER */}
			{showSignupsbyUni && (
				<>
					<h4>Sales over Total Signups by Uni</h4>
					{loading ? <LoadingSection /> : <SignupsByUniversity users={users} />}
				</>
			)}

			{/* //~DATA SIM REPORT */}
			{showDataSimulacionesV2 && (
				<>{loading ? <LoadingSection /> : <DataSimulacionesV2 />}</>
			)}
			{/* //~DATA SIM REPORT */}
			{showDataSimulaciones && (
				<>{loading ? <LoadingSection /> : <DataSimulaciones />}</>
			)}

			{/* //~LISTA DE USUARIOS */}
			{showListaDeUsuarios && (
				<>{loading ? <LoadingSection /> : <ListaDeUsuarios />}</>
			)}

			{/* //~LISTA DE USUARIOS PAGOS */}
			{showListaDeUsuariosPagos && (
				<>{loading ? <LoadingSection /> : <ListaDeUsuariosPagos />}</>
			)}

			{/* //~LISTA DE USUARIOS NO PAGOS */}
			{showListaDeUsuariosNoPagos && (
				<>{loading ? <LoadingSection /> : <ListaDeUsuariosNoPagos />}</>
			)}
			{/* //~REVIEWS */}
			{showReviews && <>{loading ? <LoadingSection /> : <Reviews />}</>}
		</div>
	);
}

export default ReportsDashboard;
