import React from 'react';
import { customDateNumbersOnly } from '../../../../utils/helpers';
import { AdminContext } from '../../../../utils/store/context/adminContext';
// import { FirestoreContext } from '../../../../utils/store/context/firestoreContext';
import LoadingSection from '../../../Helpers/LoadingSection';
import SelectENURMYear from '../../../Helpers/SelectENURMYear';
import { useSortableData } from '../../../Helpers/useSortableData';

const DataSimulaciones = () => {
	const { getUserTestRecords, testRecords, getUsersWhere } =
		React.useContext(AdminContext);

	const [searchSubmitted, setSearchSubmitted] = React.useState(false);
	// const [loading, setLoading] = React.useState(false);
	const [reportData, setReportData] = React.useState([]);
	const [reportYear, setReportYear] = React.useState('');
	const [year, setYear] = React.useState();

	const { items, requestSort } = useSortableData(reportData, {
		key: 'userLastName',
		direction: 'descending',
	});

	React.useEffect(() => {
		if (reportYear) {
			let date = new Date(reportYear);
			let yr = date.getFullYear() + 1;
			setYear(yr);
		}

		return () => {
			setSearchSubmitted(false);
		};
		// eslint-disable-next-line
	}, [reportYear]);

	React.useEffect(() => {
		const getRecords = async () => {
			let data = await getUserTestRecords();
			console.info(data);
		};

		testRecords.length <= 0 && getRecords();
		// return () => {
		//     cleanup
		// }
	}, []);

	const countNoOfTests = (uid, type) => {
		let count = testRecords.filter((r) => {
			return r.user_uid === uid && r.testLabel === type;
		});
		return count.length;
	};

	const handleSubmit = async () => {
		let data = await getUsersWhere(`isClient_${year}`, '==', true);
		console.info('Report Data:', data);
		// getActiveUsers(data);
		setReportData(data);
		setSearchSubmitted(true);
	};

	// LOADING
	if (searchSubmitted && reportData.length <= 0) {
		return <LoadingSection padding='5vh' message='Loading data....' />;
	}

	// if (searchSubmitted && (testRecords.length <= 0 || reportData.length <= 0)) {
	// 	return <LoadingSection padding='5vh' message='Loading data....' />;
	// }

	return (
		<>
			<div className='mt-2 row'>
				<h5 className='txt-brand-2'>Reporte para Data de las Simulationes</h5>
				<SelectENURMYear onChange={(yr) => setReportYear(yr)} />
				{!searchSubmitted && (
					<div className='input-field col s12'>
						<button
							type='submit'
							className='btn bg-brand'
							disabled={year === undefined}
							onClick={handleSubmit}>
							Submit
						</button>
					</div>
				)}
			</div>
			{/* //~ REPORT */}
			{searchSubmitted && (
				<div style={{ overflowX: 'scroll' }}>
					{reportData.length < 1 && <p>No results found...</p>}
					{reportData.length > 0 && (
						<>
							<p>
								Esta tabla representa la data de simulasiones de usuarios con el
								ENURM target year: <strong>{year}</strong>. (For the time being,
								the only sortable columns are: User, Signup & Total Tests)
							</p>

							<table>
								<thead style={thead}>
									<tr>
										<td
											className='c-pointer'
											onClick={() => requestSort('firstName')}>
											Nombre
										</td>
										<td
											className='c-pointer'
											onClick={() => requestSort('lastName')}>
											Apellido
										</td>
										<td style={center}>UNI</td>
										<td style={center}>
											<span
												className='c-pointer'
												onClick={() => requestSort('signUpDate')}>
												Signup
											</span>
										</td>
										<td style={center}>ENURM Score</td>
										<td
											style={center}
											className='c-pointer'
											onClick={() => requestSort('testLabel')}>
											Aleatorio
										</td>
										<td style={center}>PrepMed</td>
										<td style={center}>2020</td>
										<td style={center}>2019</td>
										<td style={center}>2018</td>
										<td style={center}>2017</td>
										<td style={center}>2016</td>
										<td style={center}>2012</td>
										<td style={center}>Correctas</td>
										<td style={center}>Incorrectas</td>
										<td style={center}>
											<span
												className='c-pointer'
												onClick={() => requestSort('totalTestRecords')}>
												Total Tests
											</span>
										</td>
										<td style={center}>With Timer</td>
										<td style={center}>Tutor Mode</td>
										<td style={center}>Modo Aleatorio</td>
										<td style={center}>Por Categoría</td>
										<td style={center}>Pruebin</td>
										<td style={center}>Simulación</td>
										<td style={center}>Personalizado</td>
									</tr>
								</thead>
								<tbody>
									{reportData &&
										items.map((u) => {
											return (
												<tr>
													<td>{u.firstName}</td>
													<td>{u.lastName}</td>
													<td style={center}>{u.universidad}</td>
													<td style={center}>
														{/* {customDateNumbersOnly(u.signUpDate.seconds * 1000)} */}
														{u.signUpDate.toDate().toString().substr(0, 16)}
													</td>
													<td style={center}>
														{u.post_test_consultation
															? u.post_test_consultation?.nota_final
															: '0'}
													</td>
													<td style={center}>{countNoOfTests(u.id, 'all')}</td>
													<td style={center}>
														{countNoOfTests(u.id, 'prepmed')}
													</td>
													<td style={center}>
														{countNoOfTests(u.id, 'enurm-2020')}
													</td>
													<td style={center}>
														{countNoOfTests(u.id, 'enurm-2019')}
													</td>
													<td style={center}>
														{countNoOfTests(u.id, 'enurm-2018')}
													</td>
													<td style={center}>
														{countNoOfTests(u.id, 'enurm-2017')}
													</td>
													<td style={center}>
														{countNoOfTests(u.id, 'enurm-2016')}
													</td>
													<td style={center}>
														{countNoOfTests(u.id, 'enurm-2012')}
													</td>
													<td style={center}>
														{countNoOfTests(u.id, 'correctas')}
													</td>
													<td style={center}>
														{countNoOfTests(u.id, 'incorrectas')}
													</td>
													<td style={center}>
														{/* {testRecords.filter((r) => r.user_uid === u.id)
															.length + 1} */}
														{u.totalTestRecords}
													</td>
													{/* with timer */}
													<td style={center}>
														{
															testRecords.filter(
																(r) => r.user_uid === u.id && r.useTimer
															).length
														}
													</td>
													{/* with tutor mode */}
													<td style={center}>
														{
															testRecords.filter(
																(r) => r.user_uid === u.id && r.useTutorMode
															).length
														}
													</td>
													{/* aleatorio */}
													<td style={center}>
														{
															testRecords.filter(
																(r) =>
																	r.user_uid === u.id &&
																	r.testMode === 'aleatorio'
															).length
														}
													</td>
													{/* por categorias */}
													<td style={center}>
														{
															testRecords.filter(
																(r) =>
																	r.user_uid === u.id &&
																	r.testMode === 'categorías'
															).length
														}
													</td>
													{/* pruebines */}
													<td style={center}>
														{
															testRecords.filter(
																(r) =>
																	r.user_uid === u.id &&
																	r.testType === 'pruebín'
															).length
														}
													</td>
													{/* simulaciones */}
													<td style={center}>
														{
															testRecords.filter(
																(r) =>
																	r.user_uid === u.id &&
																	r.testType === 'simulación'
															).length
														}
													</td>
													{/* personalizados */}
													<td style={center}>
														{
															testRecords.filter(
																(r) =>
																	r.user_uid === u.id && r.testType === 'otro'
															).length
														}
													</td>
												</tr>
											);
										})}
								</tbody>
							</table>
						</>
					)}
				</div>
			)}
		</>
	);
};

// STYLES
const thead = {
	fontWeight: '700',
	backgroundColor: 'var(--brand-1)',
	color: 'var(--brand-white)',
};

const center = {
	textAlign: 'center',
};

export default DataSimulaciones;
