import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const AnimatedImg = (props) => {
	const controls = useAnimation();
	const { ref, inView } = useInView();
	const [scrollDir, setScrollDir] = React.useState('scrolling down');

	const imgVariants = {
		hidden: {
			scale: 0,
			// y: '35%',
		},
		visible: {
			scale: 1,
			// y: 0,
			transition: {
				delay: scrollDir === 'scrolling up' ? 0 : 0.15,
				duration: scrollDir === 'scrolling up' ? 0 : 0.5,
			},
		},
	};

	React.useEffect(() => {
		const threshold = 0;
		let lastScrollY = window.pageYOffset;
		let ticking = false;

		const updateScrollDir = () => {
			const scrollY = window.pageYOffset;

			if (Math.abs(scrollY - lastScrollY) < threshold) {
				ticking = false;
				return;
			}
			setScrollDir(scrollY > lastScrollY ? 'scrolling down' : 'scrolling up');
			lastScrollY = scrollY > 0 ? scrollY : 0;
			ticking = false;
		};

		const onScroll = () => {
			if (!ticking) {
				window.requestAnimationFrame(updateScrollDir);
				ticking = true;
			}
		};

		window.addEventListener('scroll', onScroll);
		// console.log(scrollDir);

		return () => window.removeEventListener('scroll', onScroll);
	}, [scrollDir]);

	React.useEffect(() => {
		if (scrollDir === 'scrolling up') {
			return controls.start('visible');
		}
		if (inView) {
			controls.start('visible');
		}
		if (!inView) {
			controls.start('hidden');
		}
	}, [controls, inView, scrollDir]);

	return (
		<motion.img
			className={props.className}
			ref={ref}
			initial='hidden'
			animate={controls}
			variants={imgVariants}
			src={props.src}
		/>
		// 	{props.children}
		// </motion.div>
	);
};

export default AnimatedImg;
