import React from 'react';
import '../../utils/styles/ProgressBar.css';
// TODO: Add animated progress bar
// https://medium.com/swlh/creating-an-animated-progress-bar-in-react-5e85e8f6ec16

/**
 * Progress bar takes props width and percent. width={(number from 1 to 100)} (as a percent) and percent={(number from 1 to 100)} (as a percent)
 */
const ProgressBar = ({ width, percent }) => {
	// let progress = percent * width;
	const [value, setValue] = React.useState(0);

	/*eslint-disable */
	React.useEffect(() => {
		setTimeout(() => {
			setValue(percent);
		}, 500);
	}, []);
	/*eslint-enable */

	return (
		<div className='container mt-2'>
			<div className='progress-div' style={{ width: `${width}%` }}>
				<div style={{ width: `${value}%` }} className='progress-bar' />
			</div>
		</div>
	);
};

export default ProgressBar;
