import React, { useContext, useState, useEffect } from 'react';
import { AdminContext } from '../../../../utils/store/context/adminContext';

import SalesSeasonClosed from './SalesSeasonClosed';

const ChooseEnurm = ({
	onClick,
	salesSeasonOpen,
	updateThisUser,
	nextEnurm,
}) => {
	if (!salesSeasonOpen) return <SalesSeasonClosed />;

	return (
		<div className='container center'>
			<h4 className='txt-brand2'>¿En que año quieres tomar el ENURM?</h4>
			<p>
				Para empezar el proceso de crear tu cuenta debes elegir que año quieres
				tomar el examen.
			</p>
			{/* <p>
				Si te estás buscando prepararte para el 2023, serás redirigido a nuestra
				lista de espera 2023.
			</p> */}
			<div className='row mt-2'>
				<div className='col m6 s12'>
					<div
						onClick={() => {
							onClick(nextEnurm);
							updateThisUser({ enurmTargetYear: nextEnurm });
						}}
						className='d-flex-center c-pointer'>
						<div
							className='card-panel d-flex-center fsize-3 z-depth-2 bg-brand txt-brand-white'
							style={{
								height: window.innerWidth < 767 ? '150px' : '250px',
								width: '70%',
							}}>
							{nextEnurm}
						</div>
					</div>
				</div>
				<div className='col m6 s12'>
					<a
						href='https://mailchi.mp/7c089f556b99/9stb08xnsl'
						className='d-flex-center'>
						<div
							className='card-panel d-flex-center fsize-3 z-depth-2'
							style={{
								height: window.innerWidth < 767 ? '150px' : '250px',
								width: '70%',
							}}>
							{nextEnurm + 1}
						</div>
					</a>
				</div>
			</div>
		</div>
	);
};

export default ChooseEnurm;
