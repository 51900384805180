import React, { useContext, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { UtilContext } from '../../../../utils/store/context/utilContext';

// TODO: show watermark on answers
const AnswerLi = (props) => {
	let check, evalClass, listClass;
	const { usersChoice, option, selected, answer, isTutorMode } = props;
	// const [choice, setChoice] = useState('');
	const handleClick = (e) => {
		// e.target.style.outline = 'blue solid 2px !important';
		usersChoice(option);
	};

	// Should happen only on tutor mode
	if (isTutorMode) {
		// console.info('TUTOR MODE!!');
		if (answer === option) {
			evalClass = 'right-answer';
			check = 'check_box';
		} else {
			evalClass = 'wrong-answer';
			check = 'close';
		}
	} else {
		check = 'indeterminate_check_box';
	}

	// Should happen on any mode
	if (props.userSelection === option) {
		listClass = isTutorMode
			? 'answer selected-answer'
			: 'answer selected-answer bg-main';
	} else {
		listClass = 'answer z-depth-1';
	}

	return (
		<li className={`${listClass} ${selected ? evalClass : ''}`}>
			<button
				id={option}
				onClick={handleClick}
				disabled={isTutorMode && selected ? 'disabled' : ''}>
				{selected ? (
					<i className='material-icons' style={{ marginTop: '-4px' }}>
						{check}
					</i>
				) : (
					<i
						className='material-icons grey-text text-darken-2'
						style={{ marginTop: '-4px' }}>
						check_box_outline_blank
					</i>
				)}
				<span> {option}</span>
			</button>
		</li>
	);
};

const Answers = (props) => {
	const { shuffle } = useContext(UtilContext);
	const [answerOptions, setAnswerOptions] = useState([]);

	useEffect(() => {
		props.currentQuestion &&
			setAnswerOptions(
				shuffle([
					props.currentQuestion.answer,
					props.currentQuestion.option1,
					props.currentQuestion.option2,
					props.currentQuestion.option3,
				])
			);
		/*eslint-disable-next-line */
	}, [props.currentQuestion]);

	return (
		<ul className='answers'>
			{props.currentQuestion &&
				answerOptions.map((option) => {
					return (
						<AnswerLi
							option={option}
							key={uuidv4()}
							usersChoice={props.usersChoice}
							selected={props.selected}
							answer={props.currentQuestion.answer}
							userSelection={props.userSelection}
							isTutorMode={props.isTutorMode}
						/>
					);
				})}
		</ul>
	);
};

export default Answers;
