import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../../utils/store/context/authContext';
import { FirestoreContext } from '../../../utils/store/context/firestoreContext';
// import { v4 as uuidv4 } from 'uuid';
import QuestionDetails from './QuestionDetails';

const SelectSpecialization = (props) => {
	/* ===== FIREBASE CONTEXT =====*/
	const { user } = useContext(AuthContext);
	const { getCategories, categories } = useContext(FirestoreContext);
	const { onChange } = props;
	const [selectedCat, setSelectedCat] = useState('');
	const [category, setCategory] = useState();
	const [subCategory, setSubCategory] = useState('');
	const [showSubCat, setShowSubCat] = useState(false);
	const [showSpecialization, setShowSpecialization] = useState(false);
	/* ===== OTHER VARIABLES =====*/
	let selectedCategory;
	let selectedSubCategory;
	let sub_cat_select;

	/* eslint-disable */
	// * GET CATEGORIES
	useEffect(() => {
		if (!user?.isAdmin) return redirectToHome();
		getCategories();
	}, []);

	// * GET SUBCATEGORIES
	useEffect(() => {
		if (showSubCat) {
			sub_cat_select = document.querySelector('select#subCategory');
			selectedCategory = categories.filter((cat) => {
				return cat.name === category;
			});

			setSelectedCat(...selectedCategory);

			selectedCategory[0].sub_categories
				? selectedCategory[0].sub_categories.forEach((option) => {
						sub_cat_select.insertAdjacentHTML(
							'beforeend',
							`<option value="${option.name}"
			                            key="${option.name}">${option.name}</option>`
						);
				  })
				: setShowSubCat(false);
		}
	}, [showSubCat, category]);

	//* GET SPECIALIZATIONS
	useEffect(() => {
		const specializations_select = document.querySelector(
			'select#specialization'
		);

		if (showSpecialization) {
			selectedSubCategory = selectedCat.sub_categories.filter((subCat) => {
				return subCat.name === subCategory;
			});

			selectedSubCategory.hasOwnProperty('specializations') &&
			selectedSubCategory[0].specializations
				? selectedSubCategory[0].specializations.forEach((i) => {
						specializations_select.insertAdjacentHTML(
							'beforeend',
							`<option value="${i}"
							key="${i}">${i}</option>`
						);
						// TODO: I need to make the specializations into a map instead of an array and then uncommentout the code below:
						// specializations_select.insertAdjacentHTML(
						// 	'beforeend',
						// 	`<option value=${option.name}
						// 		key=${option.name}>${option.name}</option>`
						// );
				  })
				: setShowSpecialization(false);
		}
	}, [showSpecialization, subCategory]);
	/* eslint-enable */

	const handleSelectChange = (e) => {
		onChange(e);
		let target = e.currentTarget.id;
		if (target === 'category') {
			setCategory('');
			setSubCategory('');
			setShowSubCat(false);
			setShowSpecialization(false);
			setCategory(e.target.value);
			setShowSubCat(true);
		} else if (target === 'subCategory' || target === 'sub_category') {
			setSubCategory(e.target.value);
			setShowSpecialization(true);
		} else {
			console.info("THERE'S AN ISSUE.");
		}
	};

	return (
		<div>
			<div className='input-field'>
				{/* ===== SELECT A CATEGORY ===== */}
				<select
					className='browser-default'
					id='category'
					defaultValue={'default'}
					onChange={handleSelectChange}
					tabIndex='1'>
					<option value='default' disabled>
						-- Select a category --
					</option>
					{categories &&
						categories.map((option) => {
							return (
								<option
									value={`${option.name}`}
									key={`${option.id}`}>{`${option.name}`}</option>
							);
						})}
				</select>

				<label htmlFor='category'></label>
			</div>
			{/* ===== SELECT A SUBCATEGORY ===== */}
			{showSubCat && (
				<div className='input-field'>
					<select
						className='browser-default'
						id='subCategory'
						defaultValue={'default'}
						onChange={handleSelectChange}
						required
						tabIndex='2'>
						<option value='default' disabled>
							-- Select a subcategory --
						</option>
						<option value='No Sub Category:'>- No sub-category -</option>
					</select>
					<label htmlFor='subCategory'> </label>
				</div>
			)}
			{/* ===== SELECT A SPECIALIZATION ===== */}
			{showSpecialization && (
				<div className='input-field'>
					<select
						className='browser-default'
						id='specialization'
						defaultValue={'default'}
						onChange={handleSelectChange}
						tabIndex='3'>
						<option value='default' disabled>
							-- Select a specialization --
						</option>
					</select>
					<label htmlFor='specialization'> </label>
				</div>
			)}
		</div>
	);
};

const EditMode = (props) => {
	const [showQuestion, setShowQuestion] = useState(false);
	const [questionID, setQuestionID] = useState(false);
	const [selectedCategory, setSelectedCategory] = useState();
	const [selectedSubCategory, setSelectedSubCategory] = useState(null);
	const [selectedSpetialization, setSelectedSpetialization] = useState(null);
	const [filterByProperty, setfilterByProperty] = useState();
	const [filterSelected, setfilterSelected] = useState();
	const { questions } = useContext(FirestoreContext);

	const handleChange = (e) => {
		console.info(e.target.id, e.target.value);
		let property = e.target.id;
		let value = e.target.value;

		if (property === 'category') {
			setfilterSelected(value);
			setSelectedCategory(value);
			setSelectedSubCategory(null);
			setSelectedSpetialization(null);
		}
		if (property === 'subCategory' || property === 'sub_category') {
			setfilterSelected(value);
			setSelectedSubCategory(value);
		}
		if (property === 'specialization') {
			setfilterSelected(value);
			setSelectedSpetialization(value);
		}
		setfilterByProperty(property);
	};

	return (
		<div className='row'>
			<div className='center'>
				<h3>Edit Mode</h3>
			</div>
			<div className='col s12 m3'>
				<div className='center'>
					<h5>Empieza Aquí</h5>
				</div>
				<div className='w-75 m-auto'>
					<SelectSpecialization onChange={handleChange} />
				</div>

				{filterSelected && (
					<React.Fragment>
						<div className='center pb-2 pt-1'>
							<h6>Revisadas / Total de Preguntas:</h6>
							{/* CATEGORIES */}
							<p>
								{selectedCategory}:{' '}
								{
									questions.filter((q) => {
										return (
											q.category === selectedCategory &&
											q.questionReviewedAndReady === true
										);
									}).length
								}{' '}
								/
								{
									questions.filter((q) => {
										return q.category === selectedCategory;
									}).length
								}{' '}
							</p>
							{/* SUB CATEGORIES */}
							<p>
								{selectedSubCategory}
								{selectedSubCategory &&
									selectedSubCategory !== 'No Sub Category:' && (
										<span>
											:{' '}
											{
												questions.filter((q) => {
													return (
														(q.subCategory === selectedSubCategory ||
															q.sub_category === selectedSubCategory) &&
														q.questionReviewedAndReady === true
													);
												}).length
											}{' '}
											/{' '}
											{
												questions.filter((q) => {
													return (
														q.subCategory === selectedSubCategory ||
														q.sub_category === selectedSubCategory
													);
												}).length
											}
										</span>
									)}
								{selectedSubCategory &&
									selectedSubCategory === 'No Sub Category:' && (
										<span>
											{' '}
											{
												questions.filter((q) => {
													return (
														!q.subCategory &&
														q.category === selectedCategory &&
														q.questionReviewedAndReady === true
													);
												}).length
											}{' '}
											/{' '}
											{
												questions.filter((q) => {
													return (
														!q.subCategory && q.category === selectedCategory
													);
												}).length
											}
										</span>
									)}
							</p>
							{/* SPECIALIZATIONS */}
							<p>{selectedSpetialization}</p>
						</div>

						<div className='center'>
							<h6>Preguntas: </h6>
							<ul>
								{questions
									.filter((q) => {
										if (selectedSubCategory === 'No Sub Category:') {
											return !q.subCategory && q.category === selectedCategory;
										}
										return q[filterByProperty] === filterSelected;
									})
									.map((question) => {
										return (
											<li key={question.id} className='pb-1'>
												<span
													onClick={() => {
														setQuestionID(question.id);
														setShowQuestion(true);
													}}
													className='c-pointer'>
													{question.id}
												</span>{' '}
												<span>
													{question.questionReviewedAndReady ? (
														<i className='material-icons txt-success'>
															check_circle
														</i>
													) : (
														<i className='material-icons txt-danger'>
															horizontal_rule
														</i>
													)}
												</span>{' '}
											</li>
										);
									})}
							</ul>
						</div>
					</React.Fragment>
				)}
			</div>
			<div className='col s12 m9'>
				{showQuestion && <QuestionDetails questionID={questionID} />}
			</div>
		</div>
	);
};

export default EditMode;
