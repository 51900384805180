import {
	GET_CATEGORIES,
	GET_FLASHCARDS,
	GET_AUDIO,
	GET_IMAGE,
	GET_QUESTIONS,
	GET_USER_LIST,
	GET_ESPECIALIDADES,
	// DEBUG_BLUE,
} from '../types';

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state, action) => {
	// console.log(
	// 	'%c ACTION.PAYLOAD (In firebaseReducer):',
	// 	DEBUG_BLUE,
	// 	action.payload
	// );
	// console.log('%c STATE (In firebaseReducer):', DEBUG_BLUE, state);

	switch (action.type) {
		case GET_CATEGORIES:
			return {
				...state,
				categories: action.payload,
			};
		case GET_ESPECIALIDADES:
			return {
				...state,
				especialidades: [...state.especialidades, action.payload],
			};
		case GET_FLASHCARDS:
			return {
				...state,
				flashcards: action.payload,
			};
		case 'clear-flashcards':
			return {
				...state,
				flashcards: action.payload,
			};
		case GET_AUDIO:
			return {
				...state,
				audioURL: action.payload,
			};
		case GET_IMAGE:
			return {
				...state,
				imageURL: action.payload,
			};
		case GET_QUESTIONS:
			return {
				...state,
				questions: [...action.payload],
			};
		case GET_USER_LIST:
			return {
				...state,
				users: action.payload,
			};
		case 'add_question':
			return {
				...state,
				questions: [...state.questions, action.payload],
			};
		case 'updateQuestions':
			let questionID = action.payload[0];
			let keyToChange = action.payload[1];
			let newValue = action.payload[2];
			let thisQuestion = state.questions.filter(
				(question) => question.id === questionID
			);

			let updatedQuestion = {
				...thisQuestion[0],
				[keyToChange]: newValue,
			};

			let newSetOfQuestions = [
				...state.questions.filter((q) => {
					return q.id !== questionID;
				}),
				updatedQuestion,
			];
			// console.info('UPDATED QUESTION:', updatedQuestion, newSetOfQuestions);

			// SET TO LOCAL STORAGE
			// localStorage.removeItem('prepmed-questions');
			// localStorage.setItem(
			// 	'prepmed-questions',
			// 	JSON.stringify([...newSetOfQuestions])
			// );

			return {
				...state,
				questions: [
					...state.questions.filter((q) => {
						return q.id !== questionID;
					}),
					updatedQuestion,
				],
			};
		case 'deleteKeyFromQuestion':
			let questionToDeleteKeyFrom = state.questions.filter(
				(question) => question.id === action.payload.id
			);

			let questionWithoutIssues = { ...questionToDeleteKeyFrom[0] };
			delete questionWithoutIssues[action.payload.key];

			let newArrayOfQuestions = [
				...state.questions.filter((q) => {
					return q.id !== action.payload.id;
				}),
				questionWithoutIssues,
			];

			return {
				...state,
				questions: [
					...state.questions.filter((q) => {
						return q.id !== action.payload.id;
					}),
					questionWithoutIssues,
				],
			};
		case 'updateFlashcard':
			return { ...state, flashcards: [state.flashcards, action.payload] };

		default:
			return state;
	}
};
