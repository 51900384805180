import React, { createContext, useState } from 'react';
import firebase from '../../store/config/fbConfig';
/*eslint-disable*/
import {
	DATABASE,
	DATABASE_BACKUP,
	GET_CATEGORIES,
	GET_IMAGE,
	GET_QUESTIONS,
	GET_USER_LIST,
	DEBUG_BLUE,
	DEBUG_RED,
	DEBUG_YELLOW,
} from '../context/types';

/*eslint-enable*/
export const AdminContext = createContext();

const AdminContextProvider = (props) => {
	// TODO: Make this dynamic from DB
	const [errors, setErrors] = useState({});
	const [enurmData, setEnurmData] = useState({});
	const [testRecords, setTestRecords] = useState([]);

	//~ENURM DATA
	const [nextEnurm, setNextEnurm] = useState();

	const getCurrentEnurm = () => {
		let enurm;
		if (nextEnurm > 0) return;

		firebase
			.firestore()
			.collection('business')
			.doc('nextEnurm')
			.get()
			.then((doc) => {
				if (doc.exists) {
					// console.info(doc.data());
					enurm = doc.data();
					setNextEnurm(enurm.year);
					return;
				} else {
					setNextEnurm(0);
				}
			})
			.catch((e) => {
				console.error(e);
			});

		return enurm;
	};

	const getErrors = () => {
		let allDocs = [];
		firebase
			.firestore()
			.collection('errors')
			.get()
			.then((snapshot) => {
				snapshot.forEach((doc) => allDocs.push(doc.data()));
				setErrors(allDocs);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const findUserByEmail = async (emailEntered) => {
		let result = {};
		await firebase
			.firestore()
			.collection('users')
			.where('email', '==', emailEntered)
			.get()
			.then((snapshot) => {
				if (snapshot.empty) {
					console.log('No matching documents.');
					return;
				}

				snapshot.forEach((doc) => {
					console.log(doc.id, '=>', doc.data());
					result = { uid: doc.id, ...doc.data() };
					// firebase
					// 	.firestore()
					// 	.collection('users')
					// 	.doc(doc.id)
					// 	.update({ isAdmin: true });
				});
			});

		return result;
	};

	const markUserAsPaid = async (uid, source, date) => {
		let result = await firebase
			.firestore()
			.collection('users')
			.doc(uid)
			.update({
				freeTrialActive: false,
				freeTrialExpired: false,
				[`isClient_${nextEnurm}`]: true,
				[`paymentCompleted_${nextEnurm}`]: true,
				[`membership_${nextEnurm}`]: {
					isPaid: true,
					type: source === 'Giveaway' ? 'Giveaway' : 'Customer',
					paymentSource: source,
					paymentDate: new Date(date),
				},
			})
			.then(() => {
				alert('Usuario habilitado.');
				return true;
			});
		return result;
	};

	//~CONTACT MESSAGES
	//#region
	const [contactMessages, setContactMessages] = React.useState();
	/**
	 * Creates new messate document from Contact Us page
	 */
	const addContactFormMessage = (msg) => {
		firebase
			.firestore()
			.collection('contact_messages')
			.add({
				...msg,
				isAnswered: false,
				sentOn: new Date(),
			})
			.then(() => {
				console.log('%cContact messag added:', DEBUG_BLUE, msg);
				// alert('Question set added succesfully.');
			})
			.catch((err) => {
				console.log('%cAdding contact message failed', DEBUG_RED, err);
			});
	};

	const getContactMessage = () => {
		firebase
			.firestore()
			.collection('contact_messages')
			.onSnapshot((snapshot) => {
				const dbData = snapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));
				setContactMessages(dbData);
			});
	};

	const contactMessageAnswered = (id) => {
		firebase
			.firestore()
			.collection('contact_messages')
			.doc(id)
			.update({
				isAnswered: true,
			})
			.then(() => {
				console.log('Contact message answered!');
			})
			.catch((error) => {
				// The document probably doesn't exist.
				console.error('Error updating document: ', error);
			});
	};

	//#endregion

	//~ SALES INFORMATION
	//#region

	const updateTotalSales = () => {
		firebase
			.firestore()
			.collection('business')
			.doc(`enurm_${nextEnurm}`)
			.update({
				totalSales: firebase.firestore.FieldValue.increment(1),
			});
	};

	const updateTotalSignups = () => {
		firebase
			.firestore()
			.collection('business')
			.doc(`enurm_${nextEnurm}`) //~This needs to be dynamic (get current enurm year)
			.update({
				totalSignups: firebase.firestore.FieldValue.increment(1),
			});
	};
	//#endregion

	const getUserTestRecords = async (enurm = nextEnurm) => {
		let testRecordsRef = await firebase
			.firestore()
			.collection(`testRecords_${enurm}`)
			.get();

		let data = [];
		for (const doc of testRecordsRef.docs) {
			let thisDoc = {
				id: doc.id,
				...doc.data(),
			};
			data.push(thisDoc);
		}

		setTestRecords(data);
		return data;
	};

	const getTestRecordsWhere = async (
		field,
		operator,
		value,
		enurm = nextEnurm
	) => {
		// console.info(field, operator, value, typeof value);

		let data = [];
		let docRef = await firebase
			.firestore()
			.collection(`testRecords_${nextEnurm}`)
			.where(field, operator, value)
			.get();

		for (const doc of docRef.docs) {
			let thisDoc = {
				id: doc.id,
				...doc.data(),
			};
			data.push(thisDoc);
		}

		// console.info('data:', data);
		return data;
	};

	const [showDiscount, setShowDiscount] = useState(false);
	const [marketing, setMarketing] = useState({});
	const getMarketingData = () => {
		let data;
		firebase
			.firestore()
			.collection('business')
			.doc('marketing')
			.get()
			.then((doc) => {
				if (doc.exists) {
					data = doc.data();
					setMarketing(data);
					setShowDiscount(data.showDiscount);
					return;
				}
			})
			.catch((e) => {
				console.error(e);
			});

		return data;
	};
	//~REPORTS
	//#region
	const [users, setUsers] = React.useState([]);
	const getUserList = () => {
		firebase
			.firestore()
			.collection('users')
			.onSnapshot((snapshot) => {
				let userList = snapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));
				setUsers(userList);
				// dispatch({
				// 	type: GET_USER_LIST,
				// 	payload: userList,
				// });
			});
	};

	/**
	 * Takes in: field, operator, value (as strings)
	 */
	const getUsersWhere = async (field, operator, value) => {
		// console.info(field, operator, value, typeof value);

		let data = [];
		let docRef = await firebase
			.firestore()
			.collection('users')
			.where(field, operator, value)
			// .where('cedula', '!=', '')
			.get();

		for (const doc of docRef.docs) {
			let thisDoc = {
				id: doc.id,
				...doc.data(),
			};
			data.push(thisDoc);
		}

		// console.info('data:', data);
		return data;
	};

	// const updateUser = (uid) => {
	// 	firebase.firestore().collection('users').doc(uid).update({
	// 		enurmTargetYear: 2021,
	// 		// paymentCompleted_2021: false,
	// 		// isClient_2021: client,
	// 		// membership_2021: membership,
	// 		enurmYearTarget: firebase.firestore.FieldValue.delete(),
	// 		// paymentCompleted: firebase.firestore.FieldValue.delete(),
	// 		// membership: firebase.firestore.FieldValue.delete(),
	// 	});
	// };

	const getCurrentENURMData = (year = nextEnurm) => {
		firebase
			.firestore()
			.collection('business')
			.doc(`enurm_${year}`)
			.get()
			.then((doc) => {
				if (doc.exists) {
					let data = doc.data();
					setEnurmData(data);
				} else {
					// TODO: IF NO DATA & ENURMYEAR > 0, "CREATE NEW ENURM YEAR"
					// POR EJEMPLO, CUANDO YO CAMBIE "NEXTENURM" a 2023, it won't exist so a document must get created for it
				}
			})
			.catch((error) => {
				console.log('Error getting document:', error);
			});
	};

	const getReviews = async (field, operator, value) => {
		let data = [];
		let docRef = await firebase
			.firestore()
			.collection('reviews')
			.where(field, operator, value)
			.get();

		for (const doc of docRef.docs) {
			let thisDoc = {
				id: doc.id,
				...doc.data(),
			};
			data.push(thisDoc);
		}
		return data;
	};
	//#endregion

	return (
		<AdminContext.Provider
			value={{
				addContactFormMessage,
				contactMessages,
				contactMessageAnswered,
				enurmData,
				errors,
				findUserByEmail,
				getContactMessage,
				getCurrentEnurm,
				getCurrentENURMData,
				getMarketingData,
				getErrors,
				getReviews,
				getUserList,
				getUserTestRecords,
				getTestRecordsWhere,
				getUsersWhere,
				markUserAsPaid,
				marketing,
				nextEnurm,
				showDiscount,
				updateTotalSales,
				updateTotalSignups,
				users,
				testRecords,
				// updateUser,
			}}>
			{props.children}
		</AdminContext.Provider>
	);
};

export default AdminContextProvider;
