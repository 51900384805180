import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import parse from 'html-react-parser';
import { isMobileScreen } from '../../utils/helpers';
import { PUBLIC_LINKS } from '../../utils/ROUTES';

// COMPONENTS
// import LogoDisplay from './LogoDisplay';
const preguntasFrecuentes = [
	{
		id: 1,
		order: 1,
		pregunta: '¿Cómo puedo crear una cuenta?',
		respuesta: `<div><p>Para completar tu pago con PayPal, te recomendamos ver este video.</p><div><iframe ${
			isMobileScreen ? '' : 'width="560" height="315"'
		} src="https://www.youtube.com/embed/dYza8ygYcow" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div></div>`,
		isVisible: true,
		code: 'crea-tu-cuenta',
	},
	{
		id: 2,
		order: 2,
		pregunta: '¿Cómo se paga con PayPal?',
		respuesta: `<div><p>Para completar tu pago con PayPal, te recomendamos ver este video.</p><div><iframe ${
			isMobileScreen ? '' : 'width="560" height="315"'
		} src="https://www.youtube.com/embed/TPQ7XEoJw_Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div></div>`,
		isVisible: true,
		code: 'pago-con-paypal',
	},
	{
		id: 3,
		order: 3,
		pregunta: '¿Cómo se paga con Stripe?',
		respuesta: `<div><p>Para completar tu pago con Stripe, te recomendamos ver este video.</p><div><iframe ${
			isMobileScreen ? '' : 'width="560" height="315"'
		} src="https://www.youtube.com/embed/06b2RUX-Y24" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div></div>`,
		isVisible: true,
		code: 'pago-con-stripe',
	},
	{
		id: 4,
		order: 4,
		pregunta:
			'No me permite pagar con mi tarjeta del Banco Popular ¿qué puedo hacer?',
		respuesta: `<p>PayPal es la única opción de pago que ofrecemos que acepta tarjetas del Banco Popular.</p><p>Te recomendamos ver este video para más detalles.</p><div><iframe ${
			isMobileScreen ? '' : 'width="560" height="315"'
		} src="https://www.youtube.com/embed/TPQ7XEoJw_Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>`,
		isVisible: true,
		code: 'banco-popular',
	},
	{
		id: 5,
		order: 5,
		pregunta: '¿Se puede hacer el pago por transferencia?',
		respuesta:
			'<p>Si aceptamos pago por transferencia bancaria pero <b>no es la mejor opci&oacute;n</b> y no lo recomendamos por estas las razones:</p> <ul class="normal-bullets"> <li>El pago con transferencia bancaria requiere de 24 a 48 horas para la activaci&oacute;n de tu cuenta luego de confirmada la transferencia</li> <li>El pago con transferencia bancaria no tiene devoluci&oacute;n</li> <li>El pago con transferencia bancaria no se aplican descuentos&nbsp;</li> </ul> <p>Si a&uacute;n as&iacute; deseas completar el pago de tu cuenta por transferencia bancaria, por favor escr&iacute;benos a nuestra <a href="https://www.instagram.com/prepmedrd/">cuenta de instagram</a> para m&aacute;s informaci&oacute;n.</p>',
		isVisible: true,
		code: 'banco-popular',
	},
	{
		id: 6,
		order: 6,
		pregunta: '¿Cuándo es el mejor momento para utilizar PrepMedRD?',
		respuesta:
			'<p>El mejor momento para utilizar PrepMedRD es durante el año de pasantía o 6 meses antes del ENURM.</p>',
		isVisible: true,
		code: 'cuando-utilizar-prepmed',
	},
	{
		id: 7,
		order: 7,
		pregunta:
			'¿Cuánto tiempo debo dedicarle a la plataforma para obtener un buen resultado?',
		respuesta:
			'<p>Recomendamos iniciar con 1 a dos horas de estudio diarias durante el periodo de Julio &ndash; Septiembre. Entre octubre a diciembre recomendamos incrementar el tiempo de estudio de 3 a 4 horas. Luego de diciembre, a medida que se acerca el examen, recomendamos un m&iacute;nimo de 4 horas.</p><p>Cabe destacar que un esfuerzo m&iacute;nimo solo les garantiza un resultado m&iacute;nimo, mientras mayor sea su esfuerzo durante el periodo de estudio mejor ser&aacute; su resultado.</p><p>Si desea saber c&oacute;mo se prepararon otros concursantes exitosos, le recomendamos visitar nuestro canal de YouTube <a href="https://www.youtube.com/channel/UCB0TlBdYjsUXiRluQ1MvDHg">https://www.youtube.com/channel/UCB0TlBdYjsUXiRluQ1MvDHg</a>&nbsp;y ver sus historias personales.</p>',
		isVisible: true,
		code: 'cuanto-tiempo-utilizar-prepmed',
	},
	{
		id: 8,
		order: 8,
		pregunta: '¿Cómo puedo realizar el pago?',
		respuesta:
			'<p>El pago se podr&aacute; realizar de manera electr&oacute;nica de dos formas:</p><ol><li>Tarjeta de cr&eacute;dito: provee acceso inmediato a la plataforma una vez el pago sea aprobado. La seguridad de sus datos personales es garantizada por PayPal, Stripe, Visa y Mastercard. Su informaci&oacute;n de pago no es guardada por nosotros. Una vez sea realizado el pago, el acceso a la plataforma es inmediato durante el mismo d&iacute;a laboral</li>	<li>Transferencia bancaria: provee acceso 24 horas despu&eacute;s de la transferencia (tiempo en que toma para habilitar la cuenta manualmente). Escribanos por Instagram o a <a href="mailto:transferencias@prepmed.org">transferencias@prepmed.org</a>&nbsp;para m&aacute;s detalles.</li></ol>',
		isVisible: true,
		code: 'formas-de-pago',
	},
	{
		id: 9,
		order: 9,
		pregunta: '¿Cuánto tiempo dura el acceso?',
		respuesta:
			'<p>El acceso a la plataforma PrepMedRD dura hasta el día del examen del ENURM. Luego del día del ENURM nuestra plataforma cierra para realizar las actualizaciones necesarias. Para continuar su acceso deberá pagar la inscripción en la plataforma entrante.</p>',
		isVisible: true,
		code: 'tiempo-de-acceso',
	},
	{
		id: 10,
		order: 10,
		pregunta: '¿Cuál es el precio de PrepMedRD?',
		respuesta:
			'<p>El pago de&nbsp;PrepMedRD&nbsp;cubre acceso durante la temporada a la cual se subscribe. La temporada est&aacute; determinada por el ENURM (Ej.: ENURM 2024 temporada 2024)</p><p>El pago es un pago &uacute;nico&nbsp;y el precio es determinado por qué tan temprano se suscribe a la plataforma, mientras m&aacute;s temprano pague el servicio, en la temporada m&aacute;s barato le saldr&aacute; el servicio.</p><p>El precio para la&nbsp;temporada ENURM 2024 es el siguiente:</p><ul>	<li>Del 2 al 5 de Junio 4099 pesos</li>	<li>Desde el 6 Junio 5099 pesos</li><li>Primero de Septiembre en adelante: 6099 pesos</li><li>Primero de Diciembre en adelante: 7099 pesos</li></ul>',
		isVisible: true,
		code: 'precio-prepmed',
	},
	{
		id: 11,
		order: 11,
		pregunta: '¿Puedo inscribirme antes de tiempo aunque no sea mi año ENURM?',
		respuesta:
			'<p>Sí, sin embargo, deberá re-inscribir su acceso a la plataforma al año en que desea tomar el ENURM.</p>',
		isVisible: true,
		code: 'puedo-inscribirme-antes',
	},
	{
		id: 12,
		order: 12,
		pregunta: '¿Puedo inscribirme durante la pasantía?',
		respuesta:
			'<p>Si, muchos de nuestros exalumnos utilizan el año de pasantía para su preparación ENURM.</p>',
		isVisible: true,
		code: 'inscribirme-durante-pasantia',
	},
	{
		id: 13,
		order: 13,
		pregunta:
			'No estoy satisfecho con el producto y deseo un reembolso. ¿Cuál es el proceso de reembolso?',
		respuesta:
			'<p>En caso de pagar con Tarjeta de Crédito: tiene derecho a pedir reembolso luego de las primeras 72 horas de creación de su cuenta. A partir de las 72 horas (3 días) no podemos garantizarle el reembolso.</p><p>Lamentablemente no tenemos la capacidad de ofrecer reembolsos para pagos por transferencia bancaria.</p>',
		isVisible: true,
		code: 'reembolso',
	},
	{
		id: 14,
		order: 14,
		pregunta: '¿PrepMed es un curso?',
		respuesta:
			'<p>No, PrepMedRD  es una plataforma educativa completamente en línea. Con PrepMedRD tienes accesos a simulaciones de ENURMs pasados y contenido propietario de nuestra plataforma. Cada una de las preguntas tiene una explicación extensa y didáctica que le ayudará a ampliar sus conocimientos y mejorar su rendimiento el día del examen.</p>',
		isVisible: true,
		code: 'prepmed-no-es-un-curso',
	},
];

const PreguntaRespuesta = ({ code, id, pregunta, respuesta, onClick }) => {
	const [isLinkCopied, setIsLinkCopied] = useState(false);
	const copyLinkToClipboard = (e) => {
		setIsLinkCopied(true);
		let domain = window.location.origin;
		navigator.clipboard.writeText(
			`${domain}/preguntas-frecuentes/?${e.target.id}`
		);
		setTimeout(() => {
			setIsLinkCopied(false);
		}, 3000);
	};

	return (
		<details
			className={`left-align mx-auto ${isMobileScreen ? 'w-100' : 'w-65'}`}
			id={id}
			onClick={() => onClick(id)}>
			<summary>
				<h5 style={{ display: 'inline' }}>{pregunta}</h5>
			</summary>
			{parse(respuesta)}
			<button
				className='d-flex-center'
				id={code}
				style={{ justifyContent: 'end' }}
				onClick={copyLinkToClipboard}>
				<span class='material-symbols-outlined'>content_copy</span> Copiar link
				a pregunta
				<strong className='txt-success ml-2' hidden={!isLinkCopied}>
					Link copiado.
				</strong>
			</button>
		</details>
	);
};

const PreguntasFrecuentes = () => {
	const [isSearching, setIsSearching] = useState(false);
	const [tempData, setTempData] = useState([...preguntasFrecuentes]);
	const [hasQuestionCode, setHasQuestionCode] = useState(null);
	const location = useLocation();
	const history = useHistory();

	React.useEffect(() => {
		console.info(location);
		if (location.search !== '') {
			let q = location.search.split('?')[1];
			setHasQuestionCode(q);
		}
		//   return () => {
		// 	second
		//   }
	}, []);

	React.useEffect(() => {
		if (hasQuestionCode) {
			handleSearch(hasQuestionCode);
		}
	}, [hasQuestionCode]);

	const clearSearch = (e) => {
		e.target.value = '';
		setIsSearching(false);
	};

	const handleDetailsClick = (id) => {
		if (!hasQuestionCode) {
			let det = window.document.querySelectorAll('details');
			let isDetailOpen = Array.from(det).filter((detail) => detail.id == id)[0]
				.open;

			if (isDetailOpen) setIsSearching(false);
		}
	};

	const handleSearch = (e) => {
		setIsSearching(true);
		let search = e?.target?.value.toLowerCase();
		let t = preguntasFrecuentes.filter(
			(i) =>
				i.pregunta.toLowerCase().includes(search) ||
				i.respuesta.toLowerCase().includes(search) ||
				i.code === hasQuestionCode
		);
		setTempData(t);

		if (search === '') setIsSearching(false);
	};

	return (
		<div style={{ minHeight: '100vh' }}>
			<div className='container'>
				{/* <div className='w-75 mx-auto hide-on-small-and-down'>
					<LogoDisplay />
				</div> */}
				<div className='mt-4 center'>
					<h3 className='mt-2'>
						{/* <i className='material-icons fsize-larger mr-2'>check_circle</i> */}
						Preguntas Frecuentes
					</h3>
					{/* SEARCH */}
					<div
						className={`mx-auto mt-2 mb-3 ${
							isMobileScreen ? 'w-100' : 'w-50'
						}`}>
						<div className='input-field'>
							<input
								type='text'
								name='search'
								id='search'
								// placeholder='¿Qué estás buscando?'
								onChange={handleSearch}
								onFocus={clearSearch}
								// onBlur={() => setIsSearching(false)}
								autoComplete='off'
							/>
							<label htmlFor='search'>
								<i className='material-icons'>search</i> ¿Qué estás buscando?
							</label>
						</div>
						{hasQuestionCode && (
							<div className='d-flex-center' style={{ justifyContent: 'end' }}>
								<button
									onClick={() => {
										setHasQuestionCode(null);
										setIsSearching(false);
										history.push(PUBLIC_LINKS.PREGUNTAS_FRECUENTES);
									}}>
									<span class='material-symbols-outlined mr-2'>arrow_back</span>
									Ver todas las preguntas
								</button>
							</div>
						)}
					</div>
				</div>

				{/* PREGUNTAS Y RESPUESTAS */}
				{isSearching
					? tempData.map((i) => (
							<PreguntaRespuesta
								pregunta={i.pregunta}
								respuesta={i.respuesta}
								id={i.id}
								code={i.code}
								key={i.id}
								onClick={(value) => handleDetailsClick(value)}
							/>
					  ))
					: preguntasFrecuentes.map((i) => (
							<PreguntaRespuesta
								pregunta={i.pregunta}
								respuesta={i.respuesta}
								code={i.code}
								id={i.id}
								key={i.id}
								onClick={(value) => handleDetailsClick(value)}
							/>
					  ))}
			</div>
		</div>
	);
};

export default PreguntasFrecuentes;
