import React, { useContext, useEffect, useState } from 'react';
import Stats from './Stats';
import { FirestoreContext } from '../../../utils/store/context/firestoreContext';
import QuestionSummaryList from './QuestionsSummaryList';
import { deleteAllCookies, redirectToLogin } from '../../../utils/helpers';

const QuestionsDashboard = (props) => {
	const {
		getQuestionsfromDB,
		getQuestions,
		questions,
		getCategories,
		categories,
	} = useContext(FirestoreContext);

	const [showCounter, setShowCounter] = useState(true);

	/* eslint-disable */
	useEffect(() => {
		getQuestions();
		// questions?.length <= 0 && getQuestions();
		getCategories();
	}, []);

	/* eslint-enable */
	if (questions?.length <= 0) return <div>Loading questions</div>;

	return (
		<div className='dashboard white'>
			{/* {user.isAdmin ? <MakeAdmin /> : null} */}
			<div className='row'>
				{/* ===== STATS =====  */}
				{showCounter && (
					<div className='col l4 s12 m6'>
						<div className='stats '>
							<Stats questions={questions} />
						</div>
					</div>
				)}

				{/* ===== QUESTION LIST ===== */}
				<div
					className={`col inset-shadow s12 bg-lighter-shade ${
						showCounter ? 'm6 l8' : 'm12 l12'
					}`}
					style={{
						minHeight: '100vmax',
					}}>
					<div className='d-flex w-full' style={{ justifyContent: 'end' }}>
						<button
							className='btn bg-brand mr-2'
							onClick={() => {
								deleteAllCookies();
								redirectToLogin();
							}}>
							<i className='material-icons left'>cached</i>
							Recargar App
						</button>
					</div>
					<button
						className='btn btn-flat'
						onClick={() => setShowCounter(!showCounter)}>
						{setShowCounter ? 'Hide' : 'Show'} Counter
					</button>

					<div className='row'>
						<div className={`col s12  ${showCounter ? 'pw-2' : 'pw-4'}`}>
							{questions && (
								<QuestionSummaryList
									questions={questions}
									questionsFromDB={getQuestionsfromDB}
									categories={categories}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default QuestionsDashboard;
