import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import '../utils/styles/App.css';
import PrivateRoute from '../utils/RouteGuards/PrivateRoute';
import PublicRoute from '../utils/RouteGuards/PublicRoute';
import Mc from 'materialize-css';
// import 'material-icons/iconfont/material-icons.css';
import M from 'materialize-css/dist/js/materialize';
/* ==================== CONTEXT ==================== */
import AdminContextProvider from '../utils/store/context/adminContext';
import AuthContextProvider from '../utils/store/context/authContext';
import FirestoreContextProvider from '../utils/store/context/firestoreContext';
import UtilContextProvider from '../utils/store/context/utilContext';

/* ====================     COMPONENTS ==================== */
import Home from './public/Homepage/Home';
import Navbar from './public/Navbar/Navbar';
import Dashboard from './private/Dashboard';
import LogIn from './public/auth/LogIn';
import SignUp from './public/auth/SignUp';
import ForgotPassword from './public/auth/ForgotPassword';
import QuestionDetails from './private/admin/QuestionDetails';
import FlashcardsDashboard from './private/flashcards/Dashboard';
import AddQuestions from './private/admin/AddQuestions';
import ChooseQuiz from './private/quiz/ChooseQuiz';
import TestMode from './private/quiz/questionaire/TestMode';
import PreviousTestReview from './private/user/PreviousTestReview';
import NotFound from './public/NotFound';
import Reports from './private/admin/reports/Dashboard';
import AboutUs from './public/AboutUs';
import ContactUs from './public/ContactUs';
import Footer from './public/Footer';
import Privacy from './public/Privacy';
import TOS from './public/TermsOfService';
import CookiePolicy from './public/CookiePolicy';
import UserProfile from './private/user/UserProfile';
import EditMode from './private/admin/EditMode';
import CheckoutFail from './private/sales/CheckoutFail';
import CheckoutSuccess from './private/sales/CheckoutSuccess';
import MainCheckout from './private/sales/MainCheckout';
// import StripePayments from './public/stripe/StripePayments';
import PaypalCheckout from './private/sales/PaypalCheckout';
/* ====================     COMPONENTS ==================== */
// PUBLIC
import {
	ABOUT_US,
	CONTACT_US,
	HOME_PAGE,
	LOGIN_PAGE,
	NEW_PASSWORD,
	POLICY_COOKIES,
	PRIVACY_POLICY,
	SIGNUP_PAGE,
	TOS_PAGE,
	STRIPE_CHECKOUT,
	PAYPAL_CHECKOUT,
	SOCIAL_LINKS,
	COLABORADORES,
	FLASHCARDS_MODE,
	SIGNUP_PAGE_NEW,
	EMAIL_VERIFICATION,
	EMAIL_VERIFIED,
	STATS,
	ADMIN_LINKS,
	PUBLIC_LINKS,
	USER_LINKS,
} from '../utils/ROUTES';
// PRIVATE
import {
	ADD_FLASHCARD,
	ADD_QUESTIONS,
	FLASHCARD_DETAILS,
	FLASHCARDS_LIST,
	QUESTION_LIST,
	CHOOSE_QUIZ,
	DASHBOARD,
	EDIT_MODE,
	GRADE_CONSULTATION,
	PREVIOUS_TEST_REVIEW,
	QUESTION_DETAILS,
	QUESTION_DETAILS_DARK,
	QUIZ,
	TAKE_FLASHCARDS,
	TEST_RESULTS,
	REPORTS,
	USER_PROFILE,
	STRIPE_CHECKOUT_SUCCESS,
	STRIPE_CHECKOUT_FAIL,
} from '../utils/ROUTES';
import QuestionsDashboard from './private/admin/QuestionsDashboard';
import QuestionDetailsDark from './private/admin/QuestionDetailsDark';
import Covid19 from './public/redirects/Covid19';
import AddFlashcard from './private/flashcards/AddFlashcard';
import Consulta21 from './public/redirects/Consulta21';
import ListaDeEspera from './public/redirects/ListaDeEspera';
import EditFlashcard from './private/flashcards/EditFlashcard';
import SocialLinks from './public/SocialLinks';
import Colaboradores from './public/Colaboradores';
import TakeFlashcards from './private/flashcards/TakeFlashcards';
import FlashcardsMode from './private/flashcards/FlashcardsMode';
import SignUpNew from './public/auth/SignUpNew';
import VerifyYourEmail from './public/auth/sign-up/VerifyYourEmail';
import EmailVerified from './public/auth/sign-up/EmailVerified';
import Stats from './private/user/Stats';
import PostTestDashboard from './private/user/PostTestDashboard';
import PreguntasFrecuentes from './public/PreguntasFrecuentes';
import HabilitarCuenta from './private/admin/HabilitarCuenta';
import PrintFriendly from './PrintFriendly';
import SearchQuestions from './private/user/SearchQuestions';

function App() {
	useEffect(() => {
		Mc.AutoInit();
		M.AutoInit();
	}, []);
	return (
		<div className='prepmed'>
			<BrowserRouter>
				<AdminContextProvider>
					<UtilContextProvider>
						<AuthContextProvider>
							<FirestoreContextProvider>
								<Navbar />
								<div
									id='page-height'
									style={{
										minHeight: '90vh',
										height: '100%',
									}}>
									<Switch>
										{/* <PublicRoute
											component={PrintFriendly}
											path={'/printealo'}
										/> */}
										{/* ===== PRIVATE ROUTES ===== */}
										<PrivateRoute component={Dashboard} path={DASHBOARD} />
										<PrivateRoute component={Stats} path={STATS} />
										<PrivateRoute
											component={PreviousTestReview}
											path={PREVIOUS_TEST_REVIEW}
										/>
										<Route component={EditFlashcard} path={FLASHCARD_DETAILS} />
										<Route
											component={QuestionDetails}
											path={QUESTION_DETAILS}
										/>
										<PrivateRoute
											component={QuestionDetailsDark}
											path={QUESTION_DETAILS_DARK}
										/>
										<PrivateRoute
											component={PostTestDashboard}
											path={GRADE_CONSULTATION}
										/>
										<PrivateRoute component={TestMode} path={QUIZ} />
										<PrivateRoute
											component={QuestionsDashboard}
											path={QUESTION_LIST}
										/>
										<PrivateRoute
											component={TakeFlashcards}
											path={TAKE_FLASHCARDS}
										/>
										<PrivateRoute
											component={FlashcardsMode}
											path={FLASHCARDS_MODE}
										/>
										<PrivateRoute
											component={FlashcardsDashboard}
											path={FLASHCARDS_LIST}
										/>
										<PrivateRoute
											component={AddQuestions}
											path={ADD_QUESTIONS}
										/>
										<PrivateRoute
											component={AddFlashcard}
											path={ADD_FLASHCARD}
										/>
										<PrivateRoute component={ChooseQuiz} path={CHOOSE_QUIZ} />
										<PrivateRoute
											component={HabilitarCuenta}
											path={ADMIN_LINKS.HABILITAR_CUENTA}
										/>
										<PrivateRoute
											component={PreviousTestReview}
											path={TEST_RESULTS}
										/>
										<PrivateRoute component={EditMode} path={EDIT_MODE} />
										<PrivateRoute component={Reports} path={REPORTS} />
										<PrivateRoute component={UserProfile} path={USER_PROFILE} />
										<PrivateRoute
											component={SearchQuestions}
											path={USER_LINKS.PREGUNTAS_VISTAS}
										/>
										<PrivateRoute
											component={MainCheckout}
											path={STRIPE_CHECKOUT}
										/>
										<Route path={STRIPE_CHECKOUT_SUCCESS}>
											<CheckoutSuccess />
										</Route>
										<Route path={STRIPE_CHECKOUT_FAIL}>
											<CheckoutFail />
										</Route>
										{/* ===== PUBLIC ROUTES ===== */}

										<PublicRoute
											component={Home}
											restricted={false}
											exact
											path={HOME_PAGE}
										/>
										<PublicRoute
											component={AboutUs}
											restricted={false}
											path={ABOUT_US}
										/>
										<PublicRoute
											component={ContactUs}
											restricted={false}
											path={CONTACT_US}
										/>
										<PublicRoute
											component={PreguntasFrecuentes}
											restricted={false}
											path={PUBLIC_LINKS.PREGUNTAS_FRECUENTES}
										/>
										<PublicRoute
											component={PreguntasFrecuentes}
											restricted={false}
											path={PUBLIC_LINKS.PREGUNTAS_FRECUENTES_WITH_ID}
										/>
										<PublicRoute
											component={TOS}
											restricted={false}
											path={TOS_PAGE}
										/>
										<PublicRoute
											component={Privacy}
											restricted={false}
											path={PRIVACY_POLICY}
										/>
										<PublicRoute
											component={CookiePolicy}
											restricted={false}
											path={POLICY_COOKIES}
										/>
										<PublicRoute
											component={LogIn}
											restricted={true}
											path={LOGIN_PAGE}
										/>
										<PublicRoute
											component={LogIn}
											restricted={true}
											path='/admin-login'
										/>
										<PublicRoute
											component={SignUp}
											restricted={true}
											path={SIGNUP_PAGE}
										/>
										<Route component={SignUpNew} path={SIGNUP_PAGE_NEW} />
										<Route
											component={VerifyYourEmail}
											path={EMAIL_VERIFICATION}
										/>
										<PublicRoute
											component={EmailVerified}
											restricted={true}
											path={EMAIL_VERIFIED}
										/>
										<PublicRoute
											component={ForgotPassword}
											restricted={true}
											path={NEW_PASSWORD}
										/>
										<PublicRoute
											restricted={false}
											component={PaypalCheckout}
											path={PAYPAL_CHECKOUT}
										/>
										<PublicRoute
											restricted={false}
											component={Covid19}
											path={'/covid'}
										/>
										<PublicRoute
											restricted={false}
											component={Consulta21}
											path={'/consulta-enurm21'}
										/>
										<PublicRoute
											restricted={false}
											component={ListaDeEspera}
											path={'/lista-de-espera'}
										/>
										{/* <PublicRoute
											restricted={false}
											component={ListaDeEspera}
											path={'/lista-de-espera-enurm-2023'}
										/> */}
										{/* <PublicRoute
											restricted={false}
											component={ListaDeEspera}
											path={'/lista-de-espera-enurm-2022'}
										/> */}
										<PublicRoute
											restricted={false}
											component={SocialLinks}
											path={SOCIAL_LINKS}
										/>
										<PublicRoute
											restricted={false}
											component={Colaboradores}
											path={COLABORADORES}
										/>
										{/* <PublicRoute
										restricted={true}
										component={StripePayments}
										path={STRIPE_PAYMENTS}
									/> */}
										<PublicRoute restricted={false} component={NotFound} />
									</Switch>
								</div>
								<Footer />
							</FirestoreContextProvider>
						</AuthContextProvider>
					</UtilContextProvider>
				</AdminContextProvider>
			</BrowserRouter>
		</div>
	);
}

export default App;
