import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import AdminDashboard from './admin/Dashboard';
import UserDashboard from './user/Dashboard';
import StaffDashboard from './flashcards/Dashboard';
import { redirectToVerifyEmail } from '../../utils/helpers';
import { AuthContext } from '../../utils/store/context/authContext';
import { AdminContext } from '../../utils/store/context/adminContext';
import { FirestoreContext } from '../../utils/store/context/firestoreContext';
import { STRIPE_CHECKOUT } from '../../utils/ROUTES';
import SalesSeasonClosed from '../public/auth/sign-up/SalesSeasonClosed';

// import { firebaseCloudFunctions } from '../../utils/store/config/fbConfig';
// import StripeCheckout from './stripe/StripeCheckout';

const Dashboard = () => {
	const {
		user,
		// freeTrialEnd,
		sendVerificationEmail,
		hasLatestWebAppVersion,
	} = useContext(AuthContext);
	const { getCurrentEnurm, nextEnurm, getCurrentENURMData, enurmData } =
		useContext(AdminContext);

	// {
	// 	/* <i className='material-icons fsize-larger mr-2'>privacy-tip</i>; */
	// }
	// TODO: add icon "analitycs"
	// TODO: add icon "table_chart"
	// TODO: add icon "account_circle"

	// console.info('USER:', user);

	React.useEffect(() => {
		const getLatestWebAppVersion = async () => {
			await hasLatestWebAppVersion();
		};
		getLatestWebAppVersion();
		if (!enurmData) {
			getCurrentENURMData();
		}

		// ? SET freeTrialActive TO FALSE BASED ON TIME (WE ARE NO LONGER DOING THIS)
		//#region
		// if (user.freeTrialActive) {
		// 	let expiration = new Date(
		// 		user.freeTrialStartDate.seconds * 1000 +
		// 			FOURTY_EIGHT_HOURS_IN_MILISECONDS
		// 	);
		// 	let today = new Date();

		// 	if (today > expiration || user.totalTestRecords >= 3) {
		// 		console.info('Free trial ended.');
		// 		freeTrialEnd();
		// 	}
		// }
		//#endregion

		// throw new Error('some error');
		/*eslint-disable-next-line */
	}, []);

	React.useEffect(() => {
		!user?.emailVerified && sendVerificationEmail();
		/*eslint-disable-next-line */
	}, [user]);

	/**
	 * *MAIN LOGIC TO DECIDE WHAT IS RENDERED TO THE USER
	 */

	// if (!user.emailVerified) {
	// 	// return <VerifyYourEmail />;
	// 	return <div></div>;
	// }

	if (user.isStaff) {
		return <StaffDashboard />;
	}

	if (user.isAdmin) {
		return <AdminDashboard />;
	}

	if (
		user[`isClient_${nextEnurm}`] ||
		(user.freeTrialActive && user.enurmTargetYear === nextEnurm)
	) {
		return <UserDashboard />;
	}

	if (!user[`isClient_${nextEnurm}`]) {
		return <Redirect to={STRIPE_CHECKOUT} />;
		// return <SalesSeasonClosed />;
	}

	return <div className='p-2 mt-2'>Por favor comunícate con nosotros...</div>;
};

export default Dashboard;
