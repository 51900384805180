import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import M from 'materialize-css';
import { Link } from 'react-router-dom';

const TestSelectionsModal = ({ content, submit, testReady }) => {
	useEffect(() => {
		const modal = document.querySelector('#test-selections-by-user');
		var instance = M.Modal.getInstance(modal);
		if (testReady) {
			instance.open();
		}
	}, [testReady]);

	return (
		<span style={{ position: 'relative' }}>
			{/* Modal link trigger */}
			<a
				className='modal-trigger'
				style={{ display: 'none' }}
				href='#test-selections-by-user'
				title='Selecciones del examen'>
				<i className='material-icons'>feedback</i>
			</a>

			{/* Modal hidden body */}
			<div
				className='modal modal-fixed-footer'
				style={{ height: '30%' }}
				id='test-selections-by-user'
				// style={modalStyle}
			>
				{/* modal content */}
				<div className='modal-content' style={{ paddingBottom: '0' }}>
					<div>
						<h4 className='center-align m-0'>Confirma...</h4>
					</div>
					<div>{content}</div>
				</div>
				{/* modal footer */}
				<div className='modal-footer'>
					<Link className='modal-close btn-flat' to='/dashboard'>
						No <i className='material-icons right'>close</i>
					</Link>
					{submit}
				</div>
			</div>
		</span>
	);
};

TestSelectionsModal.propTypes = {
	update: PropTypes.func,
	questionID: PropTypes.string,
	// content: PropTypes.any,
	// fieldKey: PropTypes.string.isRequired,
	// materialSmall: PropTypes.bool,
	// triggerCSS: PropTypes.string.isRequired,
};

export default TestSelectionsModal;
