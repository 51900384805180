import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/analytics';
import 'firebase/functions';

// Firebase SDK Reference: https://firebase.google.com/docs/reference/js
// Your web app's Firebase configuration
// const prodConfig = {
// 	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
// 	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
// 	databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
// 	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
// 	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
// 	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
// 	appId: process.env.REACT_APP_FIREBASE_APP_ID,
// 	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMEANT_ID,
// };
// const devConfig = {
// 	apiKey: process.env.REACT_APP_FIREBASE_DEV_API_KEY,
// 	authDomain: process.env.REACT_APP_FIREBASE_DEV_AUTH_DOMAIN,
// 	databaseURL: process.env.REACT_APP_FIREBASE_DEV_DATABASE_URL,
// 	projectId: process.env.REACT_APP_FIREBASE_DEV_PROJECT_ID,
// 	storageBucket: process.env.REACT_APP_FIREBASE_DEV_STORAGE_BUCKET,
// 	messagingSenderId: process.env.REACT_APP_FIREBASE_DEV_MESSAGING_SENDER_ID,
// 	appId: process.env.REACT_APP_FIREBASE_DEV_APP_ID,
// 	measurementId: process.env.REACT_APP_FIREBASE_DEV_MEASUREMEANT_ID,
// };

// const environment = process.env.NODE_ENV === 'production';

// const firebaseConfig =
// 	process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

// const firebaseConfig = environment ? prodConfig : devConfig;
// const firebaseConfig = prodConfig;

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMEANT_ID,
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

// Auth & firestore references
export const firebaseFirestore = firebase.firestore();
export const firebaseAuth = firebase.auth();
export const firebaseCloudFunctions = firebase.functions();
export const analytics = firebase.analytics();

/**
 * Logs events to Analytics, takes 2 params: event, object (optional)
 */
export const logEvent = (event, object = {}) => {
	analytics.logEvent(event, object);
};

/**
 * Takes in error Object, errorInfo: string
 */
export const logError = (
	err,
	errInfo,
	location = window.location.pathname,
	userID = '',
	errorBoundary = false
) => {
	const user = localStorage.getItem('prepmed-uuid');

	firebase
		.firestore()
		.collection('errors')
		.add({
			...err,
			errorInfo: errInfo,
			path: location,
			recordedOn: new Date(),
			user,
			errorBoundary,
		})
		.then(() => {
			console.log('Error added');
			// alert('Question set added succesfully.');
		})
		.catch((err) => {
			console.log('Error add failed');
		});
};

export default firebase;
