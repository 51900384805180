import React from 'react';
import { FirestoreContext } from '../../../../utils/store/context/firestoreContext';

const NeedsImage = ({ id, question }) => {
	const { updateQuestionbyKey } = React.useContext(FirestoreContext);
	const [checked, setChecked] = React.useState(false);

	React.useEffect(() => {
		setChecked(question?.needs_image);
		return () => {
			setChecked(false);
		};
	}, [question]);

	return (
		<div className='input-field col s12'>
			<label htmlFor='needs_image'>
				<input
					className='filled-in'
					checked={checked}
					id='needs_image'
					type='checkbox'
					onChange={(e) => {
						updateQuestionbyKey(id, 'needs_image', !checked);
						setChecked(!checked);
					}}
				/>
				<span>Question needs image (saves change automatically)</span>
			</label>
		</div>
	);
};

export default NeedsImage;
