import React, { createContext } from 'react';
import { firebaseCloudFunctions, analytics } from '../config/fbConfig';
export const UtilContext = createContext();

const UtilContextProvider = (props) => {
	const refreshPage = () => {
		window.location.reload(false);
	};

	const redirect = (page) => {
		// "page" should be a string with the path
		// redirect('/dashboard');
		window.location.replace(page);
	};

	const handleError = (err) => {
		console.log(err);
	};

	const shuffle = (array) => {
		// function shuffle(array) {
		// 	for (let i = array.length - 1; i > 0; i--) {
		// 		let j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i

		// 		// swap elements array[i] and array[j]
		// 		// we use "destructuring assignment" syntax to achieve that
		// 		// you'll find more details about that syntax in later chapters
		// 		// same can be written as:
		// 		// let t = array[i]; array[i] = array[j]; array[j] = t
		// 		[array[i], array[j]] = [array[j], array[i]];
		// 	}
		// }

		// "The modern version of the Fisher–Yates shuffle algorithm:" (ES6 VERSION):
		/**
		 * Shuffles array in place. ES6 version
		 * param {Array} a items An array containing the items.
		 */
		for (let i = array.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[array[i], array[j]] = [array[j], array[i]];
		}

		return array;
	};

	const scrollTo = (spot) => {
		// "spot" should be an element id #add-questions-form
		document.querySelector(`${spot}`).scrollIntoView({
			behavior: 'smooth',
			block: 'start',
		});
	};

	//~ FIREBASE CLOUD FUNCTIONS
	//!firebase.google.com/docs/functions/writing-and-viewing-logs

	return (
		<UtilContext.Provider
			value={{
				refreshPage,
				redirect,
				handleError,
				shuffle,
				scrollTo,
				// logWarningToFunctions,
			}}>
			{props.children}
		</UtilContext.Provider>
	);
};

export default UtilContextProvider;
