import React, { useContext, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { AuthContext } from '../../../utils/store/context/authContext';
import { PREPMED_PRICE } from '../../../utils/store/context/types';
import { customDateFormatInSpanish } from '../../../utils/helpers';
import LoadingFull from '../../Helpers/LoadingFull';
import { AdminContext } from '../../../utils/store/context/adminContext';
// import ProgressBar from '../../Helpers/ProgressBar';

const CheckoutSuccess = (props) => {
	const { user, getCurrentUserFromFirebaseAuth, updateToPaidViaStripe } =
		useContext(AuthContext);
	const { nextEnurm } = useContext(AdminContext);

	const [loading, setLoading] = React.useState(true);

	const url = window.location.pathname;
	const sessionId = url.split('/success/session_id/').join('');
	const paymentDetails = JSON.parse(localStorage.getItem('PaymentID'));

	const { id, price, source, currency } = paymentDetails;

	useEffect(() => {
		// Todo: log successful sale
		!user?.uid && getCurrentUserFromFirebaseAuth();
		return () => {
			// cleanup
		};
		/*eslint-disable-next-line*/
	}, []);

	useEffect(() => {
		if (user?.uid && nextEnurm) {
			let isClient = user[`isClient_${nextEnurm}`];

			if (isClient) {
				return;
			} else {
				updateToPaidViaStripe(user, price, sessionId, source, currency);
			}
		}
		// user?.uid &&
		// 	updateToPaidViaStripe(user, price, sessionId, source, currency);
		setLoading(false);
		// eslint-disable-next-line
	}, [user]);

	return !id ? (
		<Redirect to='/acceder' />
	) : (
		<div className='container mt-5'>
			{loading && <LoadingFull message={'Esperando confirmación...'} />}
			{!loading && (
				<React.Fragment>
					<h2 className='txt-success'>
						<i className='material-icons fsize-larger mr-2'>check_circle</i>
						¡Tu pago fue exitoso!
					</h2>
					{/* <ProgressBar width={100} percent={100} /> */}
					<div className='mt-4 fsize-medium'>
						<p>
							Hola <strong>{user.firstName}</strong>,
						</p>
						<p>
							Nos place informarte que hemos recibido tu pago
							satisfactoriamente. Tu tarjeta presentará un cargo a nombre de{' '}
							<span>"PREPMED"</span> o <span>"PREPMEDRD.COM"</span>.
						</p>
						<p>
							Queremos aprovechar la oportunidad para darte una cálida
							bienvenida y desearte la mejor de las suertes al momento de tomar
							el examen. ¡Gracias por confiar en nosotros!
						</p>
						<p>
							Has tomado una excelente decisión y en breve podrás empezar a
							utilizar nuestra plataforma para prepararte a tomar el examen que
							decidirá tu futuro como médico en la República Dominicana.
						</p>
						<p>
							Presiona el botón de "PANEL PRINCIPAL" cuando estés listo para
							continuar.
						</p>
					</div>
					<div className='right-align mb-4'>
						{user.uid && (
							<Link to='/dashboard' className='btn btn-large bg-brand'>
								<i className='material-icons right'>arrow_forward_ios</i>
								Panel Principal
							</Link>
						)}
					</div>
				</React.Fragment>
			)}

			{/* <div className='fsize-small txt-g-1 right-align'>{sessionId}</div> */}
		</div>
	);
};

export default CheckoutSuccess;
