import React, { useContext, useEffect, useState } from 'react';
import { Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import LoadingFull from '../../components/Helpers/LoadingFull';
import { AuthContext } from '../store/context/authContext';

const PrivateRoute = ({ component: Component, ...rest }) => {
	const { loading, user, getCurrentUserFromFirebaseAuth } = useContext(
		AuthContext
	);
	const [userChecked, setUserChecked] = useState(false);
	// let lastLocation = useLocation().pathname;
	// let history = useHistory();
	// let lsHistory = localStorage.getItem('prepmed-lastLocation');

	// !this should be sessionStorage so its deleted when the session ends
	// https://stackoverflow.com/questions/60495825/how-detect-refresh-event-through-javascript-on-chrome
	// localStorage.setItem('prepmed-lastLocation', lastLocation);

	useEffect(() => {
		// console.log(history, lsHistory);
		// let isReloaded =
		// 	window.performance.getEntriesByType('navigation')[0].type === 'reload';
		// console.info(isReloaded);
		// if (isReloaded) {
		// 	return <Redirect to={lsHistory} />;
		// }
		return () => {};
		/* eslint-disable-next-line */
	}, []);

	const redirectToLogin = () => {
		return <Redirect to='/acceder' />;
	};

	return loading ? (
		<LoadingFull message='Validando permisos del usuario...' />
	) : (
		<Route
			{...rest}
			render={(props) =>
				user && user.uid ? <Component {...props} /> : redirectToLogin()
			}
		/>
	);
};

export default PrivateRoute;
