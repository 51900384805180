import React from 'react';

/**
 * Takes in "padding" and a message="" prop and display it with the loading sign
 */
const LoadingSection = ({ padding, message }) => {
	// https://icons8.com/preloaders/
	return (
		<div
			className='center'
			style={{
				height: '100%',
				width: '100%',
				// position: 'absolute',
				// top: '0',
				// left: '0',
				zIndex: '1000',
				opacity: '0.95',
				minHeight: '30vh',
			}}>
			<div style={{ paddingTop: padding }}>
				{/* {props.message && <h5 className='white-text'>{props.message}</h5>} */}
				<div className='preloader-wrapper big active'>
					<div className='spinner-layer spinner-blue-only'>
						<div className='circle-clipper left'>
							<div className='circle'></div>
						</div>
						<div className='gap-patch'>
							<div className='circle'></div>
						</div>
						<div className='circle-clipper right'>
							<div className='circle'></div>
						</div>
					</div>
				</div>
			</div>
			{message && <p>{message}</p>}
		</div>
	);
};

export default LoadingSection;
