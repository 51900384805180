import React from 'react';
import { FirestoreContext } from '../../../../utils/store/context/firestoreContext';
import EditModal from '../../admin/modals/EditModal';

const ViewEditImage = ({ showEdit, questionID, question, imageURL }) => {
	const { updateQuestionbyKey, deleteQuestionField } = React.useContext(
		FirestoreContext
	);

	return (
		<div className='row'>
			<div className='center'>
				<img
					src={imageURL}
					alt={question.imagePath}
					className='responsive-img'
				/>

				{/* //~DELETE IMAGE */}
				{showEdit && (
					<div
						className='d-flex w-75 m-auto'
						style={{ justifyContent: 'space-between' }}>
						<div>
							{question.showImageInQuestion ? (
								<span>Show image in Explanation?</span>
							) : (
								<span className='white-text'>Show image in Question?</span>
							)}
							<EditModal
								triggerCSS={`z-depth-0 modal-trigger ${
									question.showImageInQuestion
										? 'grey-text lighten-2'
										: 'white-text'
								}`}
								materialSmall={false}
								update={updateQuestionbyKey}
								questionID={questionID}
								fieldKey={'showImageInQuestion'}
								isChecked={question.showImageInQuestion}
							/>
						</div>
						<div>
							<span>Delete image?</span>
							<button
								className='txt-danger'
								onClick={() => {
									deleteQuestionField(questionID, 'imagePath');
								}}>
								<i className='material-icons'>delete</i>
							</button>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default ViewEditImage;
