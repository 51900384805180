import { firebaseFirestore, firebaseAuth } from '../../config/fbConfig';

import {
	FILTER_QUESTIONS,
	FILTER_QUESTIONS_BY_SUBCATEGORIES,
	TEST_TYPE,
	USE_TIMER,
	USE_TUTOR_MODE,
	TEST_MODE,
	TEST_TIMER_DURATION,
	TEST_TOPICS,
	TEST_TOPIC_SUBCATEGORIES,
	TEST_LABEL,
	NEXT_QUESTION,
	UPDATE_SCORE,
	UPDATE_TIMER,
	UPDATE_TIMER_START,
	SELECTED_ANSWER,
	REPORT_ERROR,
	SET_TEST_QUESTIONS,
	GET_QUESTIONS,
	// DEBUG_BLUE,
} from '../types';

const reducerFunction = (state, action) => {
	let uid = firebaseAuth.currentUser.uid;

	let testRecords = state.userTestRecords;

	// const getTestRecords = async () => {
	// 	await firebaseFirestore
	// 		.collection('users')
	// 		.doc(uid)
	// 		.get()
	// 		.then((doc) => {
	// 			if (!doc.exists) {
	// 				console.info("ERROR: Can't update user!");
	// 			} else {
	// 				const user = doc.data();
	// 				testRecords = user.testRecords;
	// 			}
	// 		})
	// 		.catch((err) => {
	// 			console.log(err);
	// 		});
	// };

	const filterQuestionsTopicRemove = (lastAdded) => {
		let newTopics = [
			...state.topics.filter((value) => {
				return value !== lastAdded;
			}),
		];

		if (newTopics.length > 0) {
			return [
				...state.allQuestions.filter((question) => {
					return newTopics.some((each) => each === question.category);
				}),
			];
		} else {
			return [...state.allQuestions];
		}
	};

	const filterQuestionsTopicSubCategoryRemove = (lastAdded) => {
		let newTopicsSubcategories = [
			...state.topicSubcategories.filter((value) => {
				return value !== lastAdded;
			}),
		];

		if (newTopicsSubcategories.length > 0) {
			return [
				...state.allQuestions.filter((question) => {
					return newTopicsSubcategories.some(
						(each) => each === question.subCategory
					);
				}),
			];
		} else {
			return [...state.allQuestions];
		}
	};

	switch (action.type) {
		case FILTER_QUESTIONS:
			let lastAdded = action.payload[action.payload.length - 1];
			return {
				...state,
				questions: state.topics.includes(lastAdded)
					? state.allQuestions.filter((question) => {
							return action.payload.some((each) => each === question.category);
					  })
					: filterQuestionsTopicRemove(lastAdded),
			};
		case FILTER_QUESTIONS_BY_SUBCATEGORIES:
			let lastSubCategoryAdded = action.payload[action.payload.length - 1];
			return {
				...state,
				questions: state.topicSubcategories.includes(lastSubCategoryAdded)
					? state.allQuestions.filter((question) => {
							return action.payload.some(
								(each) => each === question.subCategory
							);
					  })
					: filterQuestionsTopicSubCategoryRemove(lastSubCategoryAdded),
			};
		case NEXT_QUESTION:
			return {
				...state,
				currentQuestion: action.payload + 1,
			};
		case REPORT_ERROR:
			return {
				...state,
				issues: {
					list:
						action.payload.name !== 'issueMessage' && !action.payload.value
							? [...state.issues.list, action.payload.name]
							: [
									...state.issues.list.filter((value) => {
										return value !== action.payload.name;
									}),
							  ],
					message:
						action.payload.name === 'issueMessage' ? action.payload.value : '',
				},
				[action.payload.name]: !action.payload.value,
			};
		case SELECTED_ANSWER:
			return {
				...state,
				selectedAnswer: action.payload,
			};
		case SET_TEST_QUESTIONS:
			return {
				...state,
				selectedQuestionsAndAnswers: [
					...state.selectedQuestionsAndAnswers,
					{
						questionID: state.userTestSelections.questions[action.payload].id,
						correctAnswer:
							state.userTestSelections.questions[action.payload].answer,
						question:
							state.userTestSelections.questions[action.payload].question,
						explanation:
							state.userTestSelections.questions[action.payload].explanation,
						selectedAnswer: state.selectedAnswer,
					},
				],
			};
		case TEST_LABEL:
			if (action.payload === 'correctas') {
				return {
					...state,
					questions: state.allQuestions.filter((q) => {
						return state.userCorrectQuestions.includes(q.id);
					}),
					testLabel: 'correctas',
					showCategorySection: true,
				};
			}

			if (action.payload === 'nuevas') {
				let questionsTaken = [];
				state.userTestRecords.forEach((tr) =>
					questionsTaken.push(...tr.questions)
				);
				let idsOfQuestionsTaken = [
					...questionsTaken
						.map((item) => item.questionID)
						.filter((value, index, self) => self.indexOf(value) === index),
				];

				return {
					...state,
					questions: state.allQuestions.filter((q) => {
						return !idsOfQuestionsTaken.includes(q.id);
					}),
					testLabel: 'nuevas',
					showCategorySection: true,
				};
			}

			if (action.payload === 'incorrectas') {
				return {
					...state,
					questions: state.allQuestions.filter((q) => {
						return state.userIncorrectQuestions.includes(q.id);
					}),
					testLabel: 'incorrectas',
					showCategorySection: true,
				};
			}
			if (action.payload === 'all' || action.payload === 'prepmed') {
				return {
					...state,
					questions: [...state.allQuestions],
					showCategorySection: true,
					testLabel: action.payload,
				};
				// window.location.reload(false);
			} else {
				return {
					...state,
					questions: state.allQuestions.filter((question) => {
						return action.payload === question.enurm;
					}),
					showCategorySection:
						action.payload !== 'all' || action.payload !== 'prepmed'
							? false
							: true,
					testLabel: action.payload,
				};
			}
		case TEST_MODE:
			return {
				...state,
				testMode: action.payload,
				showTopicSelectionBoxes: action.payload === 'categorías' ? true : false,
			};
		case TEST_TIMER_DURATION:
			return {
				...state,
				testDuration: action.payload,
			};
		case TEST_TOPICS:
			return {
				...state,
				topics: state.topics.includes(action.payload)
					? [
							...state.topics.filter((value) => {
								return value !== action.payload;
							}),
					  ]
					: [...state.topics, action.payload],
			};
		case TEST_TOPIC_SUBCATEGORIES:
			return {
				...state,
				topicSubcategories: state.topicSubcategories.includes(action.payload)
					? [
							...state.topicSubcategories.filter((value) => {
								return value !== action.payload;
							}),
					  ]
					: [...state.topicSubcategories, action.payload],
			};
		case TEST_TYPE:
			/*eslint-disable*/
			return {
				...state,
				testLength: action.payload,
				testType:
					action.payload == 50
						? 'pruebín'
						: action.payload == 100
						? 'simulación'
						: 'otro',
				// testDuration: action.payload == 50 ? 3599.9 : 7200,
			}; /*eslint-enable*/
		case UPDATE_SCORE:
			return {
				...state,
				score: action.payload + 1,
			};
		case UPDATE_TIMER:
			return {
				...state,
				timeElapsedInSeconds: Math.floor(
					(action.payload - state.timerStartTime) / 1000
				),
			};
		case UPDATE_TIMER_START:
			return {
				...state,
				timerStartTime: action.payload,
			};
		case USE_TIMER:
			return {
				...state,
				useTimer: !action.payload,
			};
		case USE_TUTOR_MODE:
			return {
				...state,
				useTutorMode: !action.payload,
			};
		case GET_QUESTIONS:
			return {
				...state,
				allQuestions: [...action.payload],
				questions: [...action.payload],
			};
		default:
			return state;
	}
};

export default reducerFunction;
