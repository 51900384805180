import React from 'react';

function TermsOfService() {
	return (
		<div className='container'>
			<h2>Términos de servicio</h2>
			<h4>1. Términos</h4>
			<p>
				Al acceder al sitio web en{' '}
				<a href='https://www.prepmedrd.com'>https://www.prepmedrd.com</a>, usted
				acepta regirse por estos términos de servicio, todas las leyes y
				regulaciones aplicables y acepta que es responsable del cumplimiento de
				las leyes locales aplicables. Si no está de acuerdo con alguno de estos
				términos, tiene prohibido utilizar o acceder a este sitio. Los
				materiales contenidos en este sitio web están protegidos por las leyes
				de derechos de autor y de marcas registradas.
			</p>
			<p>
				Además, usted acepta nuestra política que establece su derecho a
				devolución de hasta un 75% de sus fondos si este es solicitado antes de
				concluir un periodo de 72 horas de instrito/a en el sitio web
				www.prepmedrd.com.
			</p>
			<h4>2. Licencia de uso</h4>
			<ol type='a'>
				<li>
					{/* Se otorga el permiso para descargar temporalmente una copia de los
					materiales (información o software) en el sitio web de PrepMed LLC
					para visualización transitoria personal, no de uso comercial. Esta es
					la concesión de una licencia, no una transferencia de título, y bajo
					esta licencia usted no puede: */}
					No se otorga el permiso para descargar temporalmente una copia de los
					materiales (información o software) en el sitio web de PrepMed LLC
					para visualización transitoria personal, ni de uso comercial. Usted no
					puede:
					<ol type='i'>
						<li>modificar o copiar los materiales;</li>
						<li>
							usar los materiales para cualquier propósito comercial, o para
							cualquier exhibición pública (comercial o no comercial);
						</li>
						<li>
							intentar descompilar o aplicar ingeniería inversa a cualquier
							software contenido en el sitio web de PrepMed LLC;
						</li>
						<li>
							eliminar cualquier copyright u otra notación de propiedad de los
							materiales; o
						</li>
						<li>
							transferir los materiales a otra persona o mostrar los materiales
							en cualquier otro servidor.
						</li>
					</ol>
				</li>
				<li>
					Su licencia de uso terminará automáticamente si incumple cualquiera de
					estas restricciones y PrepMed LLC podrá rescindir su acceso en
					cualquier momento. Al finalizar la visualización de estos materiales o
					al finalizar su acceso, debe destruir cualquier material descargado en
					su poder, ya sea en formato electrónico o impreso.
				</li>
				{/* <li>
					Esta licencia terminará automáticamente si incumple cualquiera de
					estas restricciones y PrepMed LLC podrá rescindirla en cualquier
					momento. Al finalizar la visualización de estos materiales o al
					finalizar esta licencia, debe destruir cualquier material descargado
					en su poder, ya sea en formato electrónico o impreso.
				</li> */}
			</ol>
			<h4>3. Aviso legal</h4>
			<ol type='a'>
				<li>
					Los materiales en el sitio web de PrepMed LLC se proporcionan "tal
					cual". PrepMed LLC no ofrece ninguna garantía, expresa o implícita, y
					por este medio niega todas las demás garantías incluidas, sin
					limitación, las garantías implícitas o las condiciones de
					comercialización, idoneidad para un fin particular o no infracción de
					la propiedad intelectual u otra violación de los derechos.{' '}
				</li>
				<li>
					Además, PrepMed LLC no garantiza ni hace ninguna declaración con
					respecto a la precisión, los resultados probables o la fiabilidad del
					uso de los materiales en su sitio web o de otro modo relacionado con
					dichos materiales o en cualquier sitio vinculado a este sitio.
				</li>
			</ol>
			<h4>4. Limitaciones</h4>
			<p>
				En ningún caso, PrepMed LLC o sus proveedores serán responsables de los
				daños (incluidos, entre otros, los daños por pérdida de datos o
				ganancias, o debido a la interrupción del negocio) que surjan del uso o
				la imposibilidad de utilizar los materiales en el sitio web de PrepMed
				LLC, incluso si la PrepMed LLC o un representante autorizado de PrepMed
				LLC han sido notificados verbalmente o por escrito sobre la posibilidad
				de tal daño. Debido a que algunas jurisdicciones no permiten
				limitaciones sobre garantías implícitas o limitaciones de
				responsabilidad por daños indirectos o incidentales, estas limitaciones
				pueden no ser de aplicación en su caso.
			</p>
			<h4>5. Precisión de los materiales</h4>
			<p>
				Los materiales que aparecen en el sitio web de PrepMed LLC podrían
				incluir errores técnicos, tipográficos o fotográficos. PrepMed LLC no
				garantiza que ninguno de los materiales en su sitio web sea preciso,
				completo o actual. PrepMed LLC puede hacer cambios a los materiales
				contenidos en su sitio web en cualquier momento sin previo aviso. Sin
				embargo, PrepMed LLC no se compromete a actualizar dichos materiales.
			</p>
			<h4>6. Enlaces</h4>
			<p>
				PrepMed LLC no ha revisado todos los sitios vinculados a su sitio web y
				no es responsable de los contenidos de ningún sitio vinculado. La
				inclusión de cualquier enlace no implica el respaldo del sitio por parte
				de PrepMed LLC. El uso de cualquier sitio web vinculado es por cuenta y
				riesgo del usuario.
			</p>
			<h4>7. Modificaciones</h4>
			<p>
				PrepMed LLC puede revisar estos términos de servicio para su sitio web
				en cualquier momento sin previo aviso. Al utilizar este sitio web, usted
				acepta regirse por la versión actual de estos términos de servicio.
			</p>
			<h4>8. Legislación</h4>
			<p>
				Estos términos y condiciones se rigen y se interpretan de acuerdo con
				las leyes de New York y usted se somete irrevocablemente a la
				jurisdicción exclusiva de los tribunales de ese estado o ubicación.
			</p>
		</div>
	);
}

export default TermsOfService;
