import React from 'react';
import logo from '../../assets/logo/LOGO_BLUE_FULL_COLOR.png';

function LogoDisplay() {
	return (
		<div className='col s12 center'>
			{window.innerWidth > 790 && (
				<img
					src={logo}
					alt='prepmed-logo'
					className='img-reponsive w-65 pt-5'
				/>
			)}
			{window.innerWidth < 790 && <div className='py-2'></div>}
		</div>
	);
}

export default LogoDisplay;
