import React from 'react';

const Consulta21 = () => {
	React.useEffect(() => {
		window.location.replace(
			'https://mailchi.mp/8063835a914d/consulta-enurm-2021'
		);
		// return () => {
		//     cleanup
		// }
	}, []);
	return <div>Espera un momento por favor...</div>;
};

export default Consulta21;
