import React, { useContext, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { redirect } from '../helpers';
import { AuthContext } from '../store/context/authContext';

const PulicRoute = ({ component: Component, restricted, ...rest }) => {
	const { user } = useContext(AuthContext);

	return (
		// Set restricted = false meaning public route
		// Set restricted = true meaning restricted route
		<Route
			{...rest}
			render={(props) =>
				user.uid && restricted ? (
					<React.Fragment>
						<Redirect to='/dashboard' />
					</React.Fragment>
				) : (
					<React.Fragment>
						<Component {...props} />
					</React.Fragment>
				)
			}
		/>
	);
};

export default PulicRoute;
