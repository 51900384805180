import React from 'react';
import { AdminContext } from '../../../../utils/store/context/adminContext';
import SelectENURMYear from '../../../Helpers/SelectENURMYear';
import { useSortableData } from '../../../Helpers/useSortableData';

const TrueActiveUsers = (props) => {
	const { getUsersWhere } = React.useContext(AdminContext);

	const [allActiveUsers, setAllActiveUsers] = React.useState([]);
	const [showIncompleteSurveys, setShowIncompleteSurveys] =
		React.useState(false);

	const [reportData, setReportData] = React.useState([]);
	const [reportYear, setReportYear] = React.useState([]);
	const [year, setYear] = React.useState();

	const [searchSubmitted, setSearchSubmitted] = React.useState(false);

	const { items, requestSort } = useSortableData(reportData, {
		key: 'lastName',
		direction: 'descending',
	});

	React.useEffect(() => {
		// handleReportYearSelect();
		if (reportYear.length > 0) {
			let date = new Date(reportYear);
			let yr = date.getFullYear() + 1;
			setYear(yr);
		}

		return () => {
			setSearchSubmitted(false);
		};
		// eslint-disable-next-line
	}, [reportYear, showIncompleteSurveys]);

	const getActiveUsers = (data) => {
		let all_active_users = [];
		let field = `isClient_${year}`;

		all_active_users = data.filter((user) => {
			return (
				user &&
				user[field] &&
				new Date(user?.lastTestDate?.seconds * 1000) >= new Date(reportYear)
			);
		});

		// console.info('Active Users:', all_active_users);
		setAllActiveUsers(all_active_users);
		setReportData(all_active_users);
	};

	const getNoSurveyUsersOnly = () => {
		setShowIncompleteSurveys(!showIncompleteSurveys);

		if (showIncompleteSurveys) {
			setReportData(allActiveUsers);
			return;
		}

		let users_survey_incomplete = [];
		users_survey_incomplete = allActiveUsers.filter((user) => {
			return !user.hasOwnProperty('post_test_consultation');
		});

		setReportData(users_survey_incomplete);
	};

	const handleSubmit = async () => {
		let data = await getUsersWhere('enurmTargetYear', '==', year);
		// console.info(data);
		getActiveUsers(data);
		setSearchSubmitted(true);
	};

	return (
		<>
			<div className='mt-2 row'>
				<h4>True Active Users Report</h4>
				{/* //~ SELECT A YEAR*/}
				<SelectENURMYear onChange={(yr) => setReportYear(yr)} />

				{/* //~ SHOW INCOMPLETE SURVEYS ONLY */}
				<div className='input-field col s12 m6' hidden={year === undefined}>
					<label htmlFor='incompliteSurveys'>
						<input
							className='filled-in'
							type='checkbox'
							id='incompliteSurveys'
							checked={showIncompleteSurveys}
							onChange={getNoSurveyUsersOnly}
						/>
						<span>Show customers with no post consultation survey</span>
					</label>
				</div>
				{!searchSubmitted && (
					<div className='input-field col s12'>
						<button
							type='submit'
							className='btn bg-brand'
							disabled={year === undefined}
							onClick={handleSubmit}>
							Submit
						</button>
					</div>
				)}
			</div>

			{/* //~RESULTS TABLE */}

			{reportYear && searchSubmitted && (
				<div className='mt-2'>
					{reportData.length < 1 && <p>No results found...</p>}
					{reportData.length > 0 && (
						<table>
							<thead style={thead}>
								<tr>
									<td>
										<span
											className='c-pointer'
											onClick={() => requestSort('firstName')}>
											Nombre
										</span>
									</td>
									<td>
										<span
											className='c-pointer'
											onClick={() => requestSort('lastName')}>
											Apellido
										</span>
									</td>
									<td>
										{' '}
										<span
											className='c-pointer'
											onClick={() => requestSort('email')}>
											Email
										</span>
									</td>

									{showIncompleteSurveys && (
										<td style={center}>No Post-Test Surveys</td>
									)}
								</tr>
							</thead>
							<tbody>
								{reportData &&
									items.map((user) => {
										return (
											<tr key={user.id}>
												<td>{user.firstName}</td>
												<td>{user.lastName}</td>
												<td>{user.email}</td>
												{showIncompleteSurveys && (
													<td style={center}>
														<i className='material-icons txt-brand mr-1'>
															check_circle
														</i>
													</td>
												)}
											</tr>
										);
									})}
							</tbody>
						</table>
					)}
				</div>
			)}
		</>
	);
};

// ~ STYLES
const thead = {
	fontWeight: '700',
	backgroundColor: 'var(--brand-1)',
	color: 'var(--brand-white)',
};

const center = {
	textAlign: 'center',
};

export default TrueActiveUsers;
