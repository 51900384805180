import React, { useContext } from 'react';
import { AdminContext } from '../../../utils/store/context/adminContext';

// const DiscountOffer = ({ nextEnurm, priceStripe, pricePaypal }) => {
const DiscountOffer = (props) => {
	const { marketing } = useContext(AdminContext);

	const { showDiscount, nextEnurm, pricePaypal, priceStripe } = marketing;

	const getStripePrice = (price = marketing.priceStripe) => {
		const priceArray = (price / 100).toString().split('');
		const y = priceArray.shift();
		priceArray.unshift(y, ',');
		const precioTotal = priceArray.join('');
		return precioTotal;
	};

	if (showDiscount) {
		return (
			<div className='alert center fsize-medium'>
				<p>
					¡Aprovecha nuestra oferta actual para <strong>un solo pago</strong> de
					RD${getStripePrice(priceStripe)} (US$
					{pricePaypal})!
				</p>
				{/* <p>
					Usa el código:<strong>prepmed2022</strong>
				</p> */}
				<p>
					Este único pago te cubre acceso hasta la fecha en que se otorgue el{' '}
					<strong>ENURM {nextEnurm - 1}</strong>.
				</p>
			</div>
		);
	}
	return <div></div>;
};

export default DiscountOffer;
