import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { AdminContext } from '../../../utils/store/context/adminContext';
// *ASSETS
import heroshot from '../../../assets/images/doctor.png';
import flashcards from '../../../assets/images/flashcards.png';
import contentExamples from '../../../assets/gifs/content-example.gif';
import quizSelections from '../../../assets/gifs/quiz-selections.gif';
import quizExample from '../../../assets/gifs/quiz-example.gif';
import statsExample from '../../../assets/gifs/stats-example.gif';
// import Footer from '../Footer';
// import testResults from '../../../assets/gifs/test-results-example.gif';

import { isMobileScreen, nextTestDate } from '../../../utils/helpers';
import DiscountOffer from '../../private/sales/DiscountOffer';

import AnimatedSection from '../../Helpers/AnimatedSections';
import AnimatedImg from '../../Helpers/AnimatedImg';

const Home = () => {
	const { marketing, showDiscount, getMarketingData } =
		React.useContext(AdminContext);
	const mainSectionStart = useRef();
	const isMobile = isMobileScreen();

	React.useEffect(() => {
		getMarketingData();
		// eslint-disable-next-line
	}, []);

	return (
		<React.Fragment>
			<div className='center main' style={{ fontSize: '1.18rem' }}>
				{/* HERO SHOT */}
				<section className='decor-above-fold' style={{ minHeight: '90vh' }}>
					<div className='above-the-fold mt-4'>
						<h1 className='dark-text headline mt-2'>
							Descubre La Mejor Herramienta de Preparación para el ENURM*
						</h1>

						<div className='ml-4 mb-2 ml-0-m left-align'>
							<p className=' mb-0' style={{ fontSize: '1.5rem' }}>
								Con nosotros puedes alcanzar tu sueño de obtener una formación
								médica especializada en la República Dominicana.
							</p>

							<ul className='ml-4 ml-0-m'>
								<li>
									<motion.i
										className='material-icons'
										initial={{ x: '-1vw' }}
										animate={{ x: 0 }}
										transition={{
											duration: 1,
										}}>
										done
									</motion.i>{' '}
									Accede a información actualizada 24/7 desde donde prefieras
								</li>
								<li>
									<motion.i
										className='material-icons'
										initial={{ x: '-1vw' }}
										animate={{ x: 0 }}
										transition={{
											duration: 1.5,
										}}>
										done
									</motion.i>{' '}
									Recibe respuestas y explicaciones a tus preguntas
								</li>
								<li>
									<motion.i
										className='material-icons'
										initial={{ x: '-1vw' }}
										animate={{ x: 0 }}
										transition={{
											duration: 2,
										}}>
										done
									</motion.i>{' '}
									Ayúdate con pruebas que validarán tus conocimientos
								</li>
								{/* // <li>
								// 	<i className='material-icons'>done</i> Olvídate de estudiar de
								// 	fotocopias o folletos.
								// </li>
								// <li>
								// 	<i className='material-icons'>done</i> Estudia de un banco de
								// 	preguntas creciente y actualizado
								// </li>
								// <li>
								// 	<i className='material-icons'>done</i> Obtén explicaciones y
								// 	fuentes de cada pregunta
								// </li>  */}
							</ul>
							<div className='mt-1 fsize-small left-align'>
								*Examen Nacional Único para Residencias Médicas.
							</div>
						</div>
						<div className='row container'>
							<div className='col s6'>
								<a
									className='btn btn-large bg-brand'
									href='https://www.prepmed.org/registrate'>
									{isMobile ? '¡Regístrate!' : 'Crea Tu Cuenta'}
								</a>
								{/* <Link className='btn btn-large bg-brand' to='/registrate'>
									{isMobile ? '¡Regístrate!' : 'Crea Tu Cuenta'}
								</Link> */}
							</div>
							<div className='col s6'>
								<button
									className='btn btn-large bg-brand1'
									onClick={() =>
										mainSectionStart.current.scrollIntoView({
											behavior: 'smooth',
											block: 'start',
										})
									}>
									{isMobile ? 'Más Info' : 'Conoce Más'}
								</button>
							</div>
						</div>
					</div>
					<motion.img
						src={heroshot}
						alt=''
						className='img-responsive heroshot'
						initial={{ x: '5vw' }}
						animate={{ x: 0 }}
						transition={{
							delay: 0.25,
							duration: 1.5,
						}}
					/>
				</section>
				{/* MAIN BENEFITS SECTION */}
				<AnimatedSection className='section-1 mt-4' style={{ minHeight: 0 }}>
					<h3 className='mt-3' ref={mainSectionStart}>
						Beneficios ideales para tí
					</h3>
					<div className='container'>
						<div className='row'>
							<div className='col m7 s12'>
								<iframe
									title='youtube'
									width={isMobile ? 'auto' : '560'}
									height={isMobile ? 'auto' : '315'}
									src='https://www.youtube.com/embed/16EancpDcD0'
									frameBorder='0'
									allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;fullscreen'
									allowFullScreen></iframe>
							</div>

							<div className='col m5 s12'>
								<ul className='left-align'>
									<li className='pt-1'>
										<i className='material-icons txt-brand mr-1'>
											check_circle
										</i>{' '}
										<strong>Flexible</strong>- Podrás prepararte 24/7 en el
										lugar que prefieras según tu disponibilidad
									</li>
									<li className='pt-1'>
										<i className='material-icons txt-brand mr-1'>
											check_circle
										</i>
										<strong>Completo</strong> - Recibirás material actualizado y
										enfocado en el ENURM.
									</li>
									<li className='pt-1'>
										<i className='material-icons txt-brand mr-1'>
											check_circle
										</i>
										<strong>Inteligente</strong> - Podrás medir tu progreso con
										estadísticas de desempeño
									</li>
									<li className='pt-1'>
										<i className='material-icons txt-brand mr-1'>
											check_circle
										</i>
										<strong>Útil</strong> - Podrás generar exámenes con los
										temas específicos a tu necesidad
									</li>
									{/* <li className='pt-1'>
								<i className='material-icons txt-brand mr-1'>check_circle</i>
								Tendrás la flexibilidad de prepararte en cualquier momento y en
								cualquier lugar para el examen que decidirá tu futuro
								profesional.
							</li>
							<li className='pt-1'>
								<i className='material-icons txt-brand mr-1'>check_circle</i>
								El material de estudio en tu poder es actualizado, diseñado y
								enfocado únicamente en la preparación para el ENURM.
							</li>
							<li className='pt-1'>
								<i className='material-icons txt-brand mr-1'>check_circle</i>
								Tendrás estadísticas de desempeño para medir cómo vá tu progreso
								e identificar a simple vista tus puntos fuertes y débiles.
							</li>
							<li className='pt-1'>
								<i className='material-icons txt-brand mr-1'>check_circle</i>
								Podrás generar exámenes o 'flashcards'<sup>(pronto)</sup> con
								los temas específicos a tu necesidad y tomando en cuenta el
								tiempo a tu disposición.
							</li> */}
								</ul>
							</div>
						</div>
					</div>
				</AnimatedSection>
				{/* ESTUDIA ONLINE */}
				<AnimatedSection className='section-2 py-2'>
					<div className='container'>
						<div className='row'>
							{isMobile && (
								<div className='col m5 d-flex align-center'>
									<AnimatedImg
										src={quizSelections}
										className='img-responsive z-depth-4 gif-right'
										alt='enurm selección de examen'
									/>
								</div>
							)}
							<div className='col m7 left-align'>
								<h3>
									Estudia online, a tu ritmo, en la comodidad de tu hogar o
									donde prefieras
								</h3>
								<p>
									¿Estás haciendo una pasantía remota y no puedes asistir a
									clases en persona? ¿Haces servicios de noche y no puedes estar
									despierto para clases durante el día? ¿Tienes
									responsabilidades que te hacen imposible estudiar en el
									horario de otra persona? ¿Te gusta estudiar temprano por la
									mañana o tarde por la noche?
								</p>
								<p>
									Ahora con PrepMed podrás estudiar 24 horas al día, 7 días a la
									semana, desde cualquier computadora, tablet o teléfono móvil.
									Nuestra plataforma está diseñada para darte la conveniencia y
									flexibilidad que necesitas para maximizar cada momento de tu
									preparación para el ENURM.
								</p>
							</div>
							{!isMobile && (
								<div className='col m5 d-flex align-center'>
									<AnimatedImg
										className='img-responsive z-depth-4 gif-right'
										src={quizSelections}
										alt='enurm selección de examen'
									/>
								</div>
							)}

							<div className='col s12'>
								<a
									className='btn btn-large bg-brand'
									href='https://www.prepmed.org/registrate'>
									{isMobile ? '¡Regístrate!' : 'Crea Tu Cuenta'}
								</a>
								{/* <Link className='btn btn-large bg-brand' to='/registrate'>
									{isMobile ? '¡Regístrate!' : 'Crea Tu Cuenta'}
								</Link> */}
							</div>
						</div>
					</div>
				</AnimatedSection>
				{/* BANCO DE PREGUNTAS */}
				<AnimatedSection className='section-1 py-2'>
					<div className='container'>
						<div className='row'>
							<div className='col m5 d-flex align-center pt-5 pt-0-m'>
								<AnimatedImg
									// src={noMorePapers}
									src={contentExamples}
									className='img-responsive z-depth-4 gif-left'
									// style={{ margin: '3rem 5rem 3rem 0' }}
									alt='no mas folletos para el ENURM'
								/>
							</div>

							<div className='col m7 left-align'>
								<h3>Prepárate con material enfocado 100% en el ENURM</h3>
								<p>
									Previamente los estudiantes de medicina dominicanos han tenido
									que enfrentar el examen nacional estudiando de materiales del
									extranjero (Por ejemplo: MIR o USMLE) los cuales no están
									diseñados para{' '}
									<strong>maximizar los puntos de aprendizaje</strong> que más
									toma en cuenta el ENURM, ni las diferencias en docencia de RD.
								</p>
								<p>
									Con <strong>PrepMed</strong> recibirás un material que te
									ayudará a obtener la puntuación más alta posible. Tendrás
									acceso a ENURMs de años anteriores con{' '}
									<strong>explicaciones investigadas</strong> utilizando las
									fuentes señaladas en el temario de la UASD. Estas
									explicaciones también son enriquecidas utilizando fuentes
									complementarias para asegurar aproveches el punto de
									aprendizaje.
								</p>
							</div>
						</div>
					</div>
				</AnimatedSection>
				{/* MODALIDADES DE ESTUDIO */}
				<AnimatedSection className='section-2 center '>
					<div className='container'>
						<div className='row'>
							<div className='col m6 s12'>
								<AnimatedImg
									src={quizExample}
									alt='modo examinación'
									className='img-responsive z-depth-2'
								/>
								<h5>
									Genera tus propias simulaciones del ENURM y entrena para el
									examen con eficiencia
								</h5>
								{/* <p className='left-align'>
									Haz simulaciones, pruebines o elige la cantidad de preguntas
									que deseas. Evalúa tu conocimiento con exámenes de categorías
									específicas o preguntas al azar. Examínate usando tiempo
									límite y exponente a tomar exámenes bajo presión. Usa el modo
									tutor para ver explicaciones de las preguntas que vas
									respondiendo.
								</p> */}

								<ul className='left-align'>
									<li className='pt-1'>
										<i className='material-icons txt-brand mr-1'>
											check_circle
										</i>
										Has simulaciones y pruebas para medir tu aprendizaje{' '}
									</li>
									<li className='pt-1'>
										<i className='material-icons txt-brand mr-1'>
											check_circle
										</i>
										Evalúa tu conocimiento con exámenes de categorías
										específicas o preguntas al azar{' '}
									</li>
									<li className='pt-1'>
										<i className='material-icons txt-brand mr-1'>
											check_circle
										</i>
										Usa el modo "tutor" para ver explicaciones de las preguntas
										que vas respondiendo
									</li>
								</ul>
							</div>
							<div className='col m6 s12'>
								<AnimatedImg
									src={flashcards}
									alt='flashcards'
									className='img-responsive z-depth-2'
								/>
								{/* <h5>
									Estudia de flashcards con respuestas y explicaciones
									investigadas y verificadas
								</h5> */}
								<h5>
									Estudia de preguntas con respuestas y explicaciones
									investigadas y verificadas
								</h5>
								{/* <p className='left-align'>
									Nuestro contenido es desarrollado por un equipo de médicos en
									diferentes niveles de formación. Usamos la experiencia de
									residentes, sub-especialistas, y médicos base en el desarrollo
									de explicaciones de alto valor didáctico. Nuestro equipo de
									asesoría médica está formado por médicos formados en República
									Dominicana, USA y Europa, asegurando así un conocimiento
									válido, actualizado y aplicable.
								</p> */}
								<p className='left-align'>
									¡Te garantizamos un conocimiento válido, actualizado y
									aplicable!
								</p>
								<p className='left-align'>
									Nuestro contenido es desarrollado por un equipo de médicos
									formados en Rep. Dom., USA y Europa, en diferentes niveles de
									formación. Usamos la experiencia de residentes,
									sub-especialistas, y médicos base en el desarrollo de
									explicaciones de alto valor didáctico.
								</p>
							</div>
							<div className='col s12'>
								<a
									className='btn btn-large bg-brand'
									href='https://www.prepmed.org/registrate'>
									{isMobile ? '¡Regístrate!' : 'Crea Tu Cuenta'}
								</a>
								{/* <Link className='btn btn-large bg-brand' to='/registrate'>
									{isMobile ? '¡Regístrate!' : 'Crea Tu Cuenta'}
								</Link> */}
							</div>
						</div>
					</div>
				</AnimatedSection>
				{/* ESTADISTICAS DE DESEMPEÑO */}
				<AnimatedSection className='section-1'>
					<div className='container'>
						<div className='row'>
							<div className='col m5 d-flex align-center'>
								<AnimatedImg
									src={statsExample}
									className='img-responsive z-depth-4 gif-left'
									alt='enurm selección de examen'
								/>
							</div>
							<div className='col m7 left-align'>
								<h3>Estadísticas para mejorar tu desempeño</h3>
								<p>
									PrepMed te facilita estadísticas para mejorar tu rendimiento.
									Cuáles son tus puntos fuertes y cuáles son los débiles que
									tienes que trabajar.
								</p>

								<p>
									También podrás revisar tus exámenes viejos que hayas tomado en
									nuestra plataforma para medir la evolución de tu conocimiento
									y prepararte mejor.
								</p>
							</div>
						</div>
					</div>
				</AnimatedSection>
				{/* PRECIO / PAGO / MEMBRESIA */}
				<AnimatedSection className='section-2'>
					<div className='container'>
						<div className='row'>
							<div className='col m8 offset-m2 s12'>
								<h3>¿Qué cuesta la membresía?</h3>
								<div className='left-align'>
									{/* <p>
										Te ofrecemos acceso a la plataforma y herramientas de
										estudio por un monto total de RD$6,000.00.{' '}
									</p> */}
									<p>
										Por RD$6,000.00 te ofrecemos acceso a ilimitadas
										simulaciones del ENURM y diversas herramientas de estudio a
										través de nuestra plataforma.
									</p>
									<p>
										Este pago cubre hasta la fecha en que se otorgue el ENURM en
										el {nextTestDate()} y al recibirlo, inmediatamente tendrás
										acceso a lo siguiente:
									</p>
									<ul className='normal-bullets'>
										<li>Simulaciones basadas en tus necesidades</li>
										<li>Preguntas corregidas y validadas con explicaciones</li>
										<li>
											Fuentes de las explicaciones para que puedas expandir tu
											conocimiento
										</li>
										<li>
											Estadísticas personalizadas para mejorar tu desempeño
										</li>
										<li>
											Flashcards para memorizar conceptos
											<sup>(pronto)</sup>
										</li>
										<li>Entre otras cosas...</li>
									</ul>
									{showDiscount && <DiscountOffer />}
									<h6>¿Qué estas esperando?</h6>
									<div className='right-align'>
										<a
											className='btn btn-large bg-brand'
											href='https://www.prepmed.org/registrate'>
											<i className='material-icons right'>send</i>
											{isMobile ? '¡Regístrate!' : 'Crea Tu Cuenta'}
										</a>
										{/* <Link className='btn btn-large bg-brand' to='/registrate'>
											<i className='material-icons right'>send</i>
											{isMobile ? '¡Regístrate!' : '¡Regístrate Hoy!'}
										</Link> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</AnimatedSection>
			</div>
			{/* <Footer /> */}
		</React.Fragment>
	);
};

export default Home;
